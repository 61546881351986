import { makeAutoObservable } from "mobx"
import { TYPES } from "../Actions/Action"
import Moments from "../Moments"

export const UC_PHASES_STATES = {
  GREEN: "GREEN",
  RED: "RED",
  DEFAULT: "DEFAULT"
}

class Usecase {

  phases=[] // [{moment_id: "", failed: true|false}]

  type = TYPES.USECASE
  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }

  get failed() {
    return this.phases.some(p => p.failed)
  }

  get iconUrl() {
    let {root, icons_root} = window.CONFIG
    return `${root}${icons_root}/${this.icon}.png`
  }


  // ici on renvoie un objet avec la description de chaque étage
  get phasesStates() {
    return this.phases.map(p => {
      if(p.failed) return UC_PHASES_STATES.RED
      if(p.moment_id === Moments.selected.id) return UC_PHASES_STATES.GREEN
      return UC_PHASES_STATES.DEFAULT
    })
  }
}


export default Usecase