import React from 'react';
import Moments from '../../../../js/Moments';
import { UC_PHASES_STATES } from '../../../../js/Usecases/Usecase';

import { observer } from 'mobx-react-lite';
import PopupsManager from 'react-sdk/PopupsManager';
import { POPUPS } from '../../../../js/AppState';
import './Cylinder.css';


// highlighted = gros contour blanc
const Cylinder = ({transform="", phases=[], phasesStates=[], wireframe=false ,highlighted, iconUrl,  onClick}) => {

  function handleClick(evt) {
    if(onClick) onClick(evt)
  }

  let className = "Cylinder"
  if(highlighted) className += " highlighted"
  if(onClick) className += " clickable"
  if(wireframe) className += " wireframe"

  const showBlink = phase => phase && phase.moment_id === Moments.selected.id && !PopupsManager.isOpened(POPUPS.FTUE)

  return (
    <g className={className} transform={transform} onClick={handleClick}>
      <defs>
          <linearGradient id="gradient-success">
          <stop offset="0%" stopColor="#5fffa0" ></stop>
          <stop offset="23%" stopColor="#1dec73" ></stop>
          <stop offset="24%" stopColor="#04e461" ></stop>
          <stop offset="44%" stopColor="#09c055" ></stop>
          <stop offset="71%" stopColor="#0dec6a" ></stop>
          <stop offset="88%" stopColor="#44d27f" ></stop>
          <stop offset="90%" stopColor="#6bd999" ></stop>
        </linearGradient>

        <linearGradient id="gradient-error">
          <stop offset="0%"  stopColor="#E78181" ></stop>
          <stop offset="23%" stopColor="#DD4C4C" ></stop>
          <stop offset="24%" stopColor="#D93838" ></stop>
          <stop offset="44%" stopColor="#BB2424" ></stop>
          <stop offset="71%" stopColor="#DB4242" ></stop>
          <stop offset="88%" stopColor="#E36F6F" ></stop>
          <stop offset="90%" stopColor="#E88888" ></stop>
        </linearGradient>

        <linearGradient id="gradient-neutral">
          <stop offset="0%"  stopColor="#5777CA" ></stop>
          <stop offset="23%" stopColor="#175DAF" ></stop>
          <stop offset="24%" stopColor="#00509F" ></stop>
          <stop offset="44%" stopColor="#007AAE" ></stop>
          <stop offset="71%" stopColor="#0B95B7" ></stop>
          <stop offset="88%" stopColor="#37ACCD" ></stop>
          <stop offset="90%" stopColor="#3EBBDA" ></stop>
        </linearGradient>

        <linearGradient id="gradient-beam" x1="0" x2="0" y1="1" y2="0">
          <stop offset="0" stopColor='#4CFF95'>
            <animate attributeName="stop-opacity" values="0.6;1;0.6" dur="4s" repeatCount="indefinite"  />
          </stop>
          <stop offset="0.9" style={{stopColor: "#08863d", stopOpacity: 0}}></stop>
        </linearGradient>
      </defs>


      {phases.length === 0 &&
      <ellipse className='Cylinder-base' cx={176.96} cy={78.317} rx={17.67} ry={10.202}  />}

      {phases.length === 1 &&
      <g className={showBlink(phases[0]) ? "blink":""}>
        <ellipse className={'Cylinder-stage0 ' + phasesStates[0]} cx={176.96} cy={78.317} rx={17.67} ry={10.202}  />
        <image href={iconUrl} x="169" y="103"  height="15px" transform="scale(1, 0.7)" />
      </g>}


      {phases[1] &&
      <g className={'Cylinder-stage1 ' + phasesStates[1] + (showBlink(phases[1]) ? " blink":"")}>
        <path
          className='Cylinder-side'
          d="m 159.29984,67.413973 v 10.975041 h 0.0165 c 0.0601,5.608999 7.95158,10.137953 17.66663,10.138916 9.71504,-9.66e-4 17.60655,-4.529919 17.66662,-10.138916 h 0.004 v -0.06305 -10.911995"
        />

        {phasesStates[1] === UC_PHASES_STATES.GREEN &&
        <g className='Cylinder-side-icons' transform='translate(0, 10)'>
          <path d="m173.35 74.518 3.9694-5.7262 3.8871 5.7262-0.81779 0.23477c-2.0323 0.58129-4.1877 0.58129-6.22 0z" />
          <path d="m188.35 72.394 2.4906-7.25 2.4404 4.2345-0.51371 0.5475c-1.2761 1.3627-2.6284 2.19-3.9035 2.3884z" />
          <path d="m166.13 72.394-2.4906-7.25-2.4404 4.2345 0.51371 0.5475c1.2761 1.3627 2.6284 2.19 3.9035 2.3884z" />
        </g>}

        {phasesStates[1] === UC_PHASES_STATES.RED &&
        <g className='Cylinder-side-icons' transform='translate(-1, 0)'>
          <path d="m177.47 84.289-2.0253 2.0253-1.1019-1.1019 2.0253-2.0253-2.0253-2.0253 1.1019-1.1019 2.0253 2.026 2.0253-2.026 1.1019 1.1019-2.0253 2.0253 2.0253 2.0253-1.1019 1.1019z" />
          <path d="m186.26 82.298-2.0253 2.803-1.1019-0.67907 2.0253-2.803-2.0253-1.2476 1.1019-1.5247 2.0253 1.2483 2.0253-2.803 1.1019 0.67908-2.0253 2.8022 2.0253 1.2483-1.1019 1.524z" />
          <path d="m192.32 78.606-0.90769 2.803-0.49379-0.67833 0.90767-2.803-0.90767-1.2483 0.49379-1.5247 0.90769 1.2483 0.90767-2.803 0.49307 0.67908-0.90692 2.8022 0.90692 1.2483-0.49307 1.524z" />
          <path d="m169.42 82.298 2.0253 2.803 1.1019-0.67907-2.0253-2.803 2.0253-1.2476-1.1019-1.5247-2.0253 1.2483-2.0253-2.803-1.1019 0.67908 2.0253 2.8022-2.0253 1.2483 1.1019 1.524z" />
          <path d="m163.36 78.606 0.90767 2.803 0.49307-0.67833-0.90695-2.803 0.90695-1.2483-0.49307-1.5247-0.90767 1.2483-0.90769-2.803-0.49379 0.67908 0.90766 2.8022-0.90766 1.2483 0.49379 1.524z" />
        </g>}

        <ellipse className='Cylinder-top' cx="176.99" cy="66.684" rx="17.67" ry="10.202" />
        {phases.length === 2 && <image href={iconUrl} x="169" y="87"  height="15px" transform="scale(1, 0.7)" />}
      </g>}

      {phases[2] &&
      <g className={"Cylinder-stage2 " + phasesStates[2] + (showBlink(phases[2]) ? " blink":"")}>
        <path
          className='Cylinder-side'
          d="M 159.29984,34.606156 V 66.74683 h 0.0145 c 0.0588,5.605536 7.94657,10.138918 17.66869,10.138916 9.72261,0 17.60985,-4.533772 17.66868,-10.138916 h 0.002 v -0.06305 -32.077629"
        />

        {phasesStates[2] === UC_PHASES_STATES.GREEN &&
        <g>
          <g className='Cylinder-side-icons' transform='translate(0, -2)'>
            <path d="m173.35 74.518 3.9694-5.7262 3.8871 5.7262-0.81779 0.23477c-2.0323 0.58129-4.1877 0.58129-6.22 0z" />
            <path d="m188.35 72.394 2.4906-7.25 2.4404 4.2345-0.51371 0.5475c-1.2761 1.3627-2.6284 2.19-3.9035 2.3884z" />
            <path d="m166.13 72.394-2.4906-7.25-2.4404 4.2345 0.51371 0.5475c1.2761 1.3627 2.6284 2.19 3.9035 2.3884z" />
          </g>
          <g className='Cylinder-side-icons' transform='translate(0, -12)'>
            <path d="m173.35 74.518 3.9694-5.7262 3.8871 5.7262-0.81779 0.23477c-2.0323 0.58129-4.1877 0.58129-6.22 0z" />
            <path d="m188.35 72.394 2.4906-7.25 2.4404 4.2345-0.51371 0.5475c-1.2761 1.3627-2.6284 2.19-3.9035 2.3884z" />
            <path d="m166.13 72.394-2.4906-7.25-2.4404 4.2345 0.51371 0.5475c1.2761 1.3627 2.6284 2.19 3.9035 2.3884z" />
          </g>
          <g className='Cylinder-side-icons' transform='translate(0, -22)'>
            <path d="m173.35 74.518 3.9694-5.7262 3.8871 5.7262-0.81779 0.23477c-2.0323 0.58129-4.1877 0.58129-6.22 0z" />
            <path d="m188.35 72.394 2.4906-7.25 2.4404 4.2345-0.51371 0.5475c-1.2761 1.3627-2.6284 2.19-3.9035 2.3884z" />
            <path d="m166.13 72.394-2.4906-7.25-2.4404 4.2345 0.51371 0.5475c1.2761 1.3627 2.6284 2.19 3.9035 2.3884z" />
          </g>
        </g>}

        {phasesStates[2] === UC_PHASES_STATES.RED &&
        <g>
          <g className='Cylinder-side-icons' transform='translate(-1, -22)'>
            <path d="m177.47 84.289-2.0253 2.0253-1.1019-1.1019 2.0253-2.0253-2.0253-2.0253 1.1019-1.1019 2.0253 2.026 2.0253-2.026 1.1019 1.1019-2.0253 2.0253 2.0253 2.0253-1.1019 1.1019z" />
            <path d="m186.26 82.298-2.0253 2.803-1.1019-0.67907 2.0253-2.803-2.0253-1.2476 1.1019-1.5247 2.0253 1.2483 2.0253-2.803 1.1019 0.67908-2.0253 2.8022 2.0253 1.2483-1.1019 1.524z" />
            <path d="m192.32 78.606-0.90769 2.803-0.49379-0.67833 0.90767-2.803-0.90767-1.2483 0.49379-1.5247 0.90769 1.2483 0.90767-2.803 0.49307 0.67908-0.90692 2.8022 0.90692 1.2483-0.49307 1.524z" />
            <path d="m169.42 82.298 2.0253 2.803 1.1019-0.67907-2.0253-2.803 2.0253-1.2476-1.1019-1.5247-2.0253 1.2483-2.0253-2.803-1.1019 0.67908 2.0253 2.8022-2.0253 1.2483 1.1019 1.524z" />
            <path d="m163.36 78.606 0.90767 2.803 0.49307-0.67833-0.90695-2.803 0.90695-1.2483-0.49307-1.5247-0.90767 1.2483-0.90769-2.803-0.49379 0.67908 0.90766 2.8022-0.90766 1.2483 0.49379 1.524z" />
          </g>

        </g>}


        <ellipse className='Cylinder-top' cx="176.99" cy="34.934" rx="17.67" ry="10.202" />
        {iconUrl && <image href={iconUrl} x="169" y="42"  height="15px" transform="scale(1, 0.7)" />}
      </g>}


      {phases[2] && phasesStates[2] !== UC_PHASES_STATES.RED && !highlighted &&
      <g className="Cylinder-beam-ctnr">
        <path className='Cylinder-beam' transform="matrix(.26458 0 0 .26458 91.522 -123.04)" d="m256.18 336.43v259.39h0.15234a66.783 38.557 0 0 1 66.684-37.314 66.783 38.557 0 0 1 66.748 37.314h0.0332v-259.39z"/>

        <g className='Cylinder-star' >
          <path d="m177.51-18.771 1.3692 2.7744 3.0619 0.44466-2.2149 2.1599 0.5229 3.0492-2.7392-1.4397-2.7384 1.4397 0.52288-3.0492-2.2156-2.1599 3.0619-0.44466z" fill="#fff" />
          <path d="m185.02-14.029c0 4.1464-3.3614 7.5078-7.5071 7.5078-4.1464 0-7.5078-3.3614-7.5078-7.5078 0-4.1464 3.3614-7.5071 7.5078-7.5071 4.1457 0 7.5071 3.3607 7.5071 7.5071z"
            fill="none"
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={1.4094}
          />
        </g>
      </g>}
    </g>
  );
};

export default observer(Cylinder)