import Usecase from "./Usecase"


class Usecases {
  all = []

  slots = new Map()


  init(json) {
    this.all = json
    .filter(elem => elem.id)
    .map(elem => new Usecase(elem))
  }

  get(id) { return this.all.find(c => c.id === id) }

  get ids() { return this.current.map(c => c.id)}


  get activated() {
    return  Array.from(this.slots)
    .map(([slotId, ucId]) => this.get(ucId))
  }

}


export default new Usecases()