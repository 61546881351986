import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import Moments from '../../js/Moments';
import Texts from '../../js/Texts';
import ActionsMatrix from '../ScreenMoment/ActionsMatrix/ActionsMatrix';
import Title from '../shared/Title/Title';

import './ScreenshotMatrix.css'

const FakeMatrix = () => {
  let m = Moments.get("Select_first_UCs")
  let r = useRef(null)

  let matrixConfigs = [
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,2,3],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,2,4],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,2,5],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,3,4],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,3,5],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,4,5],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [2,3,4],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [2,3,5],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [2,4,5],
      hidden: [6, 7, 8, 9]
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [3,4,5],
      hidden: [6, 7, 8, 9]
    },
    // ici commence le sur mesure
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,2, 3,4,5],
      hidden: [],
      hideMatrixActions: true,
      name: "1"
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,2, 3,4,5],
      hidden: [],
      hideMatrixActions: true,
      invertHighlightList: true,
      name: "2"
    },
    {
      hideAbscice: true,
      hideOrdonnee: true,
      actives: [1,2, 3,4,5],
      hidden: [],
      name: "3"
    },
    {
      hideAbscice: true,
      hideOrdonnee: false,
      actives: [1,2, 3,4,5],
      hidden: [],
      name: "4",
      // overlayBelowOrdonnee: true,
      dimAllButOrdonnee: true

    },
    {
      hideAbscice: false,
      hideOrdonnee: false,
      actives: [1,2, 3,4,5],
      hidden: [],
      name: "5",
      dimAllButAbscisse: true
    },
    {
      hideAbscice: false,
      hideOrdonnee: false,
      actives: [1,2, 3,4,5],
      hidden: [],
      name: "6"
    },
    {
      hideAbscice: false,
      hideOrdonnee: false,
      actives: [1,2, 3,4,5],
      hidden: [6,7,8,9],
      name: "7"
    },
  ]
  const [currentConfigIndex, setCurrentConfigIndex] = useState(0);
  let c = matrixConfigs[currentConfigIndex]

  function next() {
    if(currentConfigIndex === matrixConfigs.length-1) {
      alert("DONE")
      reset()
    }else {
      setCurrentConfigIndex(c => c+1)
    }
  }

  function prev() {
    if(currentConfigIndex === 0) {
      setCurrentConfigIndex(matrixConfigs.length -1)
    }else {
      setCurrentConfigIndex(c => c-1)
    }
  }

  function reset() {
    setCurrentConfigIndex(0)
  }

  function screenshot() {
    document.querySelector('.controller').style.opacity = 0

    window.html2canvas(r.current, {
      backgroundColor: null,
      width: 1920,
      height: 1080,
      x: 0,
      y:0
    })
    .then(canvas => {
      canvas.style.position = "fixed";
      canvas.style.top = 0
      canvas.style.left = 0
      document.body.appendChild(canvas)
      return canvas
    })
    .then(canvas => {
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const a = document.createElement('a')
      let suffix = c.name || c.actives.join("-")
      a.setAttribute('download', "MOT2_Matrix_" + suffix + '.png')
      a.setAttribute('href', image)
      a.click()
      canvas.remove()
      document.querySelector('.controller').style.opacity = 1
      next()
  })
  }
  return (
    <div className="FakeMatrix" ref={r}>
      <div className="m-ctnr">
        <ActionsMatrix actions={c.hideMatrixActions ? [] : m.actions} screenshotmode={c} />
      </div>


      <div className={"ucs" + (c.invertHighlightList ? " invert" : "") + (c.dimAllButOrdonnee || c.dimAllButAbscisse ? " dim" : "")}>
        <Title>{Texts.get("dashboard-ai-solutions")}</Title>
        {m.actions.map((a,index)=> {
          let uc = a.sub_elements[0]
          // if(c.hidden.includes(index + 1)) return null
          let classname="uc" + (!c.actives.includes(index + 1) ? " inactive":"") + (c.hidden.includes(index + 1) ? " hidden" : "")
          return (
            <div key={a.id} className={classname}>
              <div className="Matrix-action" style={{position: "static", transform: "none"}}>{index + 1}</div>
              <div>{uc.title}</div>
            </div>)
          })}
      </div>


        <div className='controller'>
          {c.name || c.actives.join("-")}
        <button onClick={prev}>Prev</button>
        <button onClick={next}>Next</button>
        <button onClick={reset}>Reset</button>
        <button onClick={screenshot}>SCREENSHOT</button>
      </div>

    </div>
  );
};

export default FakeMatrix;
