import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useEffect } from 'react';
import PopupsManager from 'react-sdk/PopupsManager';
import AppState, { POPUPS } from '../../js/AppState';
import GameSequence from '../../js/GameSequence';


import Texts from '../../js/Texts';
import DialOverlay from '../shared/DialOverlay/DialOverlay';
import NeonButton from '../shared/NeonButton/NeonButton';
import Title from '../shared/Title/Title';
import DashboardFtue from './DashboardFtue/DashboardFtue';
import DashboardPopup from './DashboardPopup/DashboardPopup';

import './ScreenDashboard.css';
import SvgDashboard from './SvgDashboard/SvgDashboard';
import SvgDashboardTexts from './SvgDashboard/SvgDashboard-texts/SvgDashboard-texts';

const ScreenDashboard = ({showNextButton}) => {

  let elem = GameSequence.current.raw

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    AppState.sendScores()
  } , [])

  return (
    <div className="ScreenDashboard screen">

      <div className="ScreenDashboard-title">
        <Title>{Texts.get("dashboard_title")}</Title>
      </div>

      <SvgDashboard />

      <SvgDashboardTexts
        // showEnablersPeople={peopleEnablers.length > 0}
        // showEnablersStrat={stratEnablers.length > 0}
        // showEnablersTech={techEnablers.length > 0}
        showTalents={false}
        // showEbit={ebit > 0}
        // showUC={Usecases.activated.length > 0}
      />


      <DialOverlay />

      {showNextButton &&
      <NeonButton className='ScreenDashboard-nextbtn' onClick={() => GameSequence.next()}>{elem.screen_content}</NeonButton>}

      {PopupsManager.isOpened(POPUPS.FTUE) &&
      <DashboardFtue type={PopupsManager.getData(POPUPS.FTUE)} />}

      {PopupsManager.isOpened(POPUPS.DASHBOARD_POPUP) && <DashboardPopup data={PopupsManager.getData(POPUPS.DASHBOARD_POPUP)} />}
    </div>
  );
};

export default observer(ScreenDashboard)
