import React from 'react'
import Texts from '../../js/Texts';

import './GamemasterOverlay.css'

const GamemasterOverlay = () => {

  return (
    <div className="GamemasterOverlay">
      <span>{Texts.get("overlay_wait_gamemaster")}</span>
    </div>
  );
};

export default GamemasterOverlay;
