import { makeAutoObservable } from "mobx"
import { TYPES } from "../Actions/Action"
import Moments from "../Moments"
import Enablers from "./Enablers"

export default class Enabler {

  bought = false
  type= TYPES.ENABLER

  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }

  buy(moment_id) {
    this.bought = moment_id
  }

  get moment() {
    if(this.bought !== false) return Moments.get(this.bought)
    return null
  }

  get categ() {
    return Enablers.categories.find(c => c.id === this.category)
  }
  get iconUrl() {
    let {root, icons_root} = window.CONFIG
    return `${root}${icons_root}/${this.icon}.png`
  }

  get just_bought() {
    return this.bought === Moments.selected.id
  }
}