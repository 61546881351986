import React from 'react'

import './Brick.css'

const Brick = ({enabler, onClick, blink=false, transform=""}) => {

  function handleClick(evt) {
    if(!onClick) return
    onClick(evt, enabler)
  }


  let category = enabler ? enabler.category : "empty"
  let icon = enabler ? enabler.iconUrl : null
  let enabler_id = enabler ? enabler.id : null
  let className = 'Brick ' + category + (onClick ? " clickable" : "")

  return (
    <g className={className} transform={transform} onClick={handleClick} data-enablerid={enabler_id}>
      <path className="Brick-base" d="m164.98 74.083 18.331-10.583 18.331 10.583-18.331 10.583z" />

      {enabler &&
      <g className={'Brick-walls' + (blink ? " blink" : "")}>

        <path className='Brick-top' d="m201.64 68.792-18.331-10.583-18.331 10.583 18.331 10.583z" fill="#ffd42a" />

        <image href={icon} x="197" y="93"  height="15px" transform="scale(0.9, 0.68)" />

        <path className='Brick-side' d="m183.31 79.375-1e-5 5.2917 18.331-10.583 1e-5 -5.2917z" fill="#b08d00" />
        <path className='Brick-front' d="m164.98 68.792v5.2917l18.331 10.583v-5.2917z" fill="#e2b400" />
      </g>}
    </g>

  );
};

export default Brick;