import React, { useEffect, useRef, useState } from 'react';
import AppState from '../../../js/AppState';
import Enablers from '../../../js/Enablers/Enablers';
import Texts from '../../../js/Texts';
import Usecases from '../../../js/Usecases/Usecases';
// import Brick from '../SvgDashboard/Brick/Brick';
import Cylinder from '../SvgDashboard/Cylinder/Cylinder';

import './DashboardFtue.css';
import ScaleManager from 'react-sdk/ScaleManager';


const DashboardFtue = ({type}) => {
  const [bb, setBb] = useState(null);
  const [eltsCoords, setEltsCoords] = useState(null);

  function domRectTOObject(domRect) {
    const {top, right, bottom, left, width, height, x, y} = domRect
    return {top, right, bottom, left, width, height, x, y}
  }

  function scaleify(_coords) {
    let coords = domRectTOObject(_coords)
    coords.top  /= ScaleManager.scale
    coords.bottom  /= ScaleManager.scale
    coords.left  /= ScaleManager.scale
    coords.right  /= ScaleManager.scale
    coords.width = coords.right - coords.left
    coords.height = coords.bottom - coords.top
    if(ScaleManager.marginPos === "top") coords.top  -=  ScaleManager.margin / ScaleManager.scale
    if(ScaleManager.marginPos === "left") coords.left  -=  ScaleManager.margin / ScaleManager.scale

    return coords
  }

  useEffect(() => {
    if(type === "enablers") {
      let elements = document.querySelectorAll(".Brick.clickable")

      let bbCoords=  {
        top: Infinity,
        left: Infinity,
        bottom: 0,
        right: 0,
      }

      let eltsCoords = []

      elements.forEach(brick => {
        let coord = brick.getBoundingClientRect()
        if(coord.top < bbCoords.top) bbCoords.top = coord.top
        if(coord.left < bbCoords.left) bbCoords.left = coord.left
        if(coord.right > bbCoords.right) bbCoords.right = coord.right
        if(coord.bottom > bbCoords.bottom) bbCoords.bottom = coord.bottom

        eltsCoords.push(scaleify(coord))
      })


      setBb( scaleify(bbCoords))
      setEltsCoords(eltsCoords)
    }

  }, [type]);


  /** @param {Array} coordsArray  */
  function makeClipPath(coordsArray) {

    if(!coordsArray) return ""

    let coords = coordsArray.toSorted((a, b) => {
      if(a.top < b.top) return -1
      if(a.top > b.top) return 1
      return 0
    })

    let cp = "polygon(0 0, 100% 0"

    // les dimensions de la brick
    let h = 60
    let w = 100
    let ep = 15

    // construction d'un clippath qui entoure la "Brick" de l'enabler, pour chaque Brick
    coords.forEach(c => {
      cp += ", 100% "                                 + Math.floor(c.top + h/2) + "px"      // 2
      cp += ", " + Math.floor(c.left + w) + "px "     + Math.floor(c.top + h/2) + "px"      // 3
      cp += ", " + Math.floor(c.left  + w/2) + "px "  + Math.floor(c.top) + "px"            // 4
      cp += ", " + Math.floor(c.left) + "px "         + Math.floor(c.top + h/2) + "px"      // 5
      cp += ", " + Math.floor(c.left) + "px "         + Math.floor(c.top + h/2 + ep) + "px"      // 5.5
      cp += ", " + Math.floor(c.left  + w/2) + "px "   + Math.floor(c.top + h + ep) + "px"       // 6
      cp += ", " + Math.floor(c.left + w) + "px "     + Math.floor(c.top + h/2 + ep) + "px"      // 6.5
      cp += ", " + Math.floor(c.left + w) + "px "     + Math.floor(c.top + h/2) + "px"      // 7 == 3
      cp += ", 100% "                                 + Math.floor(c.top + h/2) + "px"      // 8 == 2
    })

    cp += ", 100% 100%, 0 100%, 0 0)"
    return cp
  }

  let uc_transforms = [

    "scale(0.9) translate(24.726473762720527 -49.99136807955725)",
    "scale(0.9) translate(66.00542509060654 -39.62897950956683)",
    "scale(0.9) translate(-15.593486333293086 -38.52266516950668)",

  // 'scale(0.9) translate(14.384407247480379 -49.27290945327289)',
  // 'scale(0.9) translate(58.54616765702588 -43.673112991572864)',
  // 'scale(0.9) translate(-21.03057490450628 -33.58262843440918)',
  ]

  let style = {}
  if(bb) style = {top: bb.top - 150, left: bb.left - 50, width: bb.width + 150, height: bb.height + 200}

  let ucs = Usecases.activated.filter(uc => !uc.headless)

  return (
    <div className="DashboardFtue-overlay" style={{clipPath: type=== "enablers" && makeClipPath(eltsCoords)}}>


      <div className={"DashboardFtue " + type} style={style}>

        {type === "enablers" &&
        <div className="DashboardFtue-content">
          <div className="DashboardFtue-title">{Texts.get("ftue-enablers-l1")}</div>
          <div className="DashboardFtue-subtitle">{Texts.get("ftue-enablers-l2")}</div>
        </div>}

        {type === "usecases" &&
        <div className="DashboardFtue-content">
          <div className="DashboardFtue-title">{Texts.get("ftue-usecases-l1")}</div>
          <div className="DashboardFtue-subtitle">{Texts.get("ftue-usecases-l2")}</div>
        </div>}

        <div className="DashboardFtue-elements">
          {type === "usecases" &&
          <svg viewBox="120 0 120 50">
            {ucs.map((uc, index) => {
              return <Cylinder phases={uc.phases} phasesStates={uc.phasesStates} iconUrl={uc.iconUrl} key={index} transform={uc_transforms[index]} onClick={(evt) => AppState.openDashboardPopup(evt, uc)} />
            })}
          </svg>}
        </div>
      </div>
    </div>
  );
};

export default DashboardFtue;
