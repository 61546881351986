import { makeAutoObservable } from "mobx"
import Moments from "../Moments"
import Action from "./Action"

class Actions {
  all = []
  selectedActionsId = {}

  constructor() {
    makeAutoObservable(this)
  }
  init(json) {
    this.all = json
    .filter(a => a.id)
    .map(elem => new Action(elem))
  }

  get ids() { return this.all.map(c => c.id)}

  get(id) { return this.all.find(c => c.id === id) }

  getByMoment(moment_id) { return this.all.filter(a => a.moment_id === moment_id) }


  getCurrentSelected() {
    return this.getByMoment(Moments.selected.id).filter(a => a.selected_current)
  }
  getPrevSelected() {
    return this.all.filter(a => a.selected_prev)
  }

  get allSelectedIds() {
    return Object.keys(this.selectedActionsId)
  }

  isActionSelected(actionId) {
    let res = this.allSelectedIds.indexOf(actionId) > -1
    console.log("isActionSelected", actionId, res)
    return res
  }
  toggleAction(action) {
    if(action.selected_prev) return

    if(action.selected_current) {
      this.unselectAction(action)
    } else {
      this.selectAction(action)
    }

  }
  selectAction(action, force = false) {
    if(action.selected_prev) return

    const over_budget = force ? false : Moments.selected.selected_actions_cost + action.cost > Moments.selected.budget
    if(!over_budget) {
      this.selectedActionsId[action.id] = Moments.selected.id
    }
  }
  unselectAction(action) {
    delete this.selectedActionsId[action.id]
  }

  unselectAllActions() {
    for(let action_id in this.selectedActionsId) {
      if(this.selectedActionsId[action_id] === Moments.selected.id) {
        delete this.selectedActionsId[action_id]
      }
    }
  }

}


export default new Actions()