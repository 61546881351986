import React, { useState } from 'react'
import PdfViewer from '../PdfViewer/PdfViewer'

import './MediasGallery.css'


const TYPES = {
  IMAGE : "IMAGE",
  PDF : "PDF",

}

const get_type = url => {
  const ext = url.split(".").pop()
  console.log("ext", ext)
  if(ext === "pdf") return TYPES.PDF

  return TYPES.IMAGE
}

const MediasGallery = ({urls, onCloseClick}) => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  const url = urls[visibleIndex]

  function nextImage(e) {
    e.stopPropagation()
    setVisibleIndex(i => i + 1)
  }
  function prevImage(e) {
    e.stopPropagation()
    setVisibleIndex(i => i - 1)

  }

  const ext = get_type(url)

  return (
    <div className="MediasGallery" >

      <div className="MediasGallery-close" onClick={onCloseClick}>&times;</div>


      {ext === TYPES.IMAGE &&
      <div className="MediasGallery-element" style={{backgroundImage: `url(${url})`}} />}

      {ext === TYPES.PDF &&
      <div className="MediasGallery-element">
        <PdfViewer url={url} />
      </div>}

      {urls.length > 1 && visibleIndex < urls.length - 1 &&
      <button className='MediasGallery-next' onClick={nextImage}>{">"}</button>}

      {urls.length > 1 && visibleIndex > 0 &&
      <button className='MediasGallery-prev' onClick={prevImage}>{"<"}</button>}

    </div>

  );
};

export default MediasGallery;
