import { useState, useEffect } from 'react';

function useAdaptativefontSize(ref, minFontSize=15, maxFontSize, maxLines) {
  const [fontSize, setFontSize] = useState(minFontSize);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    let element = ref.current

    let hasNoScroll = element.offsetHeight >= element.scrollHeight
    let hasFewLines = Math.floor(element.scrollHeight / (45 * 0.9)) <= maxLines
    let isValidSize = fontSize <= maxFontSize
    // console.log("hasNoScroll", hasNoScroll)
    // console.log("hasFewLines", hasFewLines, Math.floor(element.scrollHeight / (45 * 0.9)))
    // console.log("isValidSize", isValidSize)

    let canGoBigger = hasNoScroll && hasFewLines && isValidSize
    if(canGoBigger) {
      if(!finished) setFontSize(size => size + 2)
    }
    else {
      if(!finished) setFontSize(sz => sz - 2) // on revient à la valeur précédente
      setFinished(true)
    }

    return () => {}

  }, [fontSize]);


  return [fontSize, finished]
}


export default useAdaptativefontSize