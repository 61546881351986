import React, { useEffect, useState } from 'react'
import { useRef } from 'react';

import './FadedScroll.css'

const FadedScroll = ({children}) => {
  const [topGradientVisible, setTopGradientVisible] = useState(false);
  const [bottomGradientVisible, setBottomGradientVisible] = useState(false);


  const scrollerRef = useRef(null)

  useEffect(() => {
    calculateVisibilities()
  }, []);


  function calculateVisibilities() {

    const elem = scrollerRef.current

    setTopGradientVisible(elem.scrollTop > 0)

    let h = elem.offsetHeight
    let scrollH = elem.scrollHeight - elem.scrollTop

    let bottomVisible = Math.abs(h - scrollH) > 3 // 3px de marge d'erreur

    setBottomGradientVisible(bottomVisible)
  }


  return (
    <div className="FadedScroll-ctnr">
      {topGradientVisible && <div className="FadedScroll-overlay-top" />}

      <div className="FadedScroll" onScroll={calculateVisibilities} ref={scrollerRef}>
        {children}
      </div>

      {bottomGradientVisible&& <div className="FadedScroll-overlay-bottom" />}
    </div>
  );
};

export default FadedScroll;
