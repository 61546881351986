
import './MomentButton.css'

import moment_locked from "../../../images/moment_selection/moment_locked.png"
import moment_stars_0 from "../../../images/moment_selection/moment_stars_0.png"
import moment_stars_1 from "../../../images/moment_selection/moment_stars_1.png"
import moment_stars_2 from "../../../images/moment_selection/moment_stars_2.png"
import moment_stars_3 from "../../../images/moment_selection/moment_stars_3.png"
import AppState from '../../../js/AppState'
import GameSequence from '../../../js/GameSequence'
import Moments from '../../../js/Moments'
import Texts from '../../../js/Texts'
import NeonButton from '../../shared/NeonButton/NeonButton'

const MomentButton = ({moment, selected, onClick}) => {

  function handlePlayClick() {
    Moments.select(moment)
    GameSequence.loadMomentSequence(moment.id)
  }

  function handleSelect(moment) {
    if(!AppState.debugMode) {
      if(moment.locked) return
      if(moment.previously_selected) return
    }

    onClick(moment)

  }

  const playable = !moment.locked && moment.stars === null

  let className = "MomentButton"
  className += moment.locked ? " locked" : ""
  className += playable ? " playable" : ""
  className += selected ? " selected" : ""

  return (
    <button className={className} disabled={moment.locked} onClick={() => handleSelect(moment)}>
      <div className="moment-title">{moment.title}</div>

      <div className="moment-score">
        {moment.locked                            && <span><img alt="" src={moment_locked} />{Texts.get("moment_locked")}</span>}
        {playable                                 && <span><img alt="" src={moment_stars_0} /></span>}
        {!moment.locked && moment.stars === 1     && <span><img alt="" src={moment_stars_1} /></span>}
        {!moment.locked && moment.stars === 2     && <span><img alt="" src={moment_stars_2} /></span>}
        {!moment.locked && moment.stars === 3     && <span><img alt="" src={moment_stars_3} /></span>}
      </div>

      {(selected && AppState.solo_game) &&
      <div className="moment-play-btn">
        <NeonButton onClick={handlePlayClick} element="div" >
          <div className="img-ctnr"></div>
          {Texts.get("moment_play_button")}
        </NeonButton>
      </div>}

      {(selected && !AppState.solo_game) &&
      <div className="moment-play-btn">
        <NeonButton element="div" >
          <div className="wait-spinner"></div>

          {Texts.get("moment_wait_gamemaster")}
        </NeonButton>
      </div>}


    </button>
  )
};

export default MomentButton;
