import Actions from "../Actions/Actions"
import Moments from "../Moments"
import Enabler from "./Enabler"

class Enablers {
  all = []

  init(enablers, categs) {

    this.categories = categs.filter(c => c.id !== null)

    this.all = enablers
    .filter(elem => elem.id).map(elem => new Enabler(elem))

  }

  get ids() { return this.current.map(c => c.id)}


  get(id) { return this.all.find(c => c.id === id) }

  get boughtEnablers() {
    return this.all.filter(e => e.bought).sort((a, b) =>{
      if(a.moment.index < b.moment.index) return -1
      if(a.moment.index > b.moment.index) return 1
      return 0
    })
  }





  getBoughtEnablersByCateg(categ) {
    return this.boughtEnablers.filter(e => e.category === categ)
  }


  buy() {
    let actions = Actions.getCurrentSelected()
    actions.forEach(a => {
      if(a.enablers) {
        a.enablers.forEach(e => {
          e.buy(Moments.selected.id)
        })
      }
    })
  }
}


export default new Enablers()