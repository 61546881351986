import { makeObservable, observable } from "mobx"
import { pickRandom, randomRange } from "../utils"

class Personnage {
  speech_character = null
  reactComponentUpdateCallBack = null

  speedfactor = 1
  soundRef = null

  raf = null
  lastTS = 0

  charData = null

  loaded = false

  constructor(json) {
    this.charData = json
    this.id = json.id

    this.speech_character = new window.SpeechCharacter(this.id, this.charData.options) // https://github.com/Succubus-Interactive/characterlib-succubus

    this.update = this.update.bind(this)
  }

  load() {
    // un personnage est composé de plusieurs attitudes. On charge les json des attitudes, puis on charge les fichiers .basis nécessaires,
    const proms = this.charData.animations.map( anim =>  {
      return this.loadAnim(anim.id, this.charData.resource + anim.resource + '.crunch/')
    })

    return Promise.all(proms)
    .then(() => this.loadAnimRequiredFiles())
    .then(() => this.loaded = true)
  }

  unload() {
    this.stopAnim()

    this.raf = null
    this.charData = null
    this.loaded = false
    this.reactComponentUpdateCallBack = null
    this.speech_character.delete()
    this.speech_character = null
  }

  loadAnim(anim_name, path) {
    const json_path = path + "anim.json";
    return fetch(json_path)
    .then(res => res.text())
    .then(json_txt => {
      this.speech_character.addAnim(anim_name, json_txt, path);
    })
  }

  loadAnimRequiredFiles() {
    let files = this.speech_character.getFilesToLoad()
    let promises = files.map(file => {
      return fetch(file.basePath + file.fileName)
      .then(res => res.arrayBuffer())
      .then(buffer => {
        // console.log("file", file.animationName, file.fileName)
        this.speech_character.setAnimData(file.animationName, file.fileName, buffer);
      })
    })
    return Promise.all(promises)
  }


  neutralTimeout = null
  startIdle() {
    let idleAttitudes = window.CONFIG.idleAttitudes || ["interet1", "interet2", "concentration1", "concentration2"]
    // permet d'afficher le perso en idle neutre
    this.startAnim("", "neutre", null, null, null)

    let att = pickRandom(idleAttitudes)


    this.neutralTimeout = setTimeout(() => {
      this.startAnim("", att, null, null, null)

      this.neutralTimeout = setTimeout(() => {
        this.startIdle()
      }, randomRange(6000, 10000))

    }, randomRange(6000, 10000))
  }

  startAnim(text, attitudeTimings, textTimings, howlRef, phonemeContent) {
    if(this.raf) this.stopAnim()

    this.soundRef = howlRef
    let duration = this.soundRef ? this.soundRef.duration() * this.speedfactor : 6

    this.speech_character.setContentRaw(text, attitudeTimings, textTimings , duration, phonemeContent)
    this.lastTS = performance.now()
    this.raf = window.requestAnimationFrame(this.update)
  }

  stopAnim() {
    window.cancelAnimationFrame(this.raf)
    this.raf = null
    this.soundRef = null

    if(this.neutralTimeout) clearTimeout(this.neutralTimeout)
  }

  registerUpdate(reactUpdate) {
    // console.log("registering the update function")
    this.reactComponentUpdateCallBack = reactUpdate
  }

  update(currentTS) {
    let dt = (currentTS - this.lastTS)  / 1000 * this.speedfactor
    let currentTime = this.soundRef ? this.soundRef.seek() : 0
    // let currentTime = this.soundRef.seek()


    this.speech_character.update(dt, currentTime) // dt: ms, currentTime: secondes ??

    this.lastTS = currentTS

    if(this.reactComponentUpdateCallBack) {
      this.reactComponentUpdateCallBack(this.speech_character.getBuffer(), this.speech_character.getWidth(), this.speech_character.getHeight())
    }

    this.raf = requestAnimationFrame(this.update)
  }


}

class Personnages {
  all = []

  current = null
  constructor() {
    makeObservable(this, {
      current: observable
    })
  }
  init(json) {
    // json au format .char !
    this.all = json
    .filter(char => char.id)
    .map(char => new Personnage(char))
  }
  addCharFile(json_char) {
    this.all.push(new Personnage(json_char))
  }

  loadAll() {
    // ne va load que ceux pas déja load !
    let proms = this.all
    .filter(perso => !perso.loaded)
    .map(perso => perso.load())


    return Promise.all(proms)
  }

  loadChars(ids) {
    let proms = ids.map(id => this.get(id))
    .filter(perso => !perso.loaded)
    .map(perso => perso.load())


    return Promise.all(proms)
  }

  loadChar(id) {
    let char = this.get(id)
    if(char && !char.loaded) {
      return char.load()
    }
  }

  unloadChar(ids) {
    ids.forEach(id => {
      let char = this.get(id)
      let ind = this.all.indexOf(char)
      if (ind > -1) { // only splice array when item is found
        char.unload()
        this.all.splice(ind, 1); // 2nd parameter means remove one item only
      }
    })

  }

  waitEngineInit() {
    const loop = (resolve) =>  {
      if (window.characterengineInit) resolve()
      else setTimeout(() => loop(resolve), 200)
    }

    return new Promise( resolve =>  loop(resolve) )
  }

  get ids() { return this.current.map(c => c.id)}
  get(id) { return this.all.find(c => c.id === id) }

}


export default new Personnages()
