import { action, makeObservable, observable } from "mobx";
import DataLoading from "react-sdk/DataLoading";
import Notifications from "react-sdk/Notifications/Notifications";
import { defaultCols, Dial } from "react-sdk/Personnage/Dialogues";
import PopupsManager from "react-sdk/PopupsManager";
import SequencesManager, { ElementColumns, SequencesFile } from "react-sdk/SequencesManager";
import { getUrlVar, makeId } from "react-sdk/utils";
import Actions from "./Actions/Actions";
import AppState, { POPUPS, SCREENS } from "./AppState";
import Enablers from "./Enablers/Enablers";
import Moments from "./Moments";
import Scores from "./Scores";
import Usecases from "./Usecases/Usecases";

const sequencesIds = [
"seq_0",
"seq_1",
"seq_2",
"seq_3",
"seq_4",
"seq_5",
"seq_6"
]


export const SCREEN_TYPES = {
  CLEAR: "CLEAR",
  TEXT: "TEXT",
  MEDIA: "MEDIA",
  YOUTUBE: "YOUTUBE",
  MOMENT_SELECTION: "MOMENT_SELECTION",
  MOMENT_DECISION: "MOMENT_DECISION",

  MOMENT_STARS: "MOMENT_STARS",
  DASHBOARD: "DASHBOARD",
  END: "END",
  PAUSE: "PAUSE"

}
class GameSequence {

  all = []

  lastMeaningfullTitle = null
  lastMeaningfullContent = null
  lastMeaningfullScreenType = null

  sequenceIndex = 0
  current = null
  currentDials = []

  constructor() {
    makeObservable(this, {
      current: observable,
      setCurrent: action
    })
  }

  init() {

    console.log('GameSequence init', this.sequenceIndex)
    let prevScreen = AppState.screen
    AppState.setScreen(SCREENS.LOADING)
    let seqId = sequencesIds[this.sequenceIndex]

    let sequence = DataLoading.files[seqId]

    // petits traitements de la séquence, j'ajoute des colonnes qu'on ne veut pas voir dans le fichier
    sequence
    .forEach(s => {
      s.ID = makeId()
      s.SequenceID = "game"
      s.Goto = null
      s.Type = null

      if(s.screen_type === null) s.screen_type = SCREEN_TYPES.TEXT

      if(s.dial && s.Character && !s.Audio) s.Audio = "sound"
    })


    const cols = new ElementColumns()
    cols.VisibleIf = "execute_if"
    cols.Actions = "actions"

    // ajoute auto au SM
    let S = SequencesFile.createFromDataFile(seqId, sequence, cols)

    this.registerSequenceConditions()
    this.registerSequenceActions()



    return this.preloadSequenceAudio(S.elements).then(() => {
      this.sequence = SequencesManager.playSequence(seqId)
      this.setCurrent()
      AppState.setScreen(prevScreen)
    })
  }

  preloadSequenceAudio(sequence) {

    if(!window.CONFIG.preloadDials) {
      return Promise.resolve()
    }

    this.currentDials = []
    let cols = {...defaultCols, text: "dial"}


    sequence.forEach(elem => {
      if(this.isElementDial(elem)) {
        this.currentDials.push(new Dial(elem.raw, window.CONFIG.speech, cols))
      }
    })

    return Promise.all(this.currentDials.map(d => d.load()))
  }

  setCurrent() {
    if(
      this.sequence.getCurrentElement().raw.skip_if_quickgame &&
      AppState.meeting_scenario.quickGameMode
    ) {
        return this.next()
    }


    this.current = this.sequence.getCurrentElement()

    if(this.isElementMeaningfull(this.current)) {
      if(this.current.raw.screen_title) this.lastMeaningfullTitle = this.current.raw.screen_title
      if(this.current.raw.screen_content) this.lastMeaningfullContent = this.current.raw.screen_content
      this.lastMeaningfullScreenType = this.current.raw.screen_type
    }

    if(this.current.raw.screen_type === SCREEN_TYPES.CLEAR) {
      this.lastMeaningfullTitle = null
      this.lastMeaningfullContent = null
    }

    if( (this.current.raw.screen_type === SCREEN_TYPES.MEDIA ||  this.current.raw.screen_type === SCREEN_TYPES.TEXT)
          && getUrlVar("skip") !== null) {
      this.next() // autoskip des dials pour bosser sur le dashboard
    }

  }

  next() {
    if(this.sequence.hasNext()) {
      this.sequence.next()
      this.setCurrent()
    } else {
      // fin de la séquence, on passe à la suivante
      if(this.sequenceIndex === sequencesIds.length - 2) {
        this.sequenceIndex++
        this.init()
      }
    }
  }

  loadMomentSequence(moment_id) {
    let index = Moments.ids.findIndex(m_id => m_id === moment_id)
    this.sequenceIndex = index + 1// +1 car il y a la seq 0 qui est l'intro



    if(AppState.debugMode) AppState.setScreen(SCREENS.GAME_SEQUENCE)

    return this.init()
  }

  // Ici on met les éléments dont le titre et le contenu remplacent les précédents s'ils existent
  isElementMeaningfull(element) {

    const types = [
      SCREEN_TYPES.TEXT,
      SCREEN_TYPES.MEDIA,
      SCREEN_TYPES.YOUTUBE,
      SCREEN_TYPES.DASHBOARD,
      SCREEN_TYPES.MOMENT_STARS,
      SCREEN_TYPES.END
    ]

    return element && types.includes(element.raw.screen_type)
  }


  /**
   * renvoie un bool si l'on peut skip automatiquement à la fin du dial, s'il y a un dial !
   * Attention, c'est une
   * @param {*} element
   * @returns {boolean}
   */
  isElementSkippable(element) {

    const types = [
      SCREEN_TYPES.PAUSE,
      SCREEN_TYPES.MOMENT_SELECTION,
      SCREEN_TYPES.MOMENT_DECISION,
      // SCREEN_TYPES.YOUTUBE,
    ]

    return element && !types.includes(element.raw.screen_type)
  }

  isElementDial(element) {
    return element && element.raw.Character && element.raw.Audio && element.raw.dial
  }


  handleDials() {

    if(!window.CONFIG.preloadDials) {
      let cols = {...defaultCols, text: "dial"}
      let d = new Dial(this.current.raw, window.CONFIG.speech, cols, false)
      AppState.showCharacter(d)

    }
    else {
      let d = this.currentDials.find(dial => dial.data.ID === this.current.raw.ID)
      AppState.showCharacter(d)
    }
  }

  handleDialEnd() {

      AppState.showCharacter(false)

      if(this.isElementSkippable(this.current)){
        this.next()
      }
  }

  get ids() { return this.all.map(c => c.id)}
  get(id) { return this.all.find(c => c.id === id) }


  registerSequenceConditions() {
    const parseImpacts = (momentId, impactsStr) => {
      let momentValues = impactsStr.split(";").map(v => parseInt(v))

      let m = Moments.get(momentId)
      if(m) {
        let res = momentValues.includes(m.selected_actions_impact)
        return res
      } else {
        Notifications.error(`this moment id "${momentId}" does not exist`)
        return false
      }
    }


    SequencesManager.registerGetter("action", (action_id) => {
      let a = Actions.get(action_id)
      if(a) {
        return Actions.allSelectedIds.includes(action_id)
      }else {
        Notifications.error(`this action id "${action_id}" does not exist`)
        return false
      }
    })

    SequencesManager.registerGetter("currentImpacts", (impacts) => {
      return parseImpacts(Moments.selected.id, impacts)
    })

    SequencesManager.registerGetter("impacts", (momentId, impacts) => {
      return parseImpacts(momentId, impacts)
    })



    SequencesManager.registerGetter("minstars", (nb) => {
      nb = parseInt(nb)
      if(isNaN(nb)) {
        Notifications.error(`Error in function call <strong>minstars</strong> in <strong>Final_debriefs.data</strong>. Argument must be integer`)
        return false
      }
      return Moments.total_stars >= nb
    })


    SequencesManager.registerGetter("maxstars", (nb) => {
      nb = parseInt(nb)
      if(isNaN(nb)) {
        Notifications.error(`Error in function call <strong>maxstars</strong> in <strong>Final_debriefs.data</strong>. Argument must be integer`)
        return false
      }

      return Moments.total_stars <= nb
    })


    SequencesManager.registerGetter("stars", (nb) => {
      nb = parseInt(nb)
      if(isNaN(nb)) {
        Notifications.error(`Error in function call <strong>stars</strong> in <strong>Final_debriefs.data</strong>. Argument must be integer`)
        return false
      }
      return Moments.total_stars === parseInt(nb)
    })
    SequencesManager.registerGetter("currentstars", (nb) => {
      nb = parseInt(nb)
      if(isNaN(nb)) {
        Notifications.error(`Error in function call <strong>stars</strong> in <strong>Final_debriefs.data</strong>. Argument must be integer`)
        return false
      }
      return Moments.selected.stars === parseInt(nb)
    })

    SequencesManager.registerGetter("minscore", (score_id, value) => {
      let int_val = parseInt(value)
      if(isNaN(int_val)) {
        Notifications.error(`Error in function call <strong>minscore</strong> in <strong>Final_debriefs.data</strong>. Argument must be integer`)
        return false
      }

      return Scores.get(score_id) >= int_val
    })

    SequencesManager.registerGetter("maxscore", (score_id, value) => {
      let int_val = parseInt(value)
      if(isNaN(int_val)) {
        Notifications.error(`Error in function call <strong>maxscore</strong> in <strong>Final_debriefs.data</strong>. Argument must be integer`)
        return false
      }

      return Scores.get(score_id) <= int_val
    })


    SequencesManager.registerGetter("score", (score_id, value) => {
      let int_val = parseInt(value)
      if(isNaN(int_val)) {
        Notifications.error(`Error in function call <strong>score</strong> in <strong>Final_debriefs.data</strong>. Argument must be integer`)
        return false
      }

      return Scores.get(score_id) === int_val
    })

  }


  registerSequenceActions() {

    SequencesManager.registerAction("setSlot", (slot_id, uc_id) => {
      let uc = Usecases.get(uc_id)
      if(!uc) {
        Notifications.error(`Unknown solution with id <strong>${uc_id}</strong> in <strong>setSlot</strong>`)
        return false
      }

      Usecases.slots.set(slot_id, uc_id)

      uc.phases.push({
        moment_id: Moments.selected.id,
        failed: false
      })
      uc.moment_of_activation = Moments.selected.id // TODO déléte ce systeme quand l'autre fonctionnera
    })

    SequencesManager.registerAction("upscaleSlot", (slot_id) => {
      if(!Usecases.slots.has(slot_id)) {
        Notifications.error(`The solution slot <strong>${slot_id}</strong> doesn't exist in function call <strong>upscaleSlot</strong>`)
        return
      }
      let uc_id = Usecases.slots.get(slot_id)
      let uc = Usecases.get(uc_id)
      if(!uc) {
        Notifications.error(`The solution <strong>${uc_id}</strong> doesn't exist in function call <strong>upscaleSlot</strong>`)
        return
      }
      if(uc.phases.length < 3) {
        uc.phases.push({
          moment_id: Moments.selected.id,
          failed: false
        })
      } else {
        Notifications.error(`Trying to upscale ${uc_id} but it's already to max phase (2) !`)
      }
    })

    SequencesManager.registerAction("failSlot", slot_id => {
      if(!Usecases.slots.has(slot_id)) {
        Notifications.error(`The solution slot <strong>${slot_id}</strong> doesn't exist in function call <strong>failSlot</strong>`)
        return
      }

      let uc_id = Usecases.slots.get(slot_id)
      let uc = Usecases.get(uc_id)
      if(!uc) {
        Notifications.error(`The solution <strong>${uc_id}</strong> doesn't exist in function call <strong>failSlot</strong>`)
        return
      }

      uc.phases[uc.phases.length -1].failed = true
    })

    SequencesManager.registerAction("enabler", enabler_id => {
      let e = Enablers.get(enabler_id)
      if(!e) {
        Notifications.error(`Unknown enabler with id <strong>${enabler_id}</strong> in call <strong>enabler</strong>`)
        return false
      }
      e.buy(Moments.selected.id)

    })


    SequencesManager.registerAction("undoEnabler", enabler_id => {
      let e = Enablers.get(enabler_id)
      if(!e) {
        Notifications.error(`Unknown enabler with id <strong>${enabler_id}</strong> in call <strong>undoEnabler</strong>`)
        return false
      }

      e.bought = false

    })

    SequencesManager.registerAction("showFTUE", (type) => {
      PopupsManager.open(POPUPS.FTUE, type)
    })

  }

}


export default new GameSequence()
