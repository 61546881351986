import { makeAutoObservable } from "mobx"
import Enablers from "../Enablers/Enablers"
import FunctionStringParser from "../FunctionStringParser"
import Moments from "../Moments"
import Talents from "../Talents"
import Usecases from "../Usecases/Usecases"
import Actions from "./Actions"

import Notifications from 'react-sdk/Notifications/Notifications'


export const STATES = {
  SELECTABLE: "SELECTABLE",
  SELECTED: "SELECTED",
  TOO_EXPENSIVE: "TOO_EXPENSIVE",
  SELECTED_PREVIOUSLY: "SELECTED_PREVIOUSLY",
  LOCKED: "LOCKED"
}

export const TYPES = {
  LIST: "list",
  ENABLER: "enabler",
  USECASE: "usecase",
  TALENT: "talent",

}


class Action {

  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)

  }

  isCurrentlySelected(moment_id) {
    return Actions.selectedActionsId[this.id] === moment_id
  }

  wasPreviouslySelected(moment_id) {
    return Actions.selectedActionsId[this.id] !== undefined && Actions.selectedActionsId[this.id] !== moment_id
  }

  get selected_current() {
    return this.isCurrentlySelected(Moments.selected.id)
  }

  get selected_prev() {
    return this.wasPreviouslySelected(Moments.selected.id)
  }

  get have_been_selected_at_some_point() {
    return this.selected_current || this.selected_prev
  }

  // récupère les éléments constituant cette action
  // apparemment le type et l'élément peuvent ne pas être corrélés, donc on cherche partout
  get sub_elements() {
    if( !this.params ) return []

    const findElem = id => {
      let uc = Usecases.get(id)
      if(uc) return uc

      let e = Enablers.get(id)
      if(e) return e

      let t = Talents.get(id)
      if(t) return t

      const ucid_from_slot = Usecases.slots.get(id)
      if(ucid_from_slot) {
        let uc = Usecases.get(ucid_from_slot)
        if(uc) return uc
      }

      return null

    }

    let ids = this.params.split(";")
    let elems = ids.map(id => findElem(id)).filter(e => e !== null)

    return elems

  }

  get enablers() {
    return this.sub_elements.filter(e => e.type === TYPES.ENABLER)
  }

  get talents() {
    return this.sub_elements.filter(e => e.type === TYPES.TALENT)
  }

  get usecases() {
    return this.sub_elements.filter(e => e.type === TYPES.USECASE)
  }

  get playable() {



    if(!this.playable_if) return true

    const api = {
      selected: (action_id) => {
        let a = Actions.get(action_id)
        if(a) return a.have_been_selected_at_some_point
        else {
          Notifications.error(`The action ${action_id} doesn't exist, in "playable_if" column of action "${this.id}"`)
          return false
        }
      }
    }

    let fsp = new FunctionStringParser(api)
    const matches = fsp.getMatches(this.playable_if)
    let expr = fsp.execute(matches, this.playable_if)
    return fsp.evaluate(expr)
  }

  get state() {
    if(!this.playable) return STATES.LOCKED
    if(this.selected_prev) return STATES.SELECTED_PREVIOUSLY

    // si cette card linke un seul enabler déja acheté, alors cette carte passe en DONE automatiquement
    if(this.type === "enabler"
      && this.enablers
      && this.enablers.length === 1
      && this.enablers[0].bought
    ) return STATES.SELECTED_PREVIOUSLY

    if(this.selected_current) return STATES.SELECTED

    if(Moments.selected.selected_actions_cost + this.cost > Moments.selected.budget) return STATES.TOO_EXPENSIVE




    return STATES.SELECTABLE
  }
}

export default Action