import React from 'react'

import './NeonButton.css'

const NeonButton = ({onClick, small=false, disabled=false, element="button", className = "", children}) => {

  if(small) className += " small"

  let Element = element

  return (
    <Element className={"NeonButton "  + className} disabled={disabled} onClick={onClick} >
      {children}
    </Element>
  );
};

export default NeonButton;
