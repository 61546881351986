import {Howler, Howl } from "howler"
import { makeObservable, observable } from "mobx"
import SequencesManager, {SequencesFile} from "../SequencesManager"
import Personnages from "./Personnages"


export let defaultCols = {
  text: "Text",
  character: "Character",
  audio: "Audio",
  textTimings: "TextTimings",
  attitudeTimings: "AttitudeTimings"
}

export class Dial {
  personnage = null
  onEndCallback = null
  config = null
  isLoaded = false
  isEnded = false

  constructor(json, config, _cols) {
    // ici charger l'audio

    _cols = _cols || config.cols

    let cols = Object.assign({}, defaultCols, _cols)

    this.data = json
    this.config = config
    this.cols = cols

    this.personnage = Personnages.get(this.data[cols.character])
  }

  setSubtitleCallback(subtitleCallback) {
    this.personnage.speech_character.setTextCallback(subtitleCallback)
  }

  load() {

    if(this.isLoaded) return Promise.resolve(this)

    const src =   (this.config.audios_root || "") + this.data[this.cols.audio]
    let proms = [
      this.loadAudio(src),
      this.loadPhonemes(src)
    ]
    return Promise.all(proms)
    .then(() => {
      this.isLoaded = true
      return this
    })
  }

  loadAudio(src) {
    src = src + ".mp3"
    return new Promise( (resolve, reject) => {
      this.sound = new Howl({
        src: [src],
        onload: () => {
          resolve()
        },
        onloaderror: (sound_id, code) => {
          console.log("ERROR LOADING", src, sound_id, code)
          resolve()
        }
      })
    })
  }

  loadPhonemes(src) {
    return new Promise( (resolve, reject) => {
      fetch(src + ".json")
      .then(response => response.text())
      .then(json => {
        if(json === "") json = '{"mouthCues":[]}' // si erreur de loading, on met des fausses phonemes
        this.phonems = json
        resolve()
      })
      .catch(err => {
        console.log("ERROR LOADING PHONEME", src)
        this.phonems = '{"mouthCues":[]}'
        resolve()
      })

    })
  }

  skip() {
    if(!this.isLoaded) return // si on skip avant que l'audio soit load, on a des bugs de load
    if((this.sound.state() === "unloaded" || this.sound.state() === "loading") && this.onEnd) {
      this.onEnd(true)
      return
    }

    if(this.personnage.speech_character.getNextSegmentTime) {
      let jumpTo = this.personnage.speech_character.getNextSegmentTime(this.sound.seek())
      if(jumpTo === -1) {
        this.onEnd(true)
      }
      else {
        this.sound.seek(jumpTo)
      }

    }
    else {
      this.onEnd(true)

    }

  }

  play(onPlayEnd) {
    this.onEndCallback = onPlayEnd
    this.personnage.startAnim(this.data[this.cols.text], this.data[this.cols.attitudeTimings], this.data[this.cols.textTimings], this.sound, this.phonems)

    this.sound.on('end', () => this.onEnd())
    this.sound.play()
  }

  pause() {
    this.sound.pause()
  }

  resume() {
    this.sound.play()
  }
  stop() {
    this.sound.stop()
  }
  end_timeout = null
  onEnd(immediate = false) {
    if(this.isEnded) return
    this.isEnded = true

    clearTimeout(this.end_timeout)
    this.sound.stop()
    this.sound.unload()
    if(this.onEndCallback) {
      let end_duration = immediate ? 0 : (this.config.dialEndTimeout || 500)

      this.end_timeout = setTimeout(() => {
        this.onEndCallback()
      }, end_duration)
    }
  }
}


export default class Dialogues {

  id = null
  current = null
  onSequenceEnd = null

  constructor(data, id) {
    this.id = id
    SequencesFile.createFromDataFile(this.id, data.filter(elem => elem.ID)) // ajoute auto au SM

    makeObservable(this, {current: observable})
  }

  hasSequence(sequenceId) {
    let has = SequencesManager.hasSequence(this.id, sequenceId)
    return has
  }

  playSequence(sequenceId, onSequenceEnd, subtitleCallback) {
    this.onSequenceEnd = onSequenceEnd
    const sequence = SequencesManager.playSequence(this.id, sequenceId)
    if (sequence) this.createDial(sequence, subtitleCallback)

    return sequence

  }

  createDial(sequence, subtitleCallback, preload) {
    // this.current = null
    this.current = new Dial(sequence.getCurrentElement().raw, window.CONFIG.speech)
    this.current.setSubtitleCallback(subtitleCallback)

    this.current.load()
    .then(() => {
      this.current.play(() => {
        this.handleNext(sequence, subtitleCallback)
      })
    })
  }

  handleNext(sequence, subtitleCallback) {

    if(sequence.hasNext()) {
      sequence.next()

      this.createDial(sequence, subtitleCallback)

    } else {
      let cb = this.onSequenceEnd
      this.onSequenceEnd = null

      setTimeout( () => {
        // fin de la séquence !!!
        this.current = null
        if(cb) cb()
      }, 500)
    }
  }


  get ids() { return this.current.map(c => c.id)}


  get(id) { return this.all.find(c => c.id === id) }

}
