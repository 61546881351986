import React from 'react'

import './Title.css'

const Title = ({children}) => {

  return (
    <div className="Title" dangerouslySetInnerHTML={{__html: children}} />
  );
};

export default Title;
