import { observer } from 'mobx-react-lite';
import React from 'react'
import AppState from '../../../js/AppState';

import './DialOverlay.css'

const DialOverlay = () => {

  return <div className={"DialOverlay" + (AppState.character ? " visible" : "") }/>

};

export default observer(DialOverlay)
