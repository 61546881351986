import React from 'react';
import GameSequence, { SCREEN_TYPES } from '../../js/GameSequence';

import NeonButton from '../shared/NeonButton/NeonButton';
import Title from '../shared/Title/Title';

import { observer } from 'mobx-react-lite';

import YouTube from 'react-youtube';

import DialOverlay from '../shared/DialOverlay/DialOverlay';
import './ScreenText.css';
import { useRef } from 'react';
import AppState from '../../js/AppState';
import { useEffect } from 'react';
const ScreenText = () => {

  const playerRef = useRef(null)


  useEffect(() => {
    if(!playerRef.current) return

    if(AppState.multiplayer_overlay) {
      playerRef.current.pauseVideo()
    }
    else {
      playerRef.current.playVideo()
    }
  }, [AppState.multiplayer_overlay]);






  let elem = GameSequence.current.raw

  let type = GameSequence.lastMeaningfullScreenType

  let title = GameSequence.lastMeaningfullTitle
  let content = GameSequence.lastMeaningfullContent

  let isText = !([SCREEN_TYPES.MEDIA, SCREEN_TYPES.YOUTUBE].includes(type))


  const youtubeConfig = {
    autoplay: AppState.multiplayer_overlay ? 0 : 1,
    controls: 0,
    cc_load_policy: 0,
    fs: 1,
    iv_load_policy: 3,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
  }


  return (
    <div className={"screen ScreenText " + type}>

      {type !== SCREEN_TYPES.YOUTUBE &&
      <div className="col1">
        <Title>{title}</Title>
      </div>}

      <DialOverlay />

      <div className="col2">

        {type === SCREEN_TYPES.MEDIA &&
        <div className="content">
          <img src={AppState.medias_folder + content} alt="" />
        </div>}

        {type === SCREEN_TYPES.YOUTUBE &&
        <div className="content">
          <YouTube
            onReady={e => playerRef.current = e.target}
            videoId={content}
            opts={{ height: '900', width: '1600', playerVars: youtubeConfig}} />


        </div>}

        {isText &&
        <div className="content" dangerouslySetInnerHTML={{__html: GameSequence.lastMeaningfullContent}} />}


      </div>


      <div className="col3">
        <div />
        {elem.screen_type === "PAUSE" &&
        <NeonButton onClick={() => GameSequence.next()}>{elem.screen_content}</NeonButton>}
      </div>

    </div>
  );
};

export default observer(ScreenText)
