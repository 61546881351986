import React from 'react';
import Talents from '../../../../js/Talents';

import Talent from './Talent/Talent';

import { observer } from 'mobx-react-lite';
import PopupsManager from 'react-sdk/PopupsManager';
import { TYPES } from '../../../../js/Actions/Action';
import AppState, { POPUPS } from '../../../../js/AppState';
import './Square.css';


const TalentsSquare = ({className, transform=""}) => {
  let t = Talents.hired

  let nb_internal = 0
  let nb_external = 0

  if(t) {
    nb_internal = t.nb_internal
    nb_external = t.nb_external
  }

  let translates = [
    "-105.88 32.231", "-78.382 48.106", "-50.885 63.981",
    "-133.37 48.106", "-105.88 63.981", "-78.382 79.856",
    "-160.87 63.981", "-133.37 79.856", "-105.88 95.731",
  ]

  let data = PopupsManager.getData(POPUPS.DASHBOARD_POPUP)

  let chars = new Array(9).fill(0)
  .map((_, index) => {

    if(index < nb_internal) {
      return (
        <Talent
          key={index}
          onClick={evt => AppState.openDashboardPopup(evt, {type: TYPES.TALENT, index, talent_type: "internal"})}
          isExternal={false}
          visible
          blink={t.just_bought}
          transform={`translate(${translates[index]})`} />
      )
    }
    else if(index < nb_internal + nb_external) {
      return  (
        <Talent
        key={index}
        onClick={evt => AppState.openDashboardPopup(evt, {type: TYPES.TALENT, index, talent_type: "external"})}
        isExternal={true}
        blink={t.just_bought}
        visible
        transform={`translate(${translates[index]})`} />
      )
    }

    else {
      return <Talent key={index} transform={`translate(${translates[index]})`} />
    }

  })

  // insertion du talent highlighted juste avant le talent cliqué pour qu'il apparaisse juste derrière
  if(data && data.elem.type === TYPES.TALENT) {
    chars.splice(data.elem.index, 0,
    <Talent
      highlighted={true}
      isExternal={data.elem.talent_type === "exernal"}
      visible
      key='highlighted-talent'
      transform={`translate(${translates[data.elem.index]})`} />)
  }


  return (
    <g className={"Square " + className} transform={transform}>
      <path className="Square-base" d="m183.31 52.917 91.654 52.917-91.654 52.917-91.654-52.917z" />

      <g transform='translate(275 5)'>
        {chars}
      </g>

    </g>
  );
};

export default observer(TalentsSquare)
