import { v4 as uuidv4 } from 'uuid';
import {Howler} from "howler"


export const getHowlerInstance = () => Howler
export const pickRandom = arr => arr[Math.floor( Math.random() * arr.length )]
export const arrayIntersection = (arr1, arr2) => arr1.filter(x => arr2.includes(x))

export const arrayShuffle = array => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const arrayDifference = (arr1, arr2) => arr1.filter(x => arr2.indexOf(x) === -1)
export const arrayRemoveDuplicates = (arr) => arr.filter((x, index) => arr.indexOf(x) === index)

export const randomRange = (min = 0, max = 1) => Math.random() * (max - min) + min

export const Round = (val, precision = 0) => {
  if(val !== undefined) {
    let coef = Math.pow(10, precision)
    val = Math.round((val + Number.EPSILON) * coef) / coef
    val = val.toFixed(precision)
  }
  return val
}

export const clamp = (min, max, val) => {
  return Math.max(min, Math.min(max, val))
}

export const makeId = () => uuidv4()
export const secsToMinSecs = s => ((s-(s%=60))/60).toString().padStart(2, '0')+(9<s?':':':0')+s


export const secsToTime = (s, withHours = true) => {
  let hours
  if(withHours) {
    hours = Math.floor(s / 3600);
    s %= 3600;
  }
  let min = Math.floor(s / 60)
  let secs = Math.floor(s % 60)

  let obj = {min, secs}
  if(withHours) {
    obj.hours = hours
  }
  return obj
}


export const getUrlVar = (name, source_url = window.location.href, base_url = window.location.origin) => {
  var url = new URL(source_url, base_url);
  return url.searchParams.get(name);
}
export const removeUrlVar = (name, source_url = window.location.href, base_url = window.location.origin) => {
  var url = new URL(source_url, base_url);
  url.searchParams.delete(name)
  window.history.replaceState(null, "", url.href);
}


export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}


export const mapValues = (val, start1, stop1, start2, stop2, withinBounds) => {
  const newval = (val - start1) / (stop1 - start1) * (stop2 - start2) + start2;
  if (!withinBounds) {
    return newval;
  }
  if (start2 < stop2) {
    return clamp(start2, stop2, newval)
  } else {
    return clamp(stop2, start2, newval)
  }
};