import React from 'react'
import { useState } from 'react';
import { getUrlVar } from 'react-sdk/utils';
import AppState from '../../js/AppState';
import GameSequence from '../../js/GameSequence';
import Texts from '../../js/Texts';
import ScreenshotMatrix from '../ScreenshotMatrix/ScreenshotMatrix';
import NeonButton from '../shared/NeonButton/NeonButton';
import Title from '../shared/Title/Title';

import './ScreenHome.css'
let code_url = getUrlVar("code")
const ScreenHome = () => {

  const [code, setCode] = useState(code_url ? code_url : "");
  const [pseudo, setPseudo] = useState("");
  const [error, setError] = useState(null);

  function handleSubmit(e) {
    e.preventDefault()

    AppState.meetingconnect(code, pseudo)
    .catch(err => {
      console.log("err", err)
      setError(err.toString())
    })
  }
  if(getUrlVar("screenshotmode") !== null) {
    return (
      <div className="screen ScreenHome">
        <ScreenshotMatrix />
      </div>
    )

  }


  return (
    <div className="screen ScreenHome">

      <div className="col2">
        <Title>{Texts.get("home_title")}</Title>

        <form onSubmit={handleSubmit}>
          <input
          type="text"
          value={code}
          onChange={e => setCode(e.target.value)}
          placeholder={Texts.get("code_placeholder")} />

          <input
          type="text"
          value={pseudo}
          onChange={e => setPseudo(e.target.value)}
          placeholder={Texts.get("username_placeholder")} />

          <NeonButton>{Texts.get("home_submit_button")}</NeonButton>

          {error && <div className="error">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default ScreenHome;
