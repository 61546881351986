import { makeAutoObservable } from "mobx"
import { TYPES } from "./Actions/Action"
import Actions from "./Actions/Actions"
import Moments from "./Moments"

class Talent {
  type = TYPES.TALENT

  hired = false

  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }

  get just_bought() {
    return this.hired === Moments.selected.id
  }
}


class Talents {
  all = []

  init(json) {
    this.all = json
    .filter(elem => elem.id).map(elem => new Talent(elem))
  }

  get ids() { return this.all.map(c => c.id)}


  get(id) { return this.all.find(c => c.id === id) }

  hire() {
    let actions = Actions.getCurrentSelected()
    actions.forEach(a => {
      if(a.talents) {
        a.talents.forEach(t => {
          t.hired = Moments.selected.id
        })
      }
    })
  }


  get hired() {
    return this.all.find(t => t.hired)
  }



}


export default new Talents()