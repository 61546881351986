import React from 'react'

import './Talent.css'

const Talent = ({visible, onClick, isExternal, highlighted = false, blink=false, transform}) => {
  // transform += " scale(0.55)"
  function handleClick(evt) {
    if(onClick) onClick(evt)

  }
  return (
    <g className={'Talent' + (blink ? ' blink' : '') + (onClick ? " clickable" : "") + (highlighted ? " highlighted" : "")} transform={transform} onClick={handleClick}>
      <defs id="defs48">
        <linearGradient id="Dégradé_sans_nom_6" x1={56} x2={-0.28} y1={45.37} y2={60.83} gradientUnits="userSpaceOnUse">
          <stop id="stop4" stopColor="#08ffff" offset={0} />
          <stop id="stop6" stopColor="#0df7f2" offset={0.04} />
          <stop id="stop8" stopColor="#19e2ce" offset={0.12} />
          <stop id="stop10" stopColor="#2dbf95" offset={0.22} />
          <stop id="stop12" stopColor="#3ea366" offset={0.3} />
          <stop id="stop14" stopColor="#3aa971" offset={0.34} />
          <stop id="stop16" stopColor="#1fd7bd" offset={0.66} />
          <stop id="stop18" stopColor="#0ef4ed" offset={0.89} />
          <stop id="stop20" stopColor="#08ffff" offset={1} />
        </linearGradient>
        <linearGradient id="Dégradé_sans_nom_5" x1={23.67} x2={30.59} y1={56.83} y2={60.87} gradientUnits="userSpaceOnUse" >
          <stop id="stop23" stopColor="#1657ad" offset={0.17} />
          <stop id="stop25" stopColor="#1b4c2d" offset={1} />
        </linearGradient>
        <linearGradient id="Dégradé_sans_nom_5-2" x1={21.91} x2={30.71} y1={46.48} y2={46.48} xlinkHref="#Dégradé_sans_nom_5" />
        <linearGradient id="Dégradé_sans_nom_4" x1={14.3} x2={18.44} y1={45.42} y2={37.35} gradientUnits="userSpaceOnUse">
          <stop id="stop29" stopColor="#c2e8ff" offset={0.03} />
          <stop id="stop31" stopColor="#c2edff" offset={0.2} />
          <stop id="stop33" stopColor="#c1ffff" offset={1} />
        </linearGradient>
        <linearGradient id="Dégradé_sans_nom_4-2" x1={33.17} x2={37.11} y1={36.33} y2={43.61} xlinkHref="#Dégradé_sans_nom_4" />
        <linearGradient id="Dégradé_sans_nom_3" x1={51.39} x2={8.67} y1={40.8} y2={1.26} gradientUnits="userSpaceOnUse" >
          <stop id="stop37" stopColor="#3ea366" offset={0.32} />
          <stop id="stop39" stopColor="#3aa971" offset={0.36} />
          <stop id="stop41" stopColor="#1fd7bd" offset={0.66} />
          <stop id="stop43" stopColor="#0ef4ed" offset={0.87} />
          <stop id="stop45" stopColor="#08ffff" offset={0.97} />
        </linearGradient>
        <linearGradient id="Dégradé_sans_nom_2" x1={30.97} x2={27.05} y1={35.47} y2={60.91} gradientUnits="userSpaceOnUse">
          <stop stopColor="#326cfc" offset={0.03} />
          <stop stopColor="#1dadfd" offset={0.46} />
          <stop stopColor="#0eddff" offset={0.82} />
          <stop stopColor="#08efff" offset={1} />
        </linearGradient>
        <linearGradient id="Dégradé_sans_nom_213" x1={54.9} x2={10.47} y1={54.14} y2={59.09} gradientUnits="userSpaceOnUse">
          <stop stopColor="#a8f9ff" offset={0.07} />
          <stop stopColor="#a3ecff" offset={0.16} />
          <stop stopColor="#98c8ff" offset={0.31} />
          <stop stopColor="#8fafff" offset={0.41} />
          <stop stopColor="#92b7ff" offset={0.47} />
          <stop stopColor="#a2e7ff" offset={0.82} />
          <stop stopColor="#a8f9ff" offset={1} />
        </linearGradient>
        <linearGradient id="Dégradé_sans_nom_216" x1={46.6} x2={13.96} y1={51.94} y2={-0.12} gradientUnits="userSpaceOnUse">
          <stop stopColor="#08efff" offset={0} />
          <stop stopColor="#09ebff" offset={0.06} />
          <stop stopColor="#0de0ff" offset={0.12} />
          <stop stopColor="#13cefe" offset={0.18} />
          <stop stopColor="#1bb4fe" offset={0.23} />
          <stop stopColor="#2593fd" offset={0.28} />
          <stop stopColor="#326cfc" offset={0.33} />
          <stop stopColor="#1dadfd" offset={0.62} />
          <stop stopColor="#0eddff" offset={0.86} />
          <stop stopColor="#08efff" offset={0.97} />
        </linearGradient>

      </defs>

      <ellipse className='Talent-base' id="path30085" cx="13" cy="36" rx="16" ry="9" fill="#f0f" />


      {(visible && isExternal) &&
      <g className='internal' transform='translate(1.5 4) scale(0.45)'>
        <path id="path50" className="cls-1" d="M0,67C0,57.25,10.7,22.1,26.36,22.1S52.72,57.25,52.72,67,42,83.58,26.36,83.58,0,76.7,0,67Z"/>
        <path id="path52" className="cls-2" d="M23.88,47.56,21.14,64.87A2.82,2.82,0,0,0,22,67.35l3.45,3.36a1.35,1.35,0,0,0,1.89,0l3.38-3.35a2.84,2.84,0,0,0,.81-2.46L28.61,46.81Z" />
        <path id="path54" className="cls-3" d="m26 41.53s-3.24 2.15-4 4.47 4.36 5.42 4.36 5.42 4.36-2.42 4.35-5.42c0-0.81-2.35-3-4.71-4.47z" />
        <path id="path56" className="cls-4" d="M25.87,41.41S22.72,45,20.19,48.63C10,43.79,9.72,36,9.72,36s1-4.42,2.84-4.63c4.22,5.63,13.66,10,13.66,10Z" />
        <path id="path58" className="cls-5" d="M26.19,41.53A59.32,59.32,0,0,0,32,48.75c10.42-4.84,10.73-12.63,10.73-12.63s-1.06-4.43-2.95-4.64c-4.3,5.64-13.93,10-13.93,10Z" />
        <rect id="rect60" className="cls-6" x={8.72} width={35} height={35} rx={12.46}/>
      </g>}

      {(visible && !isExternal) &&
      <g className='external' transform='translate(1.5 4) scale(0.45)'>
        <path className="cls-1" d="M0,65.72C0,56,10.7,20.85,26.36,20.85S52.72,56,52.72,65.72,42,82.33,26.36,82.33,0,75.44,0,65.72Z" />
        <path className="cls-2" d="m41.72 32.75c-3 9-15.5 21-15.5 21s-12.5-11-15.5-20c-8 11-10.72 27.08-10.72 31.97 0 9.72 10.7 16.61 26.36 16.61s26.36-6.89 26.36-16.61c0-4.93-3-21.97-11-32.97z" />
        <circle className="cls-3" cx={25.97} cy={19.04} r={19.04} />
      </g>}
    </g>
  );
};

export default Talent;
