import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import Actions from '../../../js/Actions/Actions';
import Enablers from '../../../js/Enablers/Enablers';
import Texts from '../../../js/Texts';
import Usecases from '../../../js/Usecases/Usecases';
import Brick from '../../ScreenDashboard/SvgDashboard/Brick/Brick';

import './MomentRightColumn.css';

// NOTE je double le brick en cas de highlighted pour la mettre derrière la prmeière et y mettre des contours verts. On ne verra que l'extérieur


const EnablerLine = ({enabler, highlighted}) => {
  return (
  <div className={"Enabler-line" + (highlighted ? " highlighted" : "")}>

    {highlighted &&
    <svg className='green'><Brick enabler={enabler} transform="scale(2) translate(-155 -52)" /></svg>}
    <svg><Brick enabler={enabler} transform="scale(2) translate(-155 -52)" /></svg>
    <div className="Enabler-line-title">{enabler.title}</div>
  </div>)
}

const Dropdown = ({opened = true, oldEnablers, newEnablers, categ, onClick}) => {

  if(oldEnablers.length === 0 && newEnablers.length === 0) return null

  return (
    <div className={"Dropdown " + categ}>
    <div className="Dropdown-header">
      <div className='Dropdown-icon' />
      <div className='Dropdown-title'>{Texts.get("categ_" + categ)}</div>
    </div>

    <div className="Dropdown-content">
      {newEnablers.map(e => <EnablerLine enabler={e} highlighted={true} key={e.id} />)}
      {oldEnablers.map(e => <EnablerLine enabler={e}  key={e.id} />)}
    </div>
  </div>
  )
}

const MomentRightColumn = () => {

  const newUsecases = []
  const upscaledUsecases = []
  let activatedUsecases = Usecases.activated.filter(uc => !uc.failed)

  let techEnablers = Enablers.getBoughtEnablersByCateg("tech")
  let strategyEnablers = Enablers.getBoughtEnablersByCateg("strategy")
  let peopleEnablers = Enablers.getBoughtEnablersByCateg("people")

  // assez moche...
  let newTechEnablers = []
  let newStrategyEnablers = []
  let newPeopleEnablers = []

  Actions.getCurrentSelected().forEach(a => {
    a.enablers.forEach(e => {
      if(e.category === "tech") newTechEnablers.push(e)
      if(e.category === "strategy") newStrategyEnablers.push(e)
      if(e.category === "people") newPeopleEnablers.push(e)

    })

    // on regarde si les usecases de l'action sont déja ajoutés ou pas
    a.usecases.forEach(uc => {
      if(activatedUsecases.find(_uc => _uc.id === uc.id)) {
        activatedUsecases = activatedUsecases.filter(_uc => uc.id !== _uc.id)
        upscaledUsecases.push(uc)
      } else {
        newUsecases.push(uc)
      }
    })


  })
  return (
    <div className="MomentRightColumn">
      <section className='enablers'>
        <div className="list vertical">
          <Dropdown
          oldEnablers={strategyEnablers}
          newEnablers={newStrategyEnablers} categ="strategy" />

          <Dropdown
          oldEnablers={techEnablers}
          newEnablers={newTechEnablers} categ="tech" />

          <Dropdown
          oldEnablers={peopleEnablers}
          newEnablers={newPeopleEnablers}
          categ="people" />

        </div>
      </section>






    </div>
  );
};

export default observer(MomentRightColumn)
