import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { STATES } from '../../../../js/Actions/Action';
import Actions from '../../../../js/Actions/Actions';

import AppState from '../../../../js/AppState';
import Texts from '../../../../js/Texts';
import useAdaptativefontSize from '../../../../js/useAdaptativeFontSize';
import Brick from '../../../ScreenDashboard/SvgDashboard/Brick/Brick';
import Cylinder from '../../../ScreenDashboard/SvgDashboard/Cylinder/Cylinder';

import './ActionCard.css';

const ActionCard = ({action}) => {

  const titleRef = useRef(null)


  const [fontSize, finished] = useAdaptativefontSize(titleRef, 15, 35, 3)

  const [flipped, setFlipped] = useState(false);


  let subElement = action.sub_elements
  if(action.sub_elements.length > 0) subElement = subElement[0] // normalement 1 seul sub_element


  useEffect(() => {
    if(action.state === STATES.LOCKED && action.selected_current ) {
      Actions.unselectAction(action)
    }

  }, [action.state]);

  function handleClick() {
    setFlipped(f => !f)
  }

  function handleSelect() {
    Actions.selectAction(action)
  }



  function handleCloseClick(e) {
    e.stopPropagation()
    Actions.unselectAction(action)
  }


  let className = "ActionCard "
  if(action.type) className += action.type
  if(flipped) className += " flipped"
  if(action.state === STATES.SELECTED) className += " selected"
  if(action.state === STATES.SELECTED_PREVIOUSLY) className += " prev-selected"
  if(action.state === STATES.LOCKED) className += " locked"
  if(action.state === STATES.TOO_EXPENSIVE) className += " too-expensive"

  // console.log("action.params", action.params)


  if(action.type === "usecase") {
    if(subElement && subElement.inactive) className += " locked"
  }

  if(action.type === "enabler") {
    if(subElement) className += " " + subElement.category
  }


  return (
    <div className={className}
    style={{opacity: finished ? 1 : 0}}
    onClick={handleClick} title={AppState.debugMode ? action.id : null}>

      {action.state === STATES.SELECTED && <div className="ActionCard-close" onClick={handleCloseClick}>&times;</div>}

      <div className="front">
        <div className="ActionCard-body">
          <div className={"ActionCard-icon" + (action.type === "usecase" ? " usecase" : "")}>
            {action.type !== "usecase" &&
            <svg ><Brick enabler={subElement} clickable={false} transform="scale(2) translate(-112 -58)" /></svg>
            }

            {action.type === "usecase" &&
            <svg ><Cylinder phase={1} iconUrl={subElement.iconUrl} transform="scale(2) translate(-105 -48)" /></svg>}
          </div>

          <div
           ref={titleRef}
           className="ActionCard-title"
           style={{fontSize: fontSize + "px"}}
           >{action.title}</div>



          {action.state !== STATES.SELECTED_PREVIOUSLY && action.show_cost &&
          <div className="ActionCard-cost">
            <div>Cost</div>
            <div className="ActionCard-cost-value">{action.cost}</div>
          </div>}

        </div>

      </div>

      <div className="back">
        <div className="ActionCard-body">
          <div className="ActionCard-description">
            {subElement && subElement.description && <p>{subElement.description}</p>}
            <p>{action.description}</p>
          </div>

          {action.state === STATES.SELECTABLE && !(subElement && subElement.inactive) &&
          <div className="ActionCard-bottom">
            <div className="ActionCard-pill" onClick={handleSelect}>{Texts.get("actioncard-pill-select")}</div>
          </div>}

          {action.state === STATES.SELECTED &&
          <div className="ActionCard-bottom">
            <div className="ActionCard-pill">{Texts.get("actioncard-pill-selected")}</div>
          </div>}

          {action.state === STATES.SELECTED_PREVIOUSLY &&
          <div className="ActionCard-bottom">
            <div className="ActionCard-pill">{Texts.get("actioncard-pill-done")}</div>
          </div>}

          {action.state === STATES.LOCKED &&
          <div className="ActionCard-bottom">
            <div className="ActionCard-pill">{Texts.get("actioncard-pill-locked")}</div>
          </div>}

          {action.state === STATES.TOO_EXPENSIVE &&
          <div className="ActionCard-bottom">
            <div className="ActionCard-pill">{Texts.get("actioncard-pill-too-expensive")}</div>
          </div>}

        </div>
      </div>

      {action.selected_current && <div className="ActionCard-contour" />}

    </div>
  );
};



export default observer(ActionCard)
