import * as React from "react";



export default () => {
  return (
    <g id="tower" transform="matrix(1.4799 0 0 1.4799 -287.46 -560)">
    <defs id="defs171022">
      <linearGradient
        id="linearGradient4386"
        x2={1}
        gradientTransform="matrix(-155.64 0 0 155.64 1356.4 430.68)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4374" stopColor="#1a0070" offset={0} />
        <stop id="stop4376" stopColor="#1a0070" offset={1.1e-7} />
        <stop id="stop4378" stopColor="#002f96" offset={0.42269} />
        <stop id="stop4380" stopColor="#00a3bd" offset={0.63567} />
        <stop id="stop4382" stopColor="#00a3bd" offset={0.82588} />
        <stop id="stop4384" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4412"
        x2={1}
        gradientTransform="matrix(2.4826 -46.548 -46.548 -2.4826 1276.6 448.03)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4402" stopColor="#1a0070" offset={0} />
        <stop id="stop4404" stopColor="#1a0070" offset={1.1e-7} />
        <stop id="stop4406" stopColor="#002f96" offset={0.44227} />
        <stop id="stop4408" stopColor="#00a3bd" offset={0.82588} />
        <stop id="stop4410" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4440"
        x2={1}
        gradientTransform="matrix(-155.64 0 0 155.64 1356.4 453.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4428" stopColor="#1a0070" offset={0} />
        <stop id="stop4430" stopColor="#1a0070" offset={1.1e-7} />
        <stop id="stop4432" stopColor="#002f96" offset={0.4187} />
        <stop id="stop4434" stopColor="#00a3bd" offset={0.63125} />
        <stop id="stop4436" stopColor="#00a3bd" offset={0.82588} />
        <stop id="stop4438" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4466"
        x2={1}
        gradientTransform="matrix(1.8619 -68.891 -68.891 -1.8619 1276.2 488.37)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4456" stopColor="#1a0070" offset={0} />
        <stop id="stop4458" stopColor="#1a0070" offset={1.1e-7} />
        <stop id="stop4460" stopColor="#002f96" offset={0.44227} />
        <stop id="stop4462" stopColor="#00a3bd" offset={0.82588} />
        <stop id="stop4464" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4494"
        x2={1}
        gradientTransform="matrix(-155.64 0 0 155.64 1356.4 476.65)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4482" stopColor="#1a0070" offset={0} />
        <stop id="stop4484" stopColor="#1a0070" offset={1.1e-7} />
        <stop id="stop4486" stopColor="#002f96" offset={0.43067} />
        <stop id="stop4488" stopColor="#00a3bd" offset={0.63623} />
        <stop id="stop4490" stopColor="#00a3bd" offset={0.82588} />
        <stop id="stop4492" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4522"
        x2={1}
        gradientTransform="matrix(1.0361 -80.142 -80.142 -1.0361 1276.3 528.28)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4510" stopColor="#002a94" offset={0} />
        <stop id="stop4512" stopColor="#002a94" offset={1.1e-7} />
        <stop id="stop4514" stopColor="#002f96" offset={0.371} />
        <stop id="stop4516" stopColor="#002f96" offset={0.44227} />
        <stop id="stop4518" stopColor="#00a3bd" offset={0.82588} />
        <stop id="stop4520" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4546"
        x2={1}
        gradientTransform="matrix(8.8253 -52.952 -52.952 -8.8253 1269.4 537.03)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4538" stopColor="#326cfc" offset={0} />
        <stop id="stop4540" stopColor="#326cfc" offset={1.1e-7} />
        <stop id="stop4542" stopColor="#00dcff" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4580"
        x2={1}
        gradientTransform="matrix(135.3 0 0 -135.3 1209 534.35)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4562" stopColor="#08efff" offset={0} />
        <stop id="stop4564" stopColor="#08efff" offset={0.024187} />
        <stop id="stop4566" stopColor="#326cfc" offset={0.044693} />
        <stop id="stop4568" stopColor="#08efff" offset={0.4524} />
        <stop id="stop4570" stopColor="#08efff" offset={0.51769} />
        <stop id="stop4572" stopColor="#08efff" offset={0.59524} />
        <stop id="stop4574" stopColor="#326cfc" offset={0.93855} />
        <stop id="stop4576" stopColor="#08efff" offset={0.99152} />
        <stop id="stop4578" stopColor="#08efff" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4606"
        x2={1}
        gradientTransform="matrix(-146.88 -1.655 -1.655 146.88 1338.5 586.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4596" stopColor="#1a0070" offset={0} />
        <stop id="stop4598" stopColor="#1a0070" offset={1.1e-7} />
        <stop id="stop4600" stopColor="#002f96" offset={0.32578} />
        <stop id="stop4602" stopColor="#00a3bd" offset={0.82588} />
        <stop id="stop4604" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4630"
        x2={1}
        gradientTransform="matrix(12.413 -74.477 -74.477 -12.413 1270.1 639.53)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4622" stopColor="#002a94" offset={0} />
        <stop id="stop4624" stopColor="#002a94" offset={1.1e-7} />
        <stop id="stop4626" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4654"
        x2={1}
        gradientTransform="matrix(8.1019 -48.611 -48.611 -8.1019 1270 641.42)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4646" stopColor="#326cfc" offset={0} />
        <stop id="stop4648" stopColor="#326cfc" offset={1.1e-7} />
        <stop id="stop4650" stopColor="#00dcff" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4678"
        x2={1}
        gradientTransform="matrix(1.8619 44.065 44.065 -1.8619 1275.7 576.72)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4670" stopColor="#002a94" offset={0} />
        <stop id="stop4672" stopColor="#002a94" offset={1.1e-7} />
        <stop id="stop4674" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient4702"
        x2={1}
        gradientTransform="matrix(0 -44.35 -44.35 0 1276.7 619.26)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop4694" stopColor="#002a94" offset={0} />
        <stop id="stop4696" stopColor="#002a94" offset={0.23324} />
        <stop id="stop4698" stopColor="#00a3bd" offset={0.6344} />
        <stop id="stop4700" stopColor="#00a3bd" offset={1} />
      </linearGradient>
      <clipPath id="clipPath4720">
        <path id="path4718" d="m0 1160h1920v-1160h-1920z" />
      </clipPath>
      <clipPath id="clipPath4728">
        <path id="path4726" d="m1188.4 471.8h30.6v-32.647h-30.6z" />
      </clipPath>
      <mask
        id="mask4734"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g4744">
          <g id="g4742" clipPath="url(#clipPath4732)">
            <g id="g4740">
              <g id="g4738" transform="matrix(30.607 0 0 32.647 1188.4 439.16)">
                <image
                  id="image4736"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAABHNCSVQICAgIfAhkiAAAASJJREFUKJGdkkGOwyAMRZ+BJKhK1U3vf8cqKoHEwKySCU0qjQbJCxDv2/62AJV/Hnf1KCLNvdZr/QYWkSY28BiXsIhgjMEYg7UWay0iQs55j1orpZTvcN/39H2P9x4RQVVJKZFSQlWbCtyxXOccwzAwjiP3+x1rLSklpmnaWyiltDCAMQbnHLfbjcfjwfP5pOs6QggYYyiloKp7+bXW1rBNwHvPOI547+m6jhgjIQTmeWZZlt//n/ZvqpsxxpjG/Uu3a627Oe/3m9frxbqurOtKjBFVbZze4S2TqjLP897jMAzknJmmiRDCSaDJnHMmpQSAquKco9ZKjJFlWU6jEg67vfW3LYm1dhfNOVNKaUbVwMeZ/2VFT/BR5HMKpz/f4L+cHwy05UogyscBAAAAAElFTkSuQmCC"
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath4732">
        <path id="path4730" d="m1188.4 471.8h30.6v-32.647h-30.6z" />
      </clipPath>
      <mask
        id="mask4746"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image4748"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAAAAADsPXrYAAAAAXNCSVQI5gpbmQAAAIdJREFUCJldj0FuAkEQA8vdvbBKiHLh/48M0rKgmXYOSAiog+W62fCOnulnkyRs++GKyNSc0w0JkYf1e11kGwqpjqefvF1wm4Kor9/zskWPaRcQtZ7WZd+udwgAuwkJIJEiM3r/2/bRFO5xjT7OyzYaCjxvjPJ+HzYCQpnpObsf/jpYfBz64B9yYEUbeCv43QAAAABJRU5ErkJggg=="
        />
      </mask>
      <clipPath id="clipPath4774">
        <path id="path4772" d="m1188.4 448.13h30.6v-32.647h-30.6z" />
      </clipPath>
      <mask
        id="mask4780"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g4790">
          <g id="g4788" clipPath="url(#clipPath4778)">
            <g id="g4786">
              <g id="g4784" transform="matrix(30.607 0 0 32.647 1188.4 415.48)">
                <image
                  id="image4782"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAABHNCSVQICAgIfAhkiAAAASJJREFUKJGdkkGOwyAMRZ+BJKhK1U3vf8cqKoHEwKySCU0qjQbJCxDv2/62AJV/Hnf1KCLNvdZr/QYWkSY28BiXsIhgjMEYg7UWay0iQs55j1orpZTvcN/39H2P9x4RQVVJKZFSQlWbCtyxXOccwzAwjiP3+x1rLSklpmnaWyiltDCAMQbnHLfbjcfjwfP5pOs6QggYYyiloKp7+bXW1rBNwHvPOI547+m6jhgjIQTmeWZZlt//n/ZvqpsxxpjG/Uu3a627Oe/3m9frxbqurOtKjBFVbZze4S2TqjLP897jMAzknJmmiRDCSaDJnHMmpQSAquKco9ZKjJFlWU6jEg67vfW3LYm1dhfNOVNKaUbVwMeZ/2VFT/BR5HMKpz/f4L+cHwy05UogyscBAAAAAElFTkSuQmCC"
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath4778">
        <path id="path4776" d="m1188.4 448.13h30.6v-32.647h-30.6z" />
      </clipPath>
      <mask
        id="mask4792"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image4794"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAAAAADsPXrYAAAAAXNCSVQI5gpbmQAAAIdJREFUCJldj0FuAkEQA8vdvbBKiHLh/48M0rKgmXYOSAiog+W62fCOnulnkyRs++GKyNSc0w0JkYf1e11kGwqpjqefvF1wm4Kor9/zskWPaRcQtZ7WZd+udwgAuwkJIJEiM3r/2/bRFO5xjT7OyzYaCjxvjPJ+HzYCQpnpObsf/jpYfBz64B9yYEUbeCv43QAAAABJRU5ErkJggg=="
        />
      </mask>
      <mask
        id="mask4818"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image4820"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAdCAAAAACRJCOqAAAAAXNCSVQI5gpbmQAAAN1JREFUKJG9kst2RSEIQxPQ2///XiXpQD3HdtxVRiw3jwgAf2z87fl5aA94g3zHEiQIwrC9GQ8JkjQsW4stREZGBGCptFkekr23nhHg6bVkMLL3loGaMWyb3oiMbJ/ewzVoWfTKIsDI1r96ugKq4kEgyWitfxLTlRm8ezEYmc3ODJIAjbgmQJBrKuSTZVuqCZTkM6qGNRzVHBRqVMmGvb8MkgSsOcYYs2S/BVUDS/lD0AAY1gQ0SWlWyZd4C9MVhF2S/RY0IHv38653dnuU3Ls8a+d9HD+yLu+9m3+0bxJ1mRZxk8d6AAAAAElFTkSuQmCC"
        />
      </mask>
      <clipPath id="clipPath4830">
        <path id="path4828" d="m0 1160h1920v-1160h-1920z" />
      </clipPath>
      <clipPath id="clipPath4842">
        <path id="path4840" d="m1337.9 490.16h20.26v-27.504h-20.26z" />
      </clipPath>
      <mask
        id="mask4848"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g4858">
          <g id="g4856" clipPath="url(#clipPath4846)">
            <g id="g4854">
              <g id="g4852" transform="matrix(20.267 0 0 27.505 1337.9 462.66)">
                <image
                  id="image4850"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAYAAACgADyUAAAABHNCSVQICAgIfAhkiAAAASRJREFUOI2dk8mOwyAQRF+zCcU5JP//kbkYC2GWuQxMsB0pGiQOoH5dQBUCNP4xzNWmiEzr1s69J1BEptmh93kCe7HWGqUUWmsASinUWimlTOoTqLXGWotzDuccACklUkqjyQR2NWMM3ntutxvLsgAQQmDbNlpr1FoREVprf4paa5xzLMvC4/Hg+XwC8Hq9EBFKKePYHxXv9/sA930nxsi2baNuUhQRlFJYa/Hej6N677HWopSabDrZ0V/UGDOucIROYH+AUgo558mOYwjMEep3CiEAEGNk3/cTbDrUWiPnTIyRdV2x1gKwrisxRnLOU3qGYimFlBIhBFprw/TuY0pppOejYq11gD05R0Xh91t9k9VL8B3+5ndM4HuDo02nmivwm/EDdbj6UsWwO3AAAAAASUVORK5CYII="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath4846">
        <path id="path4844" d="m1337.9 490.16h20.26v-27.504h-20.26z" />
      </clipPath>
      <mask
        id="mask4860"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image4862"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAAAAACFa2NIAAAAAXNCSVQI5gpbmQAAAJRJREFUGJVdj9FqwzAUQ4987ZCSMdb//8i9NMU48dUeuo61ehASAgnBC/THfgpJwrYhQIpoIRmooGjLwhhME6i0y/Z5kTOhQizb15VvzZlUpLp+XDn6XXIFlbZurK0IKqASlSh6NOOcJzN/h5xH3+lHGir22W+NWz9tKsyxe7Dfx3ymORjjtBFIUYKZ82H/P3r7+4Yf5rtNG2xng8AAAAAASUVORK5CYII="
        />
      </mask>
      <clipPath id="clipPath4888">
        <path id="path4886" d="m1343.7 494.51h21.71v-27.505h-21.71z" />
      </clipPath>
      <mask
        id="mask4894"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g4904">
          <g id="g4902" clipPath="url(#clipPath4892)">
            <g id="g4900">
              <g id="g4898" transform="matrix(21.714 0 0 27.505 1343.7 467)">
                <image
                  id="image4896"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAYAAABPwleqAAAABHNCSVQICAgIfAhkiAAAAVxJREFUOI2dk82K6zAMRo8dx7huSTal0Pd/uEJ3aYKT+vdubj1J0xmGEXgj/PlY+iQBFP4Y6lNSCLHLlbJnbMRCiHqklDWfc6aUUs9O/BJIKVFKoZRCSknOmRgjMUZyzvWhnbhpGrTWGGMwxtA0DSkllmVhWRa89xu6Wn9XKcXhcKDrOvq+p21bQggMw4AQgpwzKSWEEJRSvsgv6ul04nw+c71eMcawLAu3242cMyGE+v1vyX3fc7lcsNbinMM5xziOjONY727I65qttXRdx/F4RCmFtRatNU3TbGz88mPVcaUUbduitaZt29r5d/834lJKtSaEgPd+U+f7oKi1MKWE9x7nHI/Hgxhjrdl7T0ppPyQv72KMzPPMMAzc7/fa7WEYmOeZGOPeZ6BSp2lCSkkIYePzNE2V/iO5lMLz+dxN2DtZ8H8l/zLbVbwelt9u1Ua8fuQ9Pu3zR/Fv4x82TCbu2wdhKQAAAABJRU5ErkJggg=="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath4892">
        <path id="path4890" d="m1343.7 494.51h21.71v-27.505h-21.71z" />
      </clipPath>
      <mask
        id="mask4906"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image4908"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAAAAABqqQh2AAAAAXNCSVQI5gpbmQAAAKtJREFUGJVlj71OA0EQg7+Znd3AEiUNipT3fziaNEei5di/obgglODCsmUXNjxC/th/lYgoTHeHAKIhphS2ZgC1Xd7npLg7hoi9Ho6xLTKHuEFI+/fzy/oxW5/3/HjKpVyvIm4gIeXDm+UUBBQQtZiiqbB5n73V1qcDBj5q+eyl1LHt0WCm63JZShsYjHrTFttyqwMM9/7l32Gsa3dHQFTNdPY+p//79/z/GT+bg1MdhFvkjwAAAABJRU5ErkJggg=="
        />
      </mask>
      <clipPath id="clipPath4934">
        <path id="path4932" d="m1321.9 495.9h40v-48h-40z" />
      </clipPath>
      <mask
        id="mask4940"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g4950">
          <g id="g4948" clipPath="url(#clipPath4938)">
            <g id="g4946">
              <g id="g4944" transform="matrix(40 0 0 48 1321.9 447.9)">
                <image
                  id="image4942"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAYAAABjezibAAAABHNCSVQICAgIfAhkiAAAAftJREFUWIXt2dFugzAMBVAn2v9/MuxhMjK39zpOgrQ+YAlBR1rOTGyyrpnZaV8c/b8Bo3iBu/ECd+Nn582ttdK481xvFM0m20wVxWIFWgYibAaKsBloCRgx6riCU8dbQAbCfQXIcBVkWiQKV4UywHme1lq77VODJRlUKPaaQWKm8DXbs5AZZNmJONwUkBVIJXNDICLj1nufAvp2HMd1zj93BKbALGuOy5AK13u/kBGaxfAWR2TEzQAjTmVbZfEDyGCIVFAEHsdxXdRxPu6ROYhQvNUjIMJ8mymUIVBVLQP6RbPqZcilImHVqarYX/uYOO8Qxq6FVR2j42D2AWqPWHWcNfpRlOcgw2WPP/ZLV1ExHl1RP40z2wCqhUA8XlleYZRucfbQVxXLoCvIG9Arjv1MwbxiY/sw++t/3qjVphIhgWygwsVnau/99r4RCMdlMbzFMatqERB7XcxgPK/WhHg8DURYzJwZ72s4nt3uanwAMWOOUEsm7IFsKmA24/l43RIwg5rZB5I16IhQt3oES4EKV0HiHFPz8JE5mK004uJTLUJ9r3CVuVj+q8736hmsfsGsyVeQ5SpWmWQ9k51ffaJsfbOAxxiqObPzy8ARqApcwZWBKzgFmV0wTH8/aLa2tltZyZgtAq83L2Rw+hr2/iNnL17gbrzA3fh64C/qzdpNMR/K0QAAAABJRU5ErkJggg=="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath4938">
        <path id="path4936" d="m1321.9 495.9h40v-48h-40z" />
      </clipPath>
      <mask
        id="mask4952"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image4954"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAAAAABGEGdHAAAAAXNCSVQI5gpbmQAAAUBJREFUOI3tlEFyxDAIBGcU/f/JdA4grcR6U7kn2OWyym1gEEj6i+aPa34A2198+OZ7yY36fPOxZt0dtGT5BKnHDSb3QkHr6qAt27IXx0XOzUlOSxBJ+JXjPDza4wAhhLzguTO0PRaZ3IhD9g4te3gcYIxI5+lybkz2SFSCQGHrPceSMhaosO1Dzby4BL0023QxtjJLe1gmBqXfyEgaq+i5KfZYd23AQ47aW2PdDaLy2O2J6yD1gN7i82CoVlgoHcSScGKEjSJI22HmiphcSCN9k9Ghh8bVCtgoCLIhWz9KAkJVeyAi4MjyKwtyThYKIILgUF9gTYBLfUAGfxXpS9tlkqgyjC3oyrGaJGoKt/CHcT2H8BrDN9Ve1TXl8/FI8ZpZrS04q7jFqPfL2vF3j2rHWTvMWtNdKz5/6h7/7df2DQZs7kMRqOklAAAAAElFTkSuQmCC"
        />
      </mask>
      <clipPath id="clipPath4980">
        <path id="path4978" d="m1337.9 467.27h20.26v-27.505h-20.26z" />
      </clipPath>
      <mask
        id="mask4986"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g4996">
          <g id="g4994" clipPath="url(#clipPath4984)">
            <g id="g4992">
              <g id="g4990" transform="matrix(20.267 0 0 27.505 1337.9 439.77)">
                <image
                  id="image4988"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAYAAACgADyUAAAABHNCSVQICAgIfAhkiAAAASRJREFUOI2dk8mOwyAQRF+zCcU5JP//kbkYC2GWuQxMsB0pGiQOoH5dQBUCNP4xzNWmiEzr1s69J1BEptmh93kCe7HWGqUUWmsASinUWimlTOoTqLXGWotzDuccACklUkqjyQR2NWMM3ntutxvLsgAQQmDbNlpr1FoREVprf4paa5xzLMvC4/Hg+XwC8Hq9EBFKKePYHxXv9/sA930nxsi2baNuUhQRlFJYa/Hej6N677HWopSabDrZ0V/UGDOucIROYH+AUgo558mOYwjMEep3CiEAEGNk3/cTbDrUWiPnTIyRdV2x1gKwrisxRnLOU3qGYimFlBIhBFprw/TuY0pppOejYq11gD05R0Xh91t9k9VL8B3+5ndM4HuDo02nmivwm/EDdbj6UsWwO3AAAAAASUVORK5CYII="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath4984">
        <path id="path4982" d="m1337.9 467.27h20.26v-27.505h-20.26z" />
      </clipPath>
      <mask
        id="mask4998"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image5000"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAAAAACFa2NIAAAAAXNCSVQI5gpbmQAAAJRJREFUGJVdj9FqwzAUQ4987ZCSMdb//8i9NMU48dUeuo61ehASAgnBC/THfgpJwrYhQIpoIRmooGjLwhhME6i0y/Z5kTOhQizb15VvzZlUpLp+XDn6XXIFlbZurK0IKqASlSh6NOOcJzN/h5xH3+lHGir22W+NWz9tKsyxe7Dfx3ymORjjtBFIUYKZ82H/P3r7+4Yf5rtNG2xng8AAAAAASUVORK5CYII="
        />
      </mask>
      <clipPath id="clipPath5026">
        <path id="path5024" d="m1343.7 471.62h21.71v-27.505h-21.71z" />
      </clipPath>
      <mask
        id="mask5032"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5042">
          <g id="g5040" clipPath="url(#clipPath5030)">
            <g id="g5038">
              <g id="g5036" transform="matrix(21.714 0 0 27.505 1343.7 444.11)">
                <image
                  id="image5034"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAYAAABPwleqAAAABHNCSVQICAgIfAhkiAAAAVxJREFUOI2dk82K6zAMRo8dx7huSTal0Pd/uEJ3aYKT+vdubj1J0xmGEXgj/PlY+iQBFP4Y6lNSCLHLlbJnbMRCiHqklDWfc6aUUs9O/BJIKVFKoZRCSknOmRgjMUZyzvWhnbhpGrTWGGMwxtA0DSkllmVhWRa89xu6Wn9XKcXhcKDrOvq+p21bQggMw4AQgpwzKSWEEJRSvsgv6ul04nw+c71eMcawLAu3242cMyGE+v1vyX3fc7lcsNbinMM5xziOjONY727I65qttXRdx/F4RCmFtRatNU3TbGz88mPVcaUUbduitaZt29r5d/834lJKtSaEgPd+U+f7oKi1MKWE9x7nHI/Hgxhjrdl7T0ppPyQv72KMzPPMMAzc7/fa7WEYmOeZGOPeZ6BSp2lCSkkIYePzNE2V/iO5lMLz+dxN2DtZ8H8l/zLbVbwelt9u1Ua8fuQ9Pu3zR/Fv4x82TCbu2wdhKQAAAABJRU5ErkJggg=="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath5030">
        <path id="path5028" d="m1343.7 471.62h21.71v-27.505h-21.71z" />
      </clipPath>
      <mask
        id="mask5044"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image5046"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAAAAABqqQh2AAAAAXNCSVQI5gpbmQAAAKtJREFUGJVlj71OA0EQg7+Znd3AEiUNipT3fziaNEei5di/obgglODCsmUXNjxC/th/lYgoTHeHAKIhphS2ZgC1Xd7npLg7hoi9Ho6xLTKHuEFI+/fzy/oxW5/3/HjKpVyvIm4gIeXDm+UUBBQQtZiiqbB5n73V1qcDBj5q+eyl1LHt0WCm63JZShsYjHrTFttyqwMM9/7l32Gsa3dHQFTNdPY+p//79/z/GT+bg1MdhFvkjwAAAABJRU5ErkJggg=="
        />
      </mask>
      <clipPath id="clipPath5072">
        <path id="path5070" d="m1321.9 472.9h40v-48h-40z" />
      </clipPath>
      <mask
        id="mask5078"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5088">
          <g id="g5086" clipPath="url(#clipPath5076)">
            <g id="g5084">
              <g id="g5082" transform="matrix(40 0 0 48 1321.9 424.9)">
                <image
                  id="image5080"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAYAAABjezibAAAABHNCSVQICAgIfAhkiAAAAftJREFUWIXt2dFugzAMBVAn2v9/MuxhMjK39zpOgrQ+YAlBR1rOTGyyrpnZaV8c/b8Bo3iBu/ECd+Nn582ttdK481xvFM0m20wVxWIFWgYibAaKsBloCRgx6riCU8dbQAbCfQXIcBVkWiQKV4UywHme1lq77VODJRlUKPaaQWKm8DXbs5AZZNmJONwUkBVIJXNDICLj1nufAvp2HMd1zj93BKbALGuOy5AK13u/kBGaxfAWR2TEzQAjTmVbZfEDyGCIVFAEHsdxXdRxPu6ROYhQvNUjIMJ8mymUIVBVLQP6RbPqZcilImHVqarYX/uYOO8Qxq6FVR2j42D2AWqPWHWcNfpRlOcgw2WPP/ZLV1ExHl1RP40z2wCqhUA8XlleYZRucfbQVxXLoCvIG9Arjv1MwbxiY/sw++t/3qjVphIhgWygwsVnau/99r4RCMdlMbzFMatqERB7XcxgPK/WhHg8DURYzJwZ72s4nt3uanwAMWOOUEsm7IFsKmA24/l43RIwg5rZB5I16IhQt3oES4EKV0HiHFPz8JE5mK004uJTLUJ9r3CVuVj+q8736hmsfsGsyVeQ5SpWmWQ9k51ffaJsfbOAxxiqObPzy8ARqApcwZWBKzgFmV0wTH8/aLa2tltZyZgtAq83L2Rw+hr2/iNnL17gbrzA3fh64C/qzdpNMR/K0QAAAABJRU5ErkJggg=="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath5076">
        <path id="path5074" d="m1321.9 472.9h40v-48h-40z" />
      </clipPath>
      <mask
        id="mask5090"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image5092"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAAAAABGEGdHAAAAAXNCSVQI5gpbmQAAAUBJREFUOI3tlEFyxDAIBGcU/f/JdA4grcR6U7kn2OWyym1gEEj6i+aPa34A2198+OZ7yY36fPOxZt0dtGT5BKnHDSb3QkHr6qAt27IXx0XOzUlOSxBJ+JXjPDza4wAhhLzguTO0PRaZ3IhD9g4te3gcYIxI5+lybkz2SFSCQGHrPceSMhaosO1Dzby4BL0023QxtjJLe1gmBqXfyEgaq+i5KfZYd23AQ47aW2PdDaLy2O2J6yD1gN7i82CoVlgoHcSScGKEjSJI22HmiphcSCN9k9Ghh8bVCtgoCLIhWz9KAkJVeyAi4MjyKwtyThYKIILgUF9gTYBLfUAGfxXpS9tlkqgyjC3oyrGaJGoKt/CHcT2H8BrDN9Ve1TXl8/FI8ZpZrS04q7jFqPfL2vF3j2rHWTvMWtNdKz5/6h7/7df2DQZs7kMRqOklAAAAAElFTkSuQmCC"
        />
      </mask>
      <clipPath id="clipPath5118">
        <path id="path5116" d="m1337.9 444.38h20.26v-27.505h-20.26z" />
      </clipPath>
      <mask
        id="mask5124"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5134">
          <g id="g5132" clipPath="url(#clipPath5122)">
            <g id="g5130">
              <g id="g5128" transform="matrix(20.267 0 0 27.505 1337.9 416.88)">
                <image
                  id="image5126"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAYAAACgADyUAAAABHNCSVQICAgIfAhkiAAAASRJREFUOI2dk8mOwyAQRF+zCcU5JP//kbkYC2GWuQxMsB0pGiQOoH5dQBUCNP4xzNWmiEzr1s69J1BEptmh93kCe7HWGqUUWmsASinUWimlTOoTqLXGWotzDuccACklUkqjyQR2NWMM3ntutxvLsgAQQmDbNlpr1FoREVprf4paa5xzLMvC4/Hg+XwC8Hq9EBFKKePYHxXv9/sA930nxsi2baNuUhQRlFJYa/Hej6N677HWopSabDrZ0V/UGDOucIROYH+AUgo558mOYwjMEep3CiEAEGNk3/cTbDrUWiPnTIyRdV2x1gKwrisxRnLOU3qGYimFlBIhBFprw/TuY0pppOejYq11gD05R0Xh91t9k9VL8B3+5ndM4HuDo02nmivwm/EDdbj6UsWwO3AAAAAASUVORK5CYII="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath5122">
        <path id="path5120" d="m1337.9 444.38h20.26v-27.505h-20.26z" />
      </clipPath>
      <mask
        id="mask5136"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image5138"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAAAAACFa2NIAAAAAXNCSVQI5gpbmQAAAJRJREFUGJVdj9FqwzAUQ4987ZCSMdb//8i9NMU48dUeuo61ehASAgnBC/THfgpJwrYhQIpoIRmooGjLwhhME6i0y/Z5kTOhQizb15VvzZlUpLp+XDn6XXIFlbZurK0IKqASlSh6NOOcJzN/h5xH3+lHGir22W+NWz9tKsyxe7Dfx3ymORjjtBFIUYKZ82H/P3r7+4Yf5rtNG2xng8AAAAAASUVORK5CYII="
        />
      </mask>
      <clipPath id="clipPath5164">
        <path id="path5162" d="m1343.7 448.72h21.71v-27.505h-21.71z" />
      </clipPath>
      <mask
        id="mask5170"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5180">
          <g id="g5178" clipPath="url(#clipPath5168)">
            <g id="g5176">
              <g id="g5174" transform="matrix(21.714 0 0 27.505 1343.7 421.22)">
                <image
                  id="image5172"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAYAAABPwleqAAAABHNCSVQICAgIfAhkiAAAAVxJREFUOI2dk82K6zAMRo8dx7huSTal0Pd/uEJ3aYKT+vdubj1J0xmGEXgj/PlY+iQBFP4Y6lNSCLHLlbJnbMRCiHqklDWfc6aUUs9O/BJIKVFKoZRCSknOmRgjMUZyzvWhnbhpGrTWGGMwxtA0DSkllmVhWRa89xu6Wn9XKcXhcKDrOvq+p21bQggMw4AQgpwzKSWEEJRSvsgv6ul04nw+c71eMcawLAu3242cMyGE+v1vyX3fc7lcsNbinMM5xziOjONY727I65qttXRdx/F4RCmFtRatNU3TbGz88mPVcaUUbduitaZt29r5d/834lJKtSaEgPd+U+f7oKi1MKWE9x7nHI/Hgxhjrdl7T0ppPyQv72KMzPPMMAzc7/fa7WEYmOeZGOPeZ6BSp2lCSkkIYePzNE2V/iO5lMLz+dxN2DtZ8H8l/zLbVbwelt9u1Ua8fuQ9Pu3zR/Fv4x82TCbu2wdhKQAAAABJRU5ErkJggg=="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath5168">
        <path id="path5166" d="m1343.7 448.72h21.71v-27.505h-21.71z" />
      </clipPath>
      <mask
        id="mask5182"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image5184"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAAAAABqqQh2AAAAAXNCSVQI5gpbmQAAAKtJREFUGJVlj71OA0EQg7+Znd3AEiUNipT3fziaNEei5di/obgglODCsmUXNjxC/th/lYgoTHeHAKIhphS2ZgC1Xd7npLg7hoi9Ho6xLTKHuEFI+/fzy/oxW5/3/HjKpVyvIm4gIeXDm+UUBBQQtZiiqbB5n73V1qcDBj5q+eyl1LHt0WCm63JZShsYjHrTFttyqwMM9/7l32Gsa3dHQFTNdPY+p//79/z/GT+bg1MdhFvkjwAAAABJRU5ErkJggg=="
        />
      </mask>
      <clipPath id="clipPath5210">
        <path id="path5208" d="m1321.9 449.9h40v-48h-40z" />
      </clipPath>
      <mask
        id="mask5216"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5226">
          <g id="g5224" clipPath="url(#clipPath5214)">
            <g id="g5222">
              <g id="g5220" transform="matrix(40 0 0 48 1321.9 401.9)">
                <image
                  id="image5218"
                  transform="matrix(1,0,0,-1,0,1)"
                  width={1}
                  height={1}
                  imageRendering="optimizeSpeed"
                  preserveAspectRatio="none"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAYAAABjezibAAAABHNCSVQICAgIfAhkiAAAAftJREFUWIXt2dFugzAMBVAn2v9/MuxhMjK39zpOgrQ+YAlBR1rOTGyyrpnZaV8c/b8Bo3iBu/ECd+Nn582ttdK481xvFM0m20wVxWIFWgYibAaKsBloCRgx6riCU8dbQAbCfQXIcBVkWiQKV4UywHme1lq77VODJRlUKPaaQWKm8DXbs5AZZNmJONwUkBVIJXNDICLj1nufAvp2HMd1zj93BKbALGuOy5AK13u/kBGaxfAWR2TEzQAjTmVbZfEDyGCIVFAEHsdxXdRxPu6ROYhQvNUjIMJ8mymUIVBVLQP6RbPqZcilImHVqarYX/uYOO8Qxq6FVR2j42D2AWqPWHWcNfpRlOcgw2WPP/ZLV1ExHl1RP40z2wCqhUA8XlleYZRucfbQVxXLoCvIG9Arjv1MwbxiY/sw++t/3qjVphIhgWygwsVnau/99r4RCMdlMbzFMatqERB7XcxgPK/WhHg8DURYzJwZ72s4nt3uanwAMWOOUEsm7IFsKmA24/l43RIwg5rZB5I16IhQt3oES4EKV0HiHFPz8JE5mK004uJTLUJ9r3CVuVj+q8736hmsfsGsyVeQ5SpWmWQ9k51ffaJsfbOAxxiqObPzy8ARqApcwZWBKzgFmV0wTH8/aLa2tltZyZgtAq83L2Rw+hr2/iNnL17gbrzA3fh64C/qzdpNMR/K0QAAAABJRU5ErkJggg=="
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <clipPath id="clipPath5214">
        <path id="path5212" d="m1321.9 449.9h40v-48h-40z" />
      </clipPath>
      <mask
        id="mask5228"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <image
          id="image5230"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAAAAABGEGdHAAAAAXNCSVQI5gpbmQAAAUBJREFUOI3tlEFyxDAIBGcU/f/JdA4grcR6U7kn2OWyym1gEEj6i+aPa34A2198+OZ7yY36fPOxZt0dtGT5BKnHDSb3QkHr6qAt27IXx0XOzUlOSxBJ+JXjPDza4wAhhLzguTO0PRaZ3IhD9g4te3gcYIxI5+lybkz2SFSCQGHrPceSMhaosO1Dzby4BL0023QxtjJLe1gmBqXfyEgaq+i5KfZYd23AQ47aW2PdDaLy2O2J6yD1gN7i82CoVlgoHcSScGKEjSJI22HmiphcSCN9k9Ghh8bVCtgoCLIhWz9KAkJVeyAi4MjyKwtyThYKIILgUF9gTYBLfUAGfxXpS9tlkqgyjC3oyrGaJGoKt/CHcT2H8BrDN9Ve1TXl8/FI8ZpZrS04q7jFqPfL2vF3j2rHWTvMWtNdKz5/6h7/7df2DQZs7kMRqOklAAAAAElFTkSuQmCC"
        />
      </mask>
      <clipPath id="clipPath5256">
        <path
          id="path5254"
          d="m1272.2 790.26c-0.274-0.155-0.504-0.081-0.595-0.279-0.018-0.041-0.022-155.98-0.021-193.06l4.125-2.305c0.01 32.318 0.01 195.26-0.012 195.31-0.083 0.202-0.315 0.179-0.59 0.337-0.397 0.23-0.921 0.345-1.448 0.345-0.526 0-1.054-0.115-1.459-0.345"
        />
      </clipPath>
      <mask
        id="mask5272"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5282">
          <g id="g5280">
            <g id="g5278">
              <g id="g5276">
                <path
                  id="path5274"
                  d="m-32768 32767h65535v-65535h-65535z"
                  fill="url(#linearGradient5270)"
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <linearGradient
        id="linearGradient5270"
        x2={1}
        gradientTransform="matrix(0 178.95 178.95 0 1273.6 596.78)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop5266" stopColor="#fff" offset={0} />
        <stop id="stop5268" stopColor="#fff" stopOpacity={0} offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient5292"
        x2={1}
        gradientTransform="matrix(0 178.95 178.95 0 1273.6 596.78)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop5288" stopColor="#ffe552" offset={0} />
        <stop id="stop5290" stopColor="#ffe552" offset={1} stopOpacity="0" />
      </linearGradient>
      <clipPath id="clipPath5302">
        <path
          id="path5300"
          d="m1279.3 788.84c-0.274-0.156-0.504-0.081-0.595-0.28-0.017-0.04-0.022-157.08-0.02-193.45l4.124 2.418c0.01 40.377 0.01 190.92-0.012 190.98-0.083 0.203-0.315 0.178-0.59 0.338-0.398 0.229-0.922 0.344-1.448 0.344s-1.054-0.115-1.459-0.344"
        />
      </clipPath>
      <mask
        id="mask5318"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5328">
          <g id="g5326">
            <g id="g5324">
              <g id="g5322">
                <path
                  id="path5320"
                  d="m-32768 32767h65535v-65535h-65535z"
                  fill="url(#linearGradient5316)"
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <linearGradient
        id="linearGradient5316"
        x2={1}
        gradientTransform="matrix(0 178.95 178.95 0 1280.8 595.37)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop5312" stopColor="#fff" offset={0} />
        <stop id="stop5314" stopColor="#fff" stopOpacity={0} offset={1} />
      </linearGradient>
      <linearGradient id="linearGradient5338" x2={1} gradientTransform="matrix(0 178.95 178.95 0 1280.8 595.37)" gradientUnits="userSpaceOnUse">
        <stop id="stop5334" stopColor="#10a3ff" offset={0} />
        <stop id="stop5336" stopColor="#10a3ff" offset={1} stopOpacity="0" />
      </linearGradient>

      <linearGradient id="beam-gradient5388" x2={1} gradientTransform="matrix(0 178.95 178.95 0 1287.6 600.53)" gradientUnits="userSpaceOnUse">
        <stop stopColor="#e629ff" stopOpacity={0} offset={0} />
        <stop stopColor="#e629ff" stopOpacity={1} offset={0} />
        <stop stopColor="#e629ff" stopOpacity={0} offset={1} />
      </linearGradient>
      <clipPath id="clipPath5414">
        <path id="path5412" d="m1265.4 795.45h4.12v-205.59h-4.12z" />
      </clipPath>
      <clipPath id="clipPath5418">
        <path
          id="path5416"
          d="m1269.5 794.77c-0.083 0.203-0.315 0.179-0.589 0.338-0.796 0.459-2.098 0.459-2.907-1e-3 -0.275-0.155-0.504-0.08-0.595-0.28-0.021-0.044-0.023-196.26-0.021-200.83v-3.817c1.258-0.434 2.856-0.425 4.111 0.03 0 0.823 0 2.201 0.01 3.796 0.01 4.565 0.013 200.7-0.01 200.76"
        />
      </clipPath>
      <mask
        id="mask5430"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5440">
          <g id="g5438">
            <g id="g5436">
              <g id="g5434">
                <path
                  id="path5432"
                  d="m-32768 32767h65535v-65535h-65535z"
                  fill="url(#linearGradient5428)"
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <linearGradient
        id="linearGradient5428"
        x2={1}
        gradientTransform="matrix(0 178.95 178.95 0 1267.4 601.63)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop5424" stopColor="#fff" offset={0} />
        <stop id="stop5426" stopColor="#fff" stopOpacity={0} offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient5446"
        x2={1}
        gradientTransform="matrix(0 178.95 178.95 0 1267.4 601.63)"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#08efff" stopOpacity={0} offset={0} />
        <stop stopColor="#08efff" stopOpacity={0} offset={0.0299} />
        <stop stopColor="#08efff" offset={0.03} />
        <stop stopColor="#08efff" stopOpacity={0} offset={1} />
      </linearGradient>
      <clipPath id="clipPath5472">
        <path
          id="path5470"
          d="m1256.3 781.51-0.059-176.58 20.248-11.689 20.696 11.735v177.81z"
        />
      </clipPath>
      <mask
        id="mask5488"
        x={0}
        y={0}
        width={1}
        height={1}
        maskUnits="userSpaceOnUse"
      >
        <g id="g5498">
          <g id="g5496">
            <g id="g5494">
              <g id="g5492">
                <path
                  id="path5490"
                  d="m-32768 32767h65535v-65535h-65535z"
                  fill="url(#linearGradient5486)"
                />
              </g>
            </g>
          </g>
        </g>
      </mask>
      <linearGradient
        id="linearGradient5486"
        x2={1}
        gradientTransform="matrix(0 157.68 157.68 0 1276.7 602.8)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop5482" stopColor="#fff" stopOpacity={0.50001} offset={0} />
        <stop id="stop5484" stopColor="#fff" stopOpacity={0} offset={1} />
      </linearGradient>
      <linearGradient
        id="linearGradient5508"
        x2={1}
        gradientTransform="matrix(0 157.68 157.68 0 1276.7 602.8)"
        gradientUnits="userSpaceOnUse"
      >
        <stop id="stop5504" stopColor="#08efff" stopOpacity={0.5} offset={0} />
        <stop id="stop5506" stopColor="#08efff" stopOpacity={0} offset={1} />
      </linearGradient>
    </defs>
    <g id="g4362" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <path
        id="path4388"
        d="m1360.3 430.77v15.693l-82.672 32.038-84.504-32.222v-15.693l82.671-47.731z"
        fill="url(#linearGradient4386)"
      />
    </g>
    <g id="g4390" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <path
        id="path4414"
        d="m1360.3 446.46-82.672 47.731-84.504-47.915 82.671-47.731z"
        fill="url(#linearGradient4412)"
      />
    </g>
    <g id="g4416" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <path
        id="path4442"
        d="m1360.3 453.6v15.693l-82.672 32.038-84.504-32.222v-15.693l82.671-47.731z"
        fill="url(#linearGradient4440)"
      />
    </g>
    <g id="g4444" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <g id="g4446">
        <g id="g4452">
          <g id="g4454">
            <path
              id="path4468"
              d="m1360.3 469.29-82.672 47.731-84.504-47.915 82.671-47.731z"
              fill="url(#linearGradient4466)"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="g4470" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <g id="g4472">
        <g id="g4478">
          <g id="g4480">
            <path
              id="path4496"
              d="m1360.3 476.75v15.693l-82.672 32.037-84.504-32.221v-15.694l82.671-47.73z"
              fill="url(#linearGradient4494)"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="g4498" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <g id="g4500">
        <g id="g4506">
          <g id="g4508">
            <path
              id="path4524"
              d="m1360.3 492.44-82.672 46.599-84.504-46.783 82.671-47.731z"
              fill="url(#linearGradient4522)"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="g4526" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <g id="g4528">
        <g id="g4534">
          <g id="g4536">
            <path
              id="path4548"
              d="m1277.6 538.59 80.875-46.693 1.797 1.019-82.672 47.731-84.504-47.915 1.796-1.038z"
              fill="url(#linearGradient4546)"
            />
          </g>
        </g>
      </g>
    </g>
    <g className="Tower-body" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <path
        id="path4582"
        d="m1344.3 491.99 0.071 96.174-66.862 27.264-68.438-26.85 0.165-96.737 66.791-38.562z"
        fill="url(#linearGradient4580)"
      />
    </g>
    <g className="Tower-top" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <path className="Tower-top-side"
        id="path4608"
        d="m1353.5 585.69v14.406l-75.896 29.412-77.577-29.58v-14.408l75.895-43.818z"
        fill="url(#linearGradient4606)"
      />
      <path className="Tower-top-top"
        id="path4632"
        d="m1353.5 600.09-75.896 43.819-77.577-43.987 75.895-43.819z"
        fill="url(#linearGradient4630)"
      />
      <path className="Tower-top-back"
        id="path4656"
        d="m1277.6 642.85 74.245-42.865 1.651 0.935-75.896 43.819-77.577-43.988 1.65-0.952z"
        fill="url(#linearGradient4654)"
      />

      <g className="Tower-minitop">
        <path className="Tower-minitop-side"
          d="m1317.5 598.22v7.649l-40.298 15.616-41.191-15.706v-7.649l40.297-23.266z"
          fill="url(#linearGradient4678)"
        />
        <path className="Tower-minitop-top"
            d="m1317.5 605.87-40.298 23.266-41.191-23.356 40.297-23.265z"
            fill="url(#linearGradient4702)"
          />
      </g>
    </g>

    <g id="g4706" transform="matrix(.35278 0 0 -.35278 402.95 399.17)">
      <path
        id="path4708"
        d="m0 0-20.247 11.69-20.697-11.735 20.248-11.69z"
        fill="#fff"
      />
    </g>
    <g id="g4710" transform="matrix(.35278 0 0 -.35278 401.74 399.67)">
      <path
        id="path4712"
        d="m0 0 3.438 1.949-20.248 11.691-20.696-11.736 3.439-1.985 17.257 9.786z"
        fill="#00546e"
      />
    </g>
    <g id="g4714" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <g id="g4716" clipPath="url(#clipPath4720)">
        <g id="g4722">
          <g id="g4762">
            <g id="g4760" clipPath="url(#clipPath4728)">
              <g id="g4758">
                <g id="g4756" mask="url(#mask4734)">
                  <g
                    id="g4754"
                    transform="matrix(30.607 0 0 32.647 1188.4 439.16)"
                  >
                    <image
                      id="image4752"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask4746)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAABHNCSVQICAgIfAhkiAAAAF9JREFUKJG1k0EOgDAIBIH0l77Pb4qnGiRLuqLuDcIwPRR1d5dmBmructzqTQzCGs0ZyslLrmoFohn8HnKBsVaUx+Yoa8GvzN/B1Sf4xTxllhttM7Mgzmh1VcxxlDCTEzryIA/OiI1PAAAAAElFTkSuQmCC"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g4764" transform="translate(1203.4 452.69)">
          <path
            id="path4766"
            d="m0 0c0.94-0.419 1.703 4e-3 1.703 0.945v2.038c0 0.941-0.763 2.043-1.703 2.462-0.941 0.419-1.703-5e-3 -1.703-0.945v-2.039c0-0.94 0.762-2.042 1.703-2.461"
            fill="#08eeff"
          />
        </g>
        <g id="g4768">
          <g id="g4808">
            <g id="g4806" clipPath="url(#clipPath4774)">
              <g id="g4804">
                <g id="g4802" mask="url(#mask4780)">
                  <g
                    id="g4800"
                    transform="matrix(30.607 0 0 32.647 1188.4 415.48)"
                  >
                    <image
                      id="image4798"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask4792)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAABHNCSVQICAgIfAhkiAAAAF9JREFUKJG1k0EOgDAIBIH0l77Pb4qnGiRLuqLuDcIwPRR1d5dmBmructzqTQzCGs0ZyslLrmoFohn8HnKBsVaUx+Yoa8GvzN/B1Sf4xTxllhttM7Mgzmh1VcxxlDCTEzryIA/OiI1PAAAAAElFTkSuQmCC"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g4810" transform="translate(1203.4 429.01)">
          <path
            id="path4812"
            d="m0 0c0.94-0.419 1.703 4e-3 1.703 0.945v2.038c0 0.941-0.763 2.043-1.703 2.462-0.941 0.419-1.703-5e-3 -1.703-0.945v-2.038c0-0.941 0.762-2.043 1.703-2.462"
            fill="#ffe552"
          />
        </g>
      </g>
    </g>
    <g id="g4814" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <g id="g4816" transform="matrix(26 0 0 29 1191.1 463.66)">
        <image
          id="image4822"
          transform="matrix(1,0,0,-1,0,1)"
          width={1}
          height={1}
          imageRendering="optimizeSpeed"
          mask="url(#mask4818)"
          preserveAspectRatio="none"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAdCAYAAAC0T3x2AAAABHNCSVQICAgIfAhkiAAAAJVJREFUSIndlrEOwCAIRNV07Cd37Cd3p1MTq4hYPZpwq8KDU4yRiCgYKFlATEGbatd5tdeOXZUiimckAQahPGgUoIDVZzQDEeIxl4GBpd6GVcJd76LoHwYWaNsbBBYWlLnkpCMWpHwch5Tl9GxdCBj7WNBKFUXXIFBXfEezMCa+bd1XWCNO/jM86j24iqJ0oAXyPrBA3dn7KzQmSOQQAAAAAElFTkSuQmCC"
        />
      </g>
    </g>
    <g id="g4824" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <g id="g4826" clipPath="url(#clipPath4830)">
        <g id="g4832" transform="translate(1203.9 475.76)">
          <path
            id="path4834"
            d="m0 0c0.94-0.418 1.703 4e-3 1.703 0.945v2.038c0 0.941-0.763 2.043-1.703 2.462-0.941 0.419-1.703-5e-3 -1.703-0.945v-2.038c0-0.941 0.762-2.043 1.703-2.462"
            fill="#ff64f4"
          />
        </g>
        <g id="g4836">
          <g id="g4876">
            <g id="g4874" clipPath="url(#clipPath4842)">
              <g id="g4872">
                <g id="g4870" mask="url(#mask4848)">
                  <g
                    id="g4868"
                    transform="matrix(20.267 0 0 27.505 1337.9 462.66)"
                  >
                    <image
                      id="image4866"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask4860)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAYAAACgADyUAAAABHNCSVQICAgIfAhkiAAAAFRJREFUOI1j/P///38GMgALNsGlDP9Q+NEMTBhqGJFtRNeADpANgLMIaUJXg+kGIgETsbah20qZjaMaB5VGbIkYF4CppdypxNiKrIYRW0YmOT+SAgAFgx4VbUQxoQAAAABJRU5ErkJggg=="
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g4878" transform="translate(1348 471.65)">
          <path
            id="path4880"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#08eeff"
          />
        </g>
        <g id="g4882">
          <g id="g4922">
            <g id="g4920" clipPath="url(#clipPath4888)">
              <g id="g4918">
                <g id="g4916" mask="url(#mask4894)">
                  <g
                    id="g4914"
                    transform="matrix(21.714 0 0 27.505 1343.7 467)"
                  >
                    <image
                      id="image4912"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask4906)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAYAAABPwleqAAAABHNCSVQICAgIfAhkiAAAAE5JREFUOI1j/P///38GMgELdmEeLGJfMEQYUW3Gpgm3IUykaURVx4RHFUHARJqtqLZTw+ZRzUNGM2aixw++UM1mUmxHqGPEXhiQlZ9JAwBdlxcVq4ILRgAAAABJRU5ErkJggg=="
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g4924" transform="translate(1354.7 475.94)">
          <path
            id="path4926"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#f4cb00"
          />
        </g>
        <g id="g4928">
          <g id="g4968">
            <g id="g4966" clipPath="url(#clipPath4934)" opacity={0.75}>
              <g id="g4964">
                <g id="g4962" mask="url(#mask4940)">
                  <g id="g4960" transform="matrix(40 0 0 48 1321.9 447.9)">
                    <image
                      id="image4958"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask4952)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAYAAABjezibAAAABHNCSVQICAgIfAhkiAAAAOZJREFUWIXtmLEOwyAMBV+ijvnkjv3k7HTK0gK2MWBb8s2puV4QSnKUUgocc1oLUKSglhTU8lL9+nPzrntfw0sc4mOGK1VjQJQvqBH7RSDK24Mz5YTzaMHZcsK5fcFVcoL59scMIdkWXF2PiX1BoBujLuikHuClINCM8i/oqB7gqWCDFBRR2V4ndYE1vgpWSEEtwQQV7w5TqKwfrKBDAgpa7cPGugELAvsrdtYLWhDYV5FYJ3BBYH1Fxny64CpJ5lzZ57cZD7TCPyz/PgiMiQ7eiTHBB46ocovoBDcQ/JhxQApqSUEtX5KnQzCOBQPAAAAAAElFTkSuQmCC"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g4970" transform="translate(1341.6 467.56)">
          <path
            id="path4972"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#ed83ff"
          />
        </g>
        <g id="g4974">
          <g id="g5014">
            <g id="g5012" clipPath="url(#clipPath4980)">
              <g id="g5010">
                <g id="g5008" mask="url(#mask4986)">
                  <g
                    id="g5006"
                    transform="matrix(20.267 0 0 27.505 1337.9 439.77)"
                  >
                    <image
                      id="image5004"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask4998)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAYAAACgADyUAAAABHNCSVQICAgIfAhkiAAAAFRJREFUOI1j/P///38GMgALNsGlDP9Q+NEMTBhqGJFtRNeADpANgLMIaUJXg+kGIgETsbah20qZjaMaB5VGbIkYF4CppdypxNiKrIYRW0YmOT+SAgAFgx4VbUQxoQAAAABJRU5ErkJggg=="
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g5016" transform="translate(1348 448.76)">
          <path
            id="path5018"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#08eeff"
          />
        </g>
        <g id="g5020">
          <g id="g5060">
            <g id="g5058" clipPath="url(#clipPath5026)">
              <g id="g5056">
                <g id="g5054" mask="url(#mask5032)">
                  <g
                    id="g5052"
                    transform="matrix(21.714 0 0 27.505 1343.7 444.11)"
                  >
                    <image
                      id="image5050"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask5044)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAYAAABPwleqAAAABHNCSVQICAgIfAhkiAAAAE5JREFUOI1j/P///38GMgELdmEeLGJfMEQYUW3Gpgm3IUykaURVx4RHFUHARJqtqLZTw+ZRzUNGM2aixw++UM1mUmxHqGPEXhiQlZ9JAwBdlxcVq4ILRgAAAABJRU5ErkJggg=="
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g5062" transform="translate(1354.7 453.05)">
          <path
            id="path5064"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#f4cb00"
          />
        </g>
        <g id="g5066">
          <g id="g5106">
            <g id="g5104" clipPath="url(#clipPath5072)" opacity={0.75}>
              <g id="g5102">
                <g id="g5100" mask="url(#mask5078)">
                  <g id="g5098" transform="matrix(40 0 0 48 1321.9 424.9)">
                    <image
                      id="image5096"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask5090)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAYAAABjezibAAAABHNCSVQICAgIfAhkiAAAAOZJREFUWIXtmLEOwyAMBV+ijvnkjv3k7HTK0gK2MWBb8s2puV4QSnKUUgocc1oLUKSglhTU8lL9+nPzrntfw0sc4mOGK1VjQJQvqBH7RSDK24Mz5YTzaMHZcsK5fcFVcoL59scMIdkWXF2PiX1BoBujLuikHuClINCM8i/oqB7gqWCDFBRR2V4ndYE1vgpWSEEtwQQV7w5TqKwfrKBDAgpa7cPGugELAvsrdtYLWhDYV5FYJ3BBYH1Fxny64CpJ5lzZ57cZD7TCPyz/PgiMiQ7eiTHBB46ocovoBDcQ/JhxQApqSUEtX5KnQzCOBQPAAAAAAElFTkSuQmCC"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g5108" transform="translate(1341.6 444.67)">
          <path
            id="path5110"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#ed83ff"
          />
        </g>
        <g id="g5112">
          <g id="g5152">
            <g id="g5150" clipPath="url(#clipPath5118)">
              <g id="g5148">
                <g id="g5146" mask="url(#mask5124)">
                  <g
                    id="g5144"
                    transform="matrix(20.267 0 0 27.505 1337.9 416.88)"
                  >
                    <image
                      id="image5142"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask5136)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAATCAYAAACgADyUAAAABHNCSVQICAgIfAhkiAAAAFRJREFUOI1j/P///38GMgALNsGlDP9Q+NEMTBhqGJFtRNeADpANgLMIaUJXg+kGIgETsbah20qZjaMaB5VGbIkYF4CppdypxNiKrIYRW0YmOT+SAgAFgx4VbUQxoQAAAABJRU5ErkJggg=="
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g5154" transform="translate(1348 425.86)">
          <path
            id="path5156"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#08eeff"
          />
        </g>
        <g id="g5158">
          <g id="g5198">
            <g id="g5196" clipPath="url(#clipPath5164)">
              <g id="g5194">
                <g id="g5192" mask="url(#mask5170)">
                  <g
                    id="g5190"
                    transform="matrix(21.714 0 0 27.505 1343.7 421.22)"
                  >
                    <image
                      id="image5188"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask5182)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAYAAABPwleqAAAABHNCSVQICAgIfAhkiAAAAE5JREFUOI1j/P///38GMgELdmEeLGJfMEQYUW3Gpgm3IUykaURVx4RHFUHARJqtqLZTw+ZRzUNGM2aixw++UM1mUmxHqGPEXhiQlZ9JAwBdlxcVq4ILRgAAAABJRU5ErkJggg=="
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g5200" transform="translate(1354.7 430.16)">
          <path
            id="path5202"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#f4cb00"
          />
        </g>
        <g id="g5204">
          <g id="g5244">
            <g id="g5242" clipPath="url(#clipPath5210)" opacity={0.75}>
              <g id="g5240">
                <g id="g5238" mask="url(#mask5216)">
                  <g id="g5236" transform="matrix(40 0 0 48 1321.9 401.9)">
                    <image
                      id="image5234"
                      transform="matrix(1,0,0,-1,0,1)"
                      width={1}
                      height={1}
                      imageRendering="optimizeSpeed"
                      mask="url(#mask5228)"
                      preserveAspectRatio="none"
                      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAYAAABjezibAAAABHNCSVQICAgIfAhkiAAAAOZJREFUWIXtmLEOwyAMBV+ijvnkjv3k7HTK0gK2MWBb8s2puV4QSnKUUgocc1oLUKSglhTU8lL9+nPzrntfw0sc4mOGK1VjQJQvqBH7RSDK24Mz5YTzaMHZcsK5fcFVcoL59scMIdkWXF2PiX1BoBujLuikHuClINCM8i/oqB7gqWCDFBRR2V4ndYE1vgpWSEEtwQQV7w5TqKwfrKBDAgpa7cPGugELAvsrdtYLWhDYV5FYJ3BBYH1Fxny64CpJ5lzZ57cZD7TCPyz/PgiMiQ7eiTHBB46ocovoBDcQ/JhxQApqSUEtX5KnQzCOBQPAAAAAAElFTkSuQmCC"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="g5246" transform="translate(1341.6 421.78)">
          <path
            id="path5248"
            d="m0 0c-0.667-0.297-1.208 3e-3 -1.208 0.67v7.237c0 0.667 0.541 1.449 1.208 1.746 0.668 0.298 1.209-3e-3 1.209-0.67v-7.236c0-0.668-0.541-1.45-1.209-1.747"
            fill="#ed83ff"
          />
        </g>
      </g>
    </g>
    <g id="g5250" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <path
        id="path5294"
        d="m1272.2 790.26c-0.274-0.155-0.504-0.081-0.595-0.279-0.018-0.041-0.022-155.98-0.021-193.06l4.125-2.305c0.01 32.318 0.01 195.26-0.012 195.31-0.083 0.202-0.315 0.179-0.59 0.337-0.397 0.23-0.921 0.345-1.448 0.345-0.526 0-1.054-0.115-1.459-0.345"
        fill="url(#linearGradient5292)"
      />
    </g>
    <g id="g5296" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <path
        id="path5340"
        d="m1279.3 788.84c-0.274-0.156-0.504-0.081-0.595-0.28-0.017-0.04-0.022-157.08-0.02-193.45l4.124 2.418c0.01 40.377 0.01 190.92-0.012 190.98-0.083 0.203-0.315 0.178-0.59 0.338-0.398 0.229-0.922 0.344-1.448 0.344s-1.054-0.115-1.459-0.344"
        fill="url(#linearGradient5338)"
      />

    </g>
    <g id="beams-1" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <path  id="path5450" d="m1269.5 794.77c-0.083 0.203-0.315 0.179-0.589 0.338-0.796 0.459-2.098 0.459-2.907-1e-3 -0.275-0.155-0.504-0.08-0.595-0.28-0.021-0.044-0.023-196.26-0.021-200.83v-3.817c1.258-0.434 2.856-0.425 4.111 0.03 0 0.823 0 2.201 0.01 3.796 0.01 4.565 0.013 200.7-0.01 200.76" fill="url(#linearGradient5446)" />
      <path id="path5392" d="m1289.6 793.67c-0.083 0.202-0.315 0.178-0.589 0.337-0.796 0.459-2.097 0.459-2.908 0-0.274-0.156-0.503-0.081-0.594-0.28-0.021-0.045-0.024-196.26-0.021-200.83 1e-3 -1.628 0-3.024 0-3.816 1.257-0.434 2.855-0.425 4.109 0.03 0 0.822 0.01 2.201 0.01 3.796 0.01 4.565 0.015 200.7-0.01 200.76" fill="url(#beam-gradient5388)" />
    </g>
    <g id="g5466" transform="matrix(.35278 0 0 -.35278 -54.676 612.9)">
      <g id="g5502">
        <path
          id="path5510"
          d="m1256.3 781.51-0.059-176.58 20.248-11.689 20.696 11.735v177.81z"
          fill="url(#linearGradient5508)"
        />
      </g>
      <g id="g5468" clipPath="url(#clipPath5472)">
        <g id="g5474">
          <g id="g5500" mask="url(#mask5488)" />
        </g>
      </g>
    </g>
  </g>
  )
}