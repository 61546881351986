import { makeAutoObservable } from "mobx"

class Scores {
  scores = {}

  add(id, value) {
    if(this.scores[id] === undefined) {
      this.scores[id] = 0
    }
    this.scores[id] += value
  }



  get ids() { return this.all.map(c => c.id)}


  get(id) { return this.scores[id]}

}


export default new Scores()