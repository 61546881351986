import { makeAutoObservable } from "mobx"

class MeetingCommander {
  currentPeriod = -1
  constructor(meeting_id, vertxClient) {
    this.meetingId = meeting_id
    this.client = vertxClient

    this.listenToMeetingEvents()


    makeAutoObservable(this)

  }

  onEvent(cb) {
    this.onEventCb = cb
  }


  listenToMeetingEvents() {

    const onmessage = (meeting_id, from_user_id, message) => {
      if(meeting_id === this.meetingId) {
        try {
          let m = JSON.parse(message)

          if(m.Name === "Sequencer") {
            const mData = JSON.parse(m.Data);
            // console.log("mData", mData)
            // NOTE c'est un message de type event, on s'en sert pour la gestio de la séquence
            if (mData.Event) {
              if (this.onEventCb) this.onEventCb(mData.Event)
            }

            // NOTE c'est un message de type info
            if(mData.Info) {
              // c'est un message de type sequenceInfo
              // ici on s'en sert pour
              // -la vérification qu'on est tjs connecté
              // - la gestion de la séquence
              if(mData.Info.Type === "SEQUENCE_INFO") {
                if(this.meeting_infos_cb) this.meeting_infos_cb(mData.Info.Sequence)
                // this.offlineTimeout = clearTimeout(this.offlineTimeout)
                // this.status = STATUS.CONNECTED
                this.currentPeriod = mData.Info.Sequence.CurrentPeriod
                // this.sequenceManager.update(mData.Info)
              }

            }
          }
        }
        catch(err) {
          console.log("err", err)
        }
      }
    }

    this.client.SIG_MeetingMessage.Add(onmessage)
  }
  getMeetingInfos(cb) {

    this.meeting_infos_cb = cb

    let sequenceCmder = this.client.sequencerCmdHelper.__class__
    const cmd = sequenceCmder.GetInfos()
    this.sendCmd(cmd);
  }

  sendCmd(cmd){
    return new Promise( (resolve, reject) => {
      this.client.UserMeetingSequencerCommand(this.meetingId, cmd, (success, msg, data) => {
        if(success) {
          resolve(data)
        }
        else {
          // NOTE ICI c'est en cas d'Activity morte, on la relance comme ça je crois...
          // je commente pour le moment

          // let seq = {
          //   id: 0,
          //   "Periods": this.currentMeeting.Config.MeetingPeriods.map(p => ({...p, SpeedFactor: 1}))
          // }

          // this.client.MeetingSequenceSet(this.currentMeeting._id, JSON.stringify(seq), (success, msg, data) => {
          //   if(success) {
          //     resolve(data)
          //   } else {
          //     this.status = STATUS.OFFLINE
          //     reject(msg)
          //   }
          // })
          reject(msg)
        }
      })

    })
  }


}


export default MeetingCommander