import { makeAutoObservable, toJS } from "mobx"
import { TYPES } from "./Actions/Action"


export const ROLES = {
  DS: "data_scientists",
  DE: "data_engineers",
  PO: "product_owners",
  C: "contractors",
}

export class Talent {
  type = TYPES.TALENT



  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }
}


class Talents {
  all = []

  init(data) {
    this.all = data
    .filter(elem => elem.id)
    .map(elem => new Talent(elem))
  }

  get(id) { return this.all.find(c => c.id === id) }

  get ids() { return this.all.map(c => c.id)}
}


export default new Talents()