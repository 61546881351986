import { observer } from 'mobx-react-lite';
import React from 'react'
import PopupsManager from 'react-sdk/PopupsManager';
import AppState, { POPUPS } from '../../../../js/AppState';
import Brick from '../Brick/Brick';

import './Square.css'

const Square = ({className, enablers, transform=""}) => {


  let transforms = [
    "matrix(1.15 0 0 1.15 -105.88 32.231)",
    "matrix(1.15 0 0 1.15 -78.382 48.106)",
    "matrix(1.15 0 0 1.15 -50.885 63.981)",
    "matrix(1.15 0 0 1.15 -133.37 48.106)",
    "matrix(1.15 0 0 1.15 -105.88 63.981)",
    "matrix(1.15 0 0 1.15 -78.382 79.856)",
    "matrix(1.15 0 0 1.15 -160.87 63.981)",
    "matrix(1.15 0 0 1.15 -133.37 79.856)",
    "matrix(1.15 0 0 1.15 -105.88 95.731)"
  ]


  let bricks = new Array(9).fill(0)
  .map((_, index) => {
    let e = enablers[index]


    if(e) {

      let popupData = PopupsManager.getData(POPUPS.DASHBOARD_POPUP)

      return <React.Fragment key={index}>
        <g >
          {popupData && popupData.elem  === e && // une brick avec un gros contour blanc derrière la brique selectionnée
          <g className='highlighted' >
            <Brick transform={transforms[index]} enabler={e} />
          </g>}
          <Brick transform={transforms[index]} enabler={e} onClick={evt => AppState.openDashboardPopup(evt, e)} />
          {(e && e.just_bought) &&
          <g className='blink' >
            <Brick transform={transforms[index]} enabler={e} />
          </g>}
        </g>

      </React.Fragment>


    }
    else {
      return <Brick key={index} transform={transforms[index]} /> // juste la base
    }

  })

  return (
    <>
    <g className={"Square " + className} transform={transform}>
      <path className="Square-base" d="m183.31 52.917 91.654 52.917-91.654 52.917-91.654-52.917z" />

      <g transform="translate(78.5 -43)">
        {bricks}
      </g>
    </g>

    </>
  );
};

export default observer(Square)
