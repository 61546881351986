import React from 'react'
import Texts from '../../../js/Texts';

import './BudgetProgressbar.css'

const BudgetProgressbar = ({current, total, word = Texts.get("budget")}) => {

  let nb_total_elems = total


  let percentage = current / total
  let nb_elems = Math.floor(nb_total_elems * percentage)

  let elems = []

  for (let i = 0; i < nb_elems; i++ ){
    elems.push(<div className="BudgetProgressbar-elem" key={i} />)
  }

  for (let j = nb_elems; j < nb_total_elems; j++) {
    elems.push(<div className="BudgetProgressbar-elem inactive" key={j} />)
  }

  return (
    <div className="BudgetProgressbar">

      <div className="BudgetProgressbar-total" style={{opacity: percentage < 1 ? 0.4 : 0}}>{total}</div>

      <div className="BudgetProgressbar-elems-ctnr">
        <div className="BudgetProgressbar-current" style={{bottom: percentage * 100 + "%"}}>{current}</div>

        {elems}
      </div>
      <div className="BudgetProgressbar-title">{word}</div>
    </div>
  );
};

export default BudgetProgressbar;
