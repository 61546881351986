import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Personnages from 'react-sdk/Personnage/Personnages'
import AppState from '../../js/AppState'

import Characters from '../../js/Characters'
import Loader from '../shared/Loader/Loader'


import './Personnage.css'
import SpeechDisplayer from './SpeechDisplayer/SpeechDisplayer'



const Personnage = ({dial, onDialEnd, idleCharacter, onClick}) => {

  const [subtitle, setSubtitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(false)
    if(dial) {

      if(AppState.meeting_scenario.quickGameMode && dial.data.skip_if_quickgame) {
        // dial.skip()
        onDialEnd()
        return
      }

      setVisible(true)

      let loadTimeout = setTimeout(() => setLoading(true), 100)


      dial.load()
      .then(() => {
        clearTimeout(loadTimeout)
        setLoading(false)
        dial.play(onDialEnd)
      } , 100)

      dial.setSubtitleCallback(setSubtitle)
    }

    if(!dial && idleCharacter) {
      let chara = Personnages.get(idleCharacter)
      chara.startIdle()
    }


    return () => {
      dial.setSubtitleCallback(null)
      dial.personnage.stopAnim()

    }
  }, [dial, idleCharacter]);

  function skip() {
    if(dial) {
      dial.skip()
    }
  }


  let char

  if(dial) char = Characters.get(dial.data.Character)

  if(!visible) return null
  return (
    <div className={"Personnage " + (char && char.onRight ?  "on-right" : "") } onClick={() => onClick ? onClick() : null}>

      <div className="canvas-ctnr">
        <SpeechDisplayer character={dial ? dial.personnage : Personnages.get(idleCharacter)} />
      </div>

      {dial &&
      <div className="dial-overlay" onClick={skip}>
        <div className="dial">
          {char && <div className="dial-character">{char.Name} ({char.Job})</div>}
          <div className="dial-text">
            {loading && <Loader color="#0C2649" size="25" />}
            {subtitle}
          </div>
        </div>
      </div>}


    </div>
  )
}

export default observer(Personnage)