import React from 'react'

import './ScreenLoading.css'

const ScreenLoading = ({overlay}) => {
  // const [state, setState] = useState(null);

  return (
    <div className={"screen ScreenLoading" + (overlay ? " overlay" : "")}>
      Loading...
    </div>
  );
};

export default ScreenLoading;
