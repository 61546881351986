import BexpParser from "bexp"
import Notifications from 'react-sdk/Notifications/Notifications'

// TODO envoyer dans react-sdk !
// TODO faire une doc de ce truc

// NOTE permet de parser des textes du genre "minstars(15) AND medal(strategy, gold); minstars(15) AND medal(tech, gold); minstars(15) AND medal(people, gold);" et de récupérer true|false
export default class FunctionStringParser {
  api = {}


  constructor(api) {
    this.api = api
  }

  getMatches(expression) {
    let matches = []

    let regex = /([a-zA-Z]+)\(\s*("?[^)]*?"?)\s*\)/g

    let match = null
    match = regex.exec(expression)
    while(match !== null) {
      matches.push({
        substring: match[0],
        funcName: match[1],
        params: match[2].replace(/"/g, "").split(",").map(a => a.trim())
      })

      // boucle suivante
      match = regex.exec(expression)
    }
    // console.log("matches", matches)
    return matches
  }

  execute(matches, expression = null) {
    matches.forEach(match => {
      let {substring, funcName, params} = match
      // console.log("substring, funcName, params", substring, funcName, params)
      if(this.api[funcName]) {
        let result = this.api[funcName](...params, substring)
        if(expression) expression = expression.replace(substring, result)
      }
      else {
        Notifications.error(`Function ${funcName} does not exist on data ${substring}`)
        if(expression) expression = expression.replace(substring, "")
      }
    })

    return expression
  }

  evaluate(expr) {
    const bexpParser = new BexpParser()
    let result = bexpParser.evaluate(expr)
    return result
  }


  static test() {
    const bexpParser = new BexpParser()

    const t = (expr) => {
      let result = bexpParser.evaluate(expr)
      console.log(`"${expr}" => "${result}"`)
    }

    t("true AND false")
    t("true OR false")

    t("false AND (true OR (true AND false))")
    t("false OR (true OR (true AND false))")

    t("true AND NOT (true OR (true AND false))")


    t("(true AND false) AND (true OR true)")
    t("true AND false AND true OR true")

    t("(true AND (true OR false)) OR (true AND true)")

  }

}
