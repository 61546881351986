import { observer } from 'mobx-react-lite';
import React from 'react';
import Texts from '../../../js/Texts';

import './ActionsMatrix.css';

const ActionsMatrix = ({actions, visibleAction, screenshotmode=false, onActionclick}) => {
  let c = screenshotmode
  let uc = visibleAction && visibleAction.sub_elements[0]


  return (
    <div className={"ActionsMatrix" + (c && c.dimAllButOrdonnee ? " dimAllButOrdonnee" : "") + (c && c.dimAllButAbscisse ? " dimAllButAbscisse" : "")} >
      <div className={"Matrix-legend ordonnee" + (c.overlayBelowOrdonnee ? " ontop":"")}>
        <div>{Texts.get("matrix-legend-vertical-l1")}</div>
        <div style={{opacity: c && c.hideOrdonnee ? 0 : 1}}>{Texts.get("matrix-legend-vertical-l2")}</div>
      </div>
      {c.overlayBelowOrdonnee && <div className="matrix-overlay"></div>}
      <div className='vertical-matrix-container'>


        <div className="Matrix">
          <div className="Matrix-content">
            <div className="Matrix-content-grid-vertical">
              <div/><div/><div/><div/><div/>
              <div/><div/><div/><div/><div/>
              <div/><div/><div/><div/><div/>
            </div>
            <div className="Matrix-content-grid-horizontal">
              <div/><div/><div/><div/><div/><div/>
              <div/><div/><div/>
              {/* <div/><div/> */}
            </div>

            {actions.map((a,index)=> {
              let uc = a.sub_elements[0]

              if(c && c.hidden.includes(index + 1)) return null
              let classname = "Matrix-action" + (uc.inactive ? " inactive":"") + " " + a.state + (visibleAction === a ? " visible" : "")

              if(screenshotmode) {
                classname="Matrix-action" + (!c.actives.includes(index + 1) ? " inactive":"")

              }
              return (
                <div
                className={classname}
                key={a.id}
                style={{top: uc.matrix_y, left: uc.matrix_x}}
                onClick={() => onActionclick(a)}
                >
                  {index + 1}
                </div>)
            })}


            {visibleAction && <div className="Action-trait" style={{left: uc.matrix_x, top: uc.matrix_y}}  /> }
          </div>

        </div>
        <div className={"Matrix-legend abscisse" + (c.overlayBelowAbscisse ? " ontop":"")}>
          <div>{Texts.get("matrix-legend-horiontal-l1")}</div>
          <div style={{opacity: c && c.hideAbscice ? 0 : 1}}>{Texts.get("matrix-legend-horiontal-l2")}</div>
        </div>
        {c.overlayBelowAbscisse && <div className="matrix-overlay"></div>}

      </div>

    </div>
  );
};

export default observer(ActionsMatrix)
