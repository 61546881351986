import Moments from '../../js/Moments';
import Texts from '../../js/Texts';
import Title from '../shared/Title/Title';

import './ScreenFinalDebrief.css';

import SequencesManager from 'react-sdk/SequencesManager';
import moment_stars_0 from "../../images/moment_selection/moment_stars_0.png";
import moment_stars_1 from "../../images/moment_selection/moment_stars_1.png";
import moment_stars_2 from "../../images/moment_selection/moment_stars_2.png";
import moment_stars_3 from "../../images/moment_selection/moment_stars_3.png";
import GameSequence from '../../js/GameSequence';


const ScreenFinalDebrief = () => {


  let elem = GameSequence.current.raw
  const ebit = SequencesManager.variables.getScore("ebit")
  return (
    <div className="ScreenFinalDebrief screen">


      <div className="col2">

        <div className="content">
          {elem.screen_title && <div className="ScreenFinalDebrief-title"><Title>{elem.screen_title}</Title></div>}
          {elem.screen_content}

        </div>

        <div className="col2-bottom">
          <div>
            <section className='moments'>
              <h3>{Texts.get("final_debrief_moments_recap_title")}</h3>
              {Moments.all.map(m => {
                return (
                  <div className="fd-moment" key={m.id}>
                    <span className="m-title">{m.title}</span>
                    {!m.stars  && <span><img alt="" src={moment_stars_0} /></span>}
                    {m.stars === 1  && <span><img alt="" src={moment_stars_1} /></span>}
                    {m.stars === 2  && <span><img alt="" src={moment_stars_2} /></span>}
                    {m.stars === 3  && <span><img alt="" src={moment_stars_3} /></span>}
                  </div>
                )
              })}
            </section>
          </div>
          <div className='ebit-total'>
            <h3>{Texts.get("final_debrief_ebit_title")}</h3>
            <div className="ebit-value" >
              <div className="ebit-value-current">{ebit}</div>
              <div className="ebit-value-separator" />
              <div className="ebit-value-total">{Texts.get("max_ebit")}</div>
            </div>
          </div>

        </div>

      </div>

    </div>
  );
};

export default ScreenFinalDebrief;
