import { observer } from 'mobx-react-lite';

import React, { useEffect } from 'react'
import GameSequence, { SCREEN_TYPES } from '../../js/GameSequence';
import ScreenDashboard from '../ScreenDashboard/ScreenDashboard';
import ScreenMoment from '../ScreenMoment/ScreenMoment';
import ScreenMomentSelection from '../ScreenMomentSelection/ScreenMomentSelection';
import ScreenStars from '../ScreenStars/ScreenStars';
import ScreenText from '../ScreenText/ScreenText';
import ScreenFinalDebrief from '../ScreenFinalDebrief/ScreenFinalDebrief';


import './ScreenSequence.css'
const ScreenSequence = () => {

  let current = GameSequence.current
  let elem = current.raw

  useEffect(() => {
    if(GameSequence.isElementDial(current)) {

      GameSequence.handleDials()
    } else {
      if(GameSequence.isElementSkippable(current)) {
        GameSequence.next()
      }
    }

    return () => { }


  }, [current]);

  let screen_id = elem.screen_type === "PAUSE" ? GameSequence.lastMeaningfullScreenType : elem.screen_type


  return (
    <div className="screen ScreenSequence">
      {screen_id === SCREEN_TYPES.TEXT && <ScreenText />}
      {screen_id === SCREEN_TYPES.MEDIA && <ScreenText />}
      {screen_id === SCREEN_TYPES.YOUTUBE && <ScreenText />}
      {screen_id === SCREEN_TYPES.PAUSE && <ScreenText />}

      {screen_id === SCREEN_TYPES.DASHBOARD && <ScreenDashboard showNextButton={elem.screen_type === SCREEN_TYPES.PAUSE} />}
      {screen_id === SCREEN_TYPES.MOMENT_SELECTION && <ScreenMomentSelection />}
      {screen_id === SCREEN_TYPES.MOMENT_DECISION && <ScreenMoment />}
      {screen_id === SCREEN_TYPES.MOMENT_STARS && <ScreenStars />}
      {screen_id === SCREEN_TYPES.END && <ScreenFinalDebrief />}
    </div>
  );
};



export default  observer(ScreenSequence)
