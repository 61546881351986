import SequencesManager from "react-sdk/SequencesManager"
import AppState from "./AppState"
import Enablers from "./Enablers/Enablers"

import Talents from "./_oldTalents"
import Usecases from "./Usecases/Usecases"

class States {


  snapshots = []


  /**
   * permet de faire une sauvegarde de l'état actuel
   */
  makeSnap() {
    return {
      talents : Talents.all.map(t => Object.assign({}, t)),
      talents_total: Talents.total,
      usecases: Usecases.all.map(uc => Object.assign({}, uc)),
      UCSlots:  Array.from(Usecases.slots.entries()),
      enablers: Enablers.all.map(e => Object.assign({}, e)),
      ebit: SequencesManager.variables.getScore("ebit")
    }
  }

  saveSnap() {
    this.snapshots.push(this.makeSnap())
  }
  loadSnap(snap) {
    console.log("snap", snap)
    Talents.init(snap.talents)
    Usecases.init(snap.usecases)
    Enablers.init(snap.enablers)
    Usecases.slots = new Map(snap.UCSlots)
    SequencesManager.setScore("ebit", snap.ebit)
  }

  /**
   * renvoie le dernier état sauvegardé
   */
   get last_snapshot() {
    if(this.snapshots.length > 0) {
      return this.snapshots[this.snapshots.length - 1]
    }
    return null
  }


  getPreviousItem(type, id = null) {

    if(this.last_snapshot && this.last_snapshot[type]) {


      let item = this.last_snapshot[type]

      if(id) {
        item = item.find(i => i.id === id)
      }

      if(item) return item
    }

    return null

  }
}


export default new States()