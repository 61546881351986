import { observer } from 'mobx-react-lite';
import React from 'react'
import PopupsManager from 'react-sdk/PopupsManager';
import AppState, { POPUPS } from '../../../../js/AppState';
import Cylinder from '../Cylinder/Cylinder';

import './Circle.css'

const Circle = ({transform="", total=7, usecases}) => {

  let cylinders_transforms = []

  let centerX = 5
  let centerY = -26
  let radius = 25
  let ellipse_coef = 1.8
  let scale = 1

  if(total===7) {
    centerX = 26
    centerY = -21
    radius = 29
    ellipse_coef = 1.8
    scale = 0.9
  }


  const getCoords = (index) => {

    let angle = Math.PI * 2 / total * index + 0.2 // le 0.2 permet de tout pivoter un peu autour du centre pour ne pas que des UC soient cachés derrière d'autres

    let x = centerX + radius * Math.cos(angle) * ellipse_coef
    let y = centerY + radius * Math.sin(angle)

    return {x, y}

  }

  for(let i =0; i<total;i++) {
    cylinders_transforms.push(getCoords(i))
  }

  cylinders_transforms = cylinders_transforms.sort((a, b) => {
    if(a.y < b.y) return -1
    if(a.y > b.y) return 1
    return 0
  })
  .map(({x, y}) => {
    return `scale(${scale}) translate(${x} ${y})`
  })



  return (
    <g className="Circle" transform={transform}>

      <ellipse className="Circle-base" cx={183.31} cy={52.917} rx={70.678} ry={40.806}  />
      <ellipse className='Circle-stroke' cx={183.31} cy={52.917} rx={68} ry={38.8} />

      <g>
        {cylinders_transforms.map((t, index) => {
          let uc = usecases[index]

          if(uc) {
            const onClick = e => {
              // if(!uc.headless)
              AppState.openDashboardPopup(e, uc)
            }

            let popupData = PopupsManager.getData(POPUPS.DASHBOARD_POPUP)


            return (
            <React.Fragment key={index}>
              {popupData && popupData.elem  === uc &&
              <Cylinder highlighted phases={uc.phases} transform={t} />}

              <Cylinder phases={uc.phases} phasesStates={uc.phasesStates} iconUrl={uc.iconUrl} transform={t} onClick={onClick} />
            </React.Fragment>)
          }
          else return <Cylinder key={index} transform={t} />
        })}

      </g>


  </g>
  );
};

export default observer(Circle)
