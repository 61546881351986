import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { TYPES } from '../../../../js/Actions/Action';
import Actions from '../../../../js/Actions/Actions';
import Brick from '../../../ScreenDashboard/SvgDashboard/Brick/Brick';
import Cylinder from '../../../ScreenDashboard/SvgDashboard/Cylinder/Cylinder';
import Talent from '../../../ScreenDashboard/SvgDashboard/Square/Talent/Talent';
import './ActionListElement.css';


const ActionListElement = ({action}) => {

  useEffect(() => {
    if(!action.playable)  Actions.unselectAction(action)
  }, [action.playable])

  function handleClick() {
    // les moments de type "list" c'est toujours 1 action max donc on fait un toggle
    Actions.unselectAllActions()
    if(action.playable) Actions.selectAction(action)
  }

  // const prev_selected = action.selected && action.selected !== Moments.selected.id
  let className = "Action"
  if(action.selected_prev) className += " prev-selected"
  if(action.selected_current) className += " selected"
  if(!action.playable) className += " disabled"

  return (
  <div onClick={handleClick} className={className} title={action.id}>
    <div className="Action-top">

      <div className="Action-chevron"/>

      <h2 dangerouslySetInnerHTML={{__html: action.title}} />
    </div>
    {action.selected_current &&
    <div className="Action-effects">
      {action.sub_elements
      // .filter(elem => elem.type !== TYPES.TALENT)
      .map((elem, index) => {
        if(elem.type === TYPES.TALENT) {
          let chars = []
          for(let i = 0; i < parseInt(elem.nb_internal); i++) {
            chars.push(
              <div className='Action-effect talent' key={chars.length}>
                <svg><Talent isExternal={false} visible={true} transform="scale(2.2) translate(4 0)" /></svg>
              </div>
            )
          }

          for(let i = 0; i < parseInt(elem.nb_external); i++) {
            chars.push(
              <div className='Action-effect talent' key={chars.length}>
                <svg><Talent isExternal={true} visible={true} transform="scale(2.2) translate(4 0)"/></svg>
              </div>
            )
          }


          return chars

        }
        else if(elem.type !== TYPES.USECASE) {
          return (
            <div className='Action-effect' key={index}>
              <svg ><Brick enabler={elem}  transform="scale(2) translate(-155 -52)" /></svg>
              <div className='Action-effect-title'>{elem.title}</div>
            </div>)
        }
        else {
          return (
          <div className='Action-effect' key={index}>
            <svg ><Cylinder iconUrl={elem.iconUrl} phases={[true]} transform="scale(2) translate(-145 -52)" /></svg>
            <div className='Action-effect-title'>{elem.title}</div>
          </div>)
        }

      })}
    </div>}
    {action.selected_current && <div className="Action-description">{action.description}</div>}
  </div>)
}


export default observer(ActionListElement)
