import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import '../../fonts/stylesheet.css';
import AppState, { SCREENS } from "../../js/AppState";

import ScreenLoading from "../ScreenLoading/ScreenLoading";
import Notifs from "../shared/Notifs/Notifs";

import ScaleManager from 'react-sdk/ScaleManager';

import GameSequence from "../../js/GameSequence";
import GamemasterOverlay from "../GamemasterOverlay/GamemasterOverlay";
import Personnage from "../Personnage/Personnage";
import ScreenHome from "../ScreenHome/ScreenHome";
import ScreenSequence from "../ScreenSequence/ScreenSequence";
import './App.css';


const App = () => {



  function handleDialEnd() {
    GameSequence.handleDialEnd()

  }


  return (
  <div className="App" style={ScaleManager.style}>

    {AppState.screen === SCREENS.LOADING && <ScreenLoading />}
    {AppState.screen === SCREENS.HOME && <ScreenHome />}
    {AppState.screen === SCREENS.GAME_SEQUENCE && <ScreenSequence />}

    <Notifs />


    {AppState.character &&
    <Personnage dial={AppState.character} onDialEnd={handleDialEnd} />}

    {AppState.multiplayer_overlay && <GamemasterOverlay />}

    <div className="version">
      {process.env.REACT_APP_VERSION}

      {AppState.debugMode && <>
        {/* <button onClick={() => AppState.loadSavedSnap()}>LOAD STATE </button>
        <button onClick={() => AppState.saveSnapToLs()}>SAVE STATE TO LS</button> */}
      </>}
    </div>
  </div>)
}

export default observer(App);
