import { makeAutoObservable } from "mobx"

class Code {
  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }
}


class Codes {
  all = []

  init(json) {
    this.all = json
    .filter(elem => elem.code)
    .map(elem => new Code(elem))
  }

  get(code) { return this.all.find(c => c.code.toLowerCase() === code.toLowerCase()) }

}


export default new Codes()