import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';
import ScaleManager from 'react-sdk/ScaleManager';

import './TraitCanvas.css'

const TraitCanvas = ({coords, direction="left"}) => {
  let r = useRef(null)

  useEffect(() => {
    r.current.width = 1920
    r.current.height = 1080

    let ctx = r.current.getContext("2d")

    ctx.strokeStyle = 'lime'
    ctx.lineWidth  = 2




    if(direction === "left") { // pour les enblers
      let left = coords.left / ScaleManager.scale
      let top = coords.top / ScaleManager.scale + 20
      if(ScaleManager.marginPos === "top") top -= ScaleManager.margin / ScaleManager.scale
      if(ScaleManager.marginPos === "left") left -= ScaleManager.margin / ScaleManager.scale

      ctx.strokeStyle = 'white'
      ctx.lineWidth  = 9

      ctx.beginPath();
      ctx.moveTo(left - 500, top);
      ctx.lineTo(left, top);
      ctx.stroke();
    }
    else { // usecases et talents
      let right = coords.right / ScaleManager.scale
      let top = coords.top / ScaleManager.scale + 20
      if(ScaleManager.marginPos === "top") top -= ScaleManager.margin / ScaleManager.scale
      if(ScaleManager.marginPos === "left") right -= ScaleManager.margin / ScaleManager.scale

      ctx.strokeStyle = 'white'
      ctx.lineWidth  = 9

      ctx.beginPath();
      ctx.moveTo(right + 600, top);
      ctx.lineTo(right, top);
      ctx.stroke();

    }





  }, []);
  return (
    <canvas className="TraitCanvas" ref={r}>
    </canvas>
  );
};

export default TraitCanvas;
