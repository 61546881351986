import React from 'react'
import FadedScroll from '../../shared/FadedScroll/FadedScroll';
import ActionListElement from './ActionListElement/ActionListElement';
import './ActionsList.css'

const ActionsList = ({actions}) => {
  return (
    <FadedScroll>
      {actions.map(a => <ActionListElement action={a} key={a.id} />)}
    </FadedScroll>
  );
};

export default ActionsList;
