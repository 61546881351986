import { makeAutoObservable } from "mobx"

/**
 * permet de gérer une stack de popups
 * soit utiliser en singleton, soit instancier
 *
 *
 */


export class PopupsManager {

  datas = new Map()
  _queue = []
  vertxApi = null

  constructor() {

    this.closeAll = this.closeAll.bind(this)

    makeAutoObservable(this)
  }

  // NOTE: c'est optionnel d'appeler cette méthode
  init(vertxApi) {
    this.vertxApi = vertxApi
  }


  open(popup_id, data=null) {
    if(!this.isOpened(popup_id)) {
      if(this.vertxApi) this.vertxApi.analytics.navigationEnter("popup." + popup_id)
    }
    this.datas.set(popup_id, data) // pas dans le if car on peut vouloir mettre à jour les datas....

  }

  queue(popup_id, data=null) {
    this._queue.push([popup_id, data])
    this.updateQueue()
  }

  close(popup_id) {
    if(this.isOpened(popup_id)) {
      if(this.vertxApi) this.vertxApi.analytics.navigationLeave("popup." + this.popup)
      // this.popups.delete(popup_id)
      this.datas.delete(popup_id)
      setTimeout(() => {
        this.updateQueue()
      }, 1)
    }
  }

  updateQueue() {
    if(!this.someOpened && this._queue.length > 0) {
      let [popup_id, popup_data] = this._queue.shift() // on prend le premier element inséré FIFO
      this.open(popup_id, popup_data)
    }
  }

  emptyQueue() {
    this._queue = []
  }

  getData(popup_id) {
    return this.datas.get(popup_id)
  }

  isOpened(popup_id) { return this.datas.has(popup_id) }

  get someOpened() { return this.datas.size > 0 }


  closeAll() { this.datas.clear() }
}

export default new PopupsManager()