import { observer } from 'mobx-react-lite';
import React from 'react'
import Notifications from 'react-sdk/Notifications/Notifications';

import './Notifs.css'

const Notifs = () => {

  return (
    <div className="Notifs">
      {Array.from(Notifications.list).map(n => {
        return (
        <div className={"notif " + n.type} key={n.id} onClick={() => n.close()} dangerouslySetInnerHTML={{__html: n.message}}>

        </div>)
      })}

    </div>
  );
};

export default observer(Notifs)