import React, { useEffect, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Texts from '../../../js/Texts';

import './PdfViewer.css'

const PdfViewer = ({url}) => {

  const ctnrRef = useRef(null)
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [h, setH] = useState(100)

  useEffect(() => {
    const height = ctnrRef.current.offsetHeight
    setH(height)
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
  }

  function onLoadError(error) {

    console.log("error", error)

  }

  const next = e => {
    e.stopPropagation()
    setCurrentPage(p => p + 1)

  }

  const prev = e => {
    e.stopPropagation()
    setCurrentPage(p => p - 1)
  }

  return (
    <div className="PdfViewer" ref={ctnrRef}>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onLoadError}>
        <Page height={h} pageNumber={currentPage} />
      </Document>
      <div className='PdfViewer-bottom'>
        {Texts.get("pdf_page")} {currentPage} {Texts.get("pdf_page_of")} {totalPages}
        <div>
          {currentPage > 1 && <button className="MediasGallery-prev" onClick={prev}>{"<"}</button>}
          {currentPage < totalPages && <button className="MediasGallery-next" onClick={next}>{">"}</button>}
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
