import { makeAutoObservable } from "mobx";

import Actions from "./Actions/Actions";

import DataLoading from 'react-sdk/DataLoading';
import Enablers from "./Enablers/Enablers";
import Moments from "./Moments";

import States from "./States";
import Texts from "./Texts";
import Usecases from "./Usecases/Usecases";


import Personnages from "react-sdk/Personnage/Personnages";

import { getUrlVar } from "react-sdk/utils";
import PopupsManager from "react-sdk/PopupsManager";


import GameSequence from "./GameSequence";
import Characters from "./Characters";
import Talents from "./Talents";
import SequencesManager from "react-sdk/SequencesManager";
import Codes from "./Codes";
import MeetingCommander from "./MeetingCommander";
import ScaleManager from "react-sdk/ScaleManager";

export const SCREENS = {
  LOADING: "LOADING",
  HOME: "HOME",
  GAME_SEQUENCE: "GAME_SEQUENCE"
}

export const POPUPS = {
  DASHBOARD_POPUP: "DASHBOARD_POPUP",
  FTUE: "FTUE"
}


class AppState {

  screen = SCREENS.LOADING
  debugMode = false
  solo_game = false
  language = "en"
  multiplayer_overlay = false
  ebit = 0
  character = null

  meeting_commander = null
  meeting_scenario = null

  // ----------------
  showCharacter(dial) {
    this.character = dial
  }

  constructor() {
    if(getUrlVar("debug")) this.debugMode = parseInt(getUrlVar("debug"))

    let vertxconfig = window.VertxConfig.createFromObjet(window.CONFIG.vertx)
    this.vertxApi = window.VertxApi.create(vertxconfig)


    ScaleManager.init(1920,1080)



    SequencesManager.variables.verbose = false

    // let {ServerIP, ServerPort, LocalStorageUserKey, Universe, App} = window.CONFIG.server
    Personnages.waitEngineInit()
    .then(() => DataLoading.getAllFiles({characters: window.CONFIG.characters}, "./"))
    .then(files => Personnages.init(files.characters))
    .then( () => Personnages.loadAll() )
    .then(() => {
      console.log("Tous les persos sont chargés: ", Personnages.all.map(p => p.charData.name).join(", "))
    })
    .then(() => DataLoading.getAllFiles(window.CONFIG.requiredFiles, window.CONFIG.root))
    .then(files => {
      Texts.init(files.texts)
      Codes.init(files.codes)
    })
    .then(() => this.userConnect() )
    .then(() => this.setScreen(SCREENS.HOME))
    .catch(err => {
      console.log("Loading error : ", err)
    })

    makeAutoObservable(this)
  }

  get medias_folder() {
    let {root, medias_folder} = window.CONFIG
    return root + this.meeting_scenario.rootFolder + "/" + medias_folder
  }

  loadScenario() {

    let root = window.CONFIG.root + this.meeting_scenario.rootFolder + "/"

    return DataLoading.getAllFiles(window.CONFIG.scenarioFiles, root)
    .then( (files) => {
      Characters.init(files.chars)
      Enablers.init(files.enablers, files.enablers_categs)
      Usecases.init(files.usecases)
      Actions.init(files.actions)
      Moments.init(files.moments)
      Talents.init(files.talents)
    })
    .then(() => {
      if(this.debugMode !== false) {

        let i = this.debugMode // ici l'index du moment à load
        let m = Moments.all[i - 1]
        m.locked = false
        Moments.select(m)
        GameSequence.sequenceIndex = i
      }
    })
    .then(() => GameSequence.init())

  }

  userConnect() {
    return new Promise( (resolve, reject) => {
      this.vertxApi.user.autoConnect({enableAnonFallback: true}, ({status, data, statusMessage}) => {
        if(status) {
          resolve()
        } else {
          console.log("autoConnect error", statusMessage)
          // this.setScreen(SCREENS.LOGIN)
        }
      })
    })
  }


  meetingconnect(code, pseudo) {
    let code_row = Codes.get(code)

    // ICI jeu solo
    if(code_row !== undefined) {
      this.solo_game = true
      // ici on récupère le scenario en question
      let {root, scenarios_folder} = window.CONFIG
      return DataLoading.getFile(root + scenarios_folder + code_row.scenario + ".config")
      .then(file => {
        this.meeting_scenario = file.data.Game
        this.loadScenario()
        .then(() => this.setScreen(SCREENS.GAME_SEQUENCE))

      })
    }

    // ICI jeu multi
    return new Promise( (resolve, reject) => {
      if(pseudo === "") {
        reject(Texts.get("error_no_username"))
        return
      }

      this.setScreen(SCREENS.LOADING)

      this.vertxApi.client.UserUpdatePseudo(pseudo, (success, message, data) => {
        if(success) {
          this.vertxApi.user.getConnectedUser().pseudo = pseudo
          this.vertxApi.client.UserGetInfo((success, msg, data) => {
            if(success) {
              data = JSON.parse(data.DataArray[0])
              let user_id = data._id
              this.vertxApi.client.MeetingAddUserWithCode(code, user_id, (success, msg, data) => {
                if(success) {
                    let meetingId = data.MeetingID
                    this.vertxApi.client.UserMeetingConnect(meetingId, (success, msg, data) => {
                      if(success) {
                        this._meetingGetConfig(meetingId)
                        .then(() => this.loadScenario())
                        .then(() => {
                          // ICI on ajoute aussi un listener pour gérer les Events
                          this.meeting_commander = new MeetingCommander(meetingId, this.vertxApi.client)
                          this.meeting_commander.onEvent(evt => this.handleEvents(evt))
                          this.solo_game = false

                          // ICI on récup les infos pour savoir si on est start ou pas

                          this.meeting_commander.getMeetingInfos(sequence_infos => {
                            if(sequence_infos.CurrentPeriod > -1) {
                              // ici c'est un jeu multi qui a déja été lancé
                              this.goToSpecificPeriod(sequence_infos.CurrentPeriod, sequence_infos.State === "PERIOD_PAUSED")
                            } else {
                              this.setScreen(null)
                              this.multiplayer_overlay = true

                            }
                          })

                        })
                        .catch(err => {
                          console.log("_meetingGetConfig error", err)
                          reject(err)
                        })
                      }
                      else {
                        console.log("ERROR UserMeetingConnect", msg)
                        reject(Texts.get("meeting_connection_error"))
                      }
                    })

                  } else {
                    console.log("ERROR MeetingAddUserWithCode", msg)
                    reject(Texts.get("meeting_connection_error"))
                  }
                })
            } else {
              console.log("UserGetInfo", msg)
              reject("An error happened")
            }
          })
        }
        else {
          console.log("ERROR SET PSEUDO", message)
          reject(Texts.get("meeting_connection_error"))
        }
      })
    })
  }

  _meetingGetConfig(meetingId) {
    return new Promise( (resolve, reject) => {
      this.vertxApi.client.UserMeetingGetConfig(meetingId, (success, message, data) => {
        console.log("UserMeetingGetConfig success, message, data", success, message, data)
        if(success) {
          try {
            let scenario = JSON.parse(data.DataArray[0])
            this.meeting_scenario = scenario.Game
            resolve()
          }
          catch(err) {
            reject(err)
          }
        }
        else {
          reject(message)
        }
      })

    })
  }

  handleEvents(event) {
    // DEBUT DE PERIODE
    if(event.Type === "PERIOD_STARTED" && event.PeriodProgress === 0) { // car evt PERIOD_STARTED si sortie de pause
      this.multiplayer_overlay = false
      if(this.character) {
        this.character.stop()
        this.character = null
      }
      this.goToSpecificPeriod(event.Period)
    }

    // PERIODE PAUSE/STOP
    if(event.Type === "PERIOD_STOPPED" || event.Type === "PERIOD_PAUSED") {
      // pause le dial en cours s'il existe
      if(this.character) {
        this.character.pause()
      }
      // overlay
      this.multiplayer_overlay = true
    }

    // SORTIE DE PAUSE
    if(event.Type === "PERIOD_STARTED" && event.PeriodProgress > 0) {
      this.multiplayer_overlay = false
      if(this.character) {
        this.character.resume()
      }
    }
  }

  goToSpecificPeriod(periodNumber, onPause) {
    GameSequence.sequenceIndex = periodNumber
    this.multiplayer_overlay = onPause

    if(periodNumber > 0) {
      let moment = Moments.getByIndex(GameSequence.sequenceIndex - 1)
      moment.locked = false
      Moments.select(moment)
      GameSequence.loadMomentSequence(moment.id).then(() => {
        this.setScreen(SCREENS.GAME_SEQUENCE)
      })
    }
    else {
      this.setScreen(SCREENS.GAME_SEQUENCE)
    }


  }

  sendScores() {
    let scores = {
      EBIT: SequencesManager.variables.getScore("ebit"),
      MoT1: Moments.getByIndex(0).stars,
      MoT2: Moments.getByIndex(1).stars,
      MoT3: Moments.getByIndex(2).stars,
      MoT4: Moments.getByIndex(3).stars,
      MoT5: Moments.getByIndex(4).stars
      // MoT6: Moments.getByIndex(5).stars
    }

    let message = JSON.stringify({ Name : 'Score', Data : scores});
    if(this.meeting_commander) {
      this.vertxApi.client.UserMeetingSendToAll(this.meeting_commander.meetingId, message, true, (success, msg, data) => {
        console.log("UserMeetingSendToAll success, msg, data", success, msg, data)
      });
    }
  }

  setScreen(id) {
    this.screen = id
  }


  openDashboardPopup(e, elem) {
    PopupsManager.open(POPUPS.DASHBOARD_POPUP, {
      elem,
      coords: e.target.getBoundingClientRect()
    })
  }
  closeDashboardPopup() {
    PopupsManager.close(POPUPS.DASHBOARD_POPUP)
  }

  saveSnapToLs() {
    localStorage.setItem("bcg_snap",  JSON.stringify(States.makeSnap()))
  }
  loadSavedSnap() {
    let snap = localStorage.getItem("bcg_snap")

    if (snap) {
      try {
        let s = JSON.parse(snap)
        States.loadSnap(s)
        this.setScreen(SCREENS.DASHBOARD)



      }
      catch(err) {
        console.log("err", err)
      }
    }
  }

}


export default new AppState()
