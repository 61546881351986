import React from 'react'
import SequencesManager from 'react-sdk/SequencesManager';
import Texts from '../../../../js/Texts';

import './SvgDashboard-texts.css'


import tech from "../../../../images/tech&data.png"
import strat from "../../../../images/strat&gov.png"
import pwow from "../../../../images/people&wow.png"
import Talents from '../../../../js/Talents';

const SvgDashboardTexts = ({
  showUC=true,
  showTalents=true,
  showEnablersPeople=true,
  showEnablersTech=true,
  showEbit=true,
  showEnablersStrat=true}) => {

  let ebit = SequencesManager.variables.getScore("ebit")

  let talents_option = Talents.hired
  let talents_text_id = null
  if(talents_option) {
    talents_text_id =  "dashboard-talents-title-" + talents_option.id
  }



  return (
    <div className="SvgDashboard-texts">
      {showEbit && <div className="SvgDashboard-texts-ebit">
        <div className="value">{ebit}</div>
        <div className="legend"dangerouslySetInnerHTML={{__html: Texts.get("dashboard-ebit-title")}}  />
      </div>}

      {showUC && <div className="SvgDashboard-texts-usecases" dangerouslySetInnerHTML={{__html: Texts.get("dashboard-ai-solutions")}} />}

      {showTalents &&
      <div className="SvgDashboard-texts-talents">
        <div className="talents-l2" dangerouslySetInnerHTML={{__html: Texts.get("dashboard-talents-subtitle")}} />
        {talents_text_id && <div className="talents-l1" dangerouslySetInnerHTML={{__html: Texts.get(talents_text_id)}} />}
        {/* <div className="talents-l1" dangerouslySetInnerHTML={{__html: "Internal Product Owners\n& Externally Contracted Specialty Vendors"}} /> */}
      </div>}

      {showEnablersPeople &&
      <div className="SvgDashboard-texts-enablers pwow">
        <img src={pwow} />
        <div>
          <div className="enablers-l1" dangerouslySetInnerHTML={{__html: Texts.get("dashboard-enabler-subtitle")}} />
          <div className="enablers-l2" dangerouslySetInnerHTML={{__html: Texts.get("dashboard-enabler-people&wow")}} />
        </div>
      </div>}

      {showEnablersTech && <div className="SvgDashboard-texts-enablers tech">
        <img src={tech} />
        <div>
          <div className="enablers-l1" dangerouslySetInnerHTML={{__html: Texts.get("dashboard-enabler-subtitle")}} />
          <div className="enablers-l2" dangerouslySetInnerHTML={{__html: Texts.get("dashboard-enabler-tech&data")}} />
        </div>
      </div>}

      {showEnablersStrat && <div className="SvgDashboard-texts-enablers strat">
        <img src={strat} />
        <div >
          <div className="enablers-l1" dangerouslySetInnerHTML={{__html: Texts.get("dashboard-enabler-subtitle")}} />
          <div className="enablers-l2" dangerouslySetInnerHTML={{__html: Texts.get("dashboard-enabler-strat&gov")}} />
        </div>
      </div>}



    </div>
  );
};

export default SvgDashboardTexts;
