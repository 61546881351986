import { makeAutoObservable, makeObservable, observable } from "mobx"
import Actions from "./Actions/Actions"
import AppState from "./AppState"
import States from "./States"

class Moment {
  locked = true
  // locked = false
  debriefs = []

  constructor(json, index) {
    Object.assign(this, json)
    this.index = index

    makeAutoObservable(this)

    if(AppState.debugMode) this.locked = false
  }

  submit() {
    States.saveSnap() // on save le state actuel (au cas ou)
  }

  get actions() {
    return Actions.getByMoment(this.id)
  }

  get selected_actions() {
    return this.actions.filter(a => a.isCurrentlySelected(this.id))
  }
  get selected_actions_id() {
    return this.selected_actions.map(a => a.id)
  }

  get nb_selected_actions() {
    return this.selected_actions.length
  }

  get selected_actions_cost() {
    return this.selected_actions.reduce((acc, a) => acc += a.cost, 0)
  }
  get selected_actions_impact() {
    if(this.selected_actions.length === 0) return null
    return this.selected_actions.reduce((acc, a) => acc += a.impact, 0)
  }

  get stars() {
    if(this.nb_selected_actions === 0 ) return null
    if(this.selected_actions_impact <= this.impact_low) return 1
    if(this.selected_actions_impact >= this.impact_high) return 3
    return 2
  }

  get moment_medias() {
    if(!this.medias) return null

    return this.medias
    .split(";")
    .filter(med => med !== "")
    .map(media => AppState.medias_folder + media)
  }


  get moment_file_url() {
    return this.file_download ? AppState.medias_folder + this.file_download : null
  }

  get can_be_validated() {

    if(this.min_budget) {
      if(this.selected_actions_cost < this.min_budget) return false
    }

    return this.nb_selected_actions > 0
  }

  get previously_selected() {
    return this.stars !== null
  }

}


class Moments {
  all = []
  selected = null

  constructor() {
    makeObservable(this, {
      selected: observable
    })
  }

  init(moments) {
    this.all = moments
    .filter(elem => elem.id)
    .map((elem, index) => new Moment(elem, index) )

    this.unlockNext()
  }

  unlockNext() {
    let m = this.all.find(m => m.locked)
    if(m) m.locked = false
  }


  select(moment) {
    if(moment.locked) return

    this.selected = moment
  }

  get ids() { return this.all.map(c => c.id)}
  get(id) { return this.all.find(c => c.id === id) }

  getByIndex(index) {
    return this.all[index]
  }

  get total_stars() {
    return this.all.reduce((acc, moment) => {
      return acc + moment.stars
    }, 0)
  }

  get maximum_stars() {
    return this.all.length * 3
  }

  get allMomentsPlayed() {
    return this.all.filter(m => m.stars === null).length === 0
  }
}


export default new Moments()