import { observer } from 'mobx-react-lite';
import React from 'react';
import { STATES } from '../../../js/Actions/Action';
import Actions from '../../../js/Actions/Actions';
import Texts from '../../../js/Texts';
import Cylinder from '../../ScreenDashboard/SvgDashboard/Cylinder/Cylinder';
import NeonButton from '../../shared/NeonButton/NeonButton';

import './MatrixActionDescription.css';

const MatrixActionDescription = ({action}) => {
  let uc = action && action.sub_elements[0]

  return (
    <div className={"MatrixActionDescription" + (action && action.state === STATES.SELECTED ? " selected" : "")}>
      {!action && <div className="MatrixActionDescription-empty">{Texts.get("matrix-description-empty")}</div>}

      {uc &&
      <div className="MatrixActionDescription-uc">
        <svg viewBox='115 50 120 40'>
          <Cylinder iconUrl={uc.iconUrl} phases={[true]} />
        </svg>
        <div className="uc-title">{uc.title}</div>
        <div className="uc-description">{uc.description}</div>

        {action.state === STATES.SELECTABLE && !uc.inactive &&
        <div className="uc-button">
          <NeonButton  onClick={() => Actions.selectAction(action)}>
            {Texts.get("matrix-description-select-button")}
          </NeonButton>
        </div>}

        {action.state === STATES.TOO_EXPENSIVE && !uc.inactive &&
        <div className="uc-button discarded">
        <NeonButton disabled={true}>
        {Texts.get("moment-matrix-all-uc-selected")}
        </NeonButton>
      </div>}

        {action.state === STATES.SELECTED &&
        <div className="uc-button">
          <NeonButton onClick={() => Actions.unselectAction(action)}>
            {Texts.get("matrix-description-unselect-button")}
          </NeonButton>
        </div>}

        {uc.inactive &&
        <div className="uc-button discarded">
          <NeonButton disabled={true}>
          {Texts.get("moment-matrix-uc-discarded")}
          </NeonButton>
        </div>}


        {action.state !== STATES.SELECTED && action.state !== STATES.SELECTABLE && <div />}
      </div>}
    </div>
  );
};

export default observer(MatrixActionDescription)
