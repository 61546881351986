import React from 'react'
import SequencesManager from 'react-sdk/SequencesManager';
import AppState from '../../../../js/AppState';
import States from '../../../../js/States';
import Texts from '../../../../js/Texts';

import './EbitCircle.css'

const EbitCircle = () => {

  let ebit = SequencesManager.variables.getScore("ebit")
  // ebit = 1500 // pour test
  let prevEbit = States.getPreviousItem("ebit")

  let highlight = ebit !== prevEbit


  let paths = [
    "m412.6-123.85c-41.911 0-76.008 18.512-76.008 41.267 0 22.755 34.097 41.267 76.008 41.267 41.91 0 76.007-18.513 76.007-41.267 0-22.755-34.097-41.267-76.007-41.267m0 84.955c-44.37 0-80.466-19.598-80.466-43.688 0-24.09 36.097-43.688 80.466-43.688 44.368 0 80.465 19.598 80.465 43.688 0 24.09-36.097 43.688-80.465 43.688",
    "m412.6-120.34c-38.346 0-69.544 16.938-69.544 37.758 0 20.819 31.197 37.757 69.544 37.757s69.543-16.938 69.543-37.757c0-20.819-31.197-37.758-69.543-37.758m0 77.936c-40.805 0-74.002-18.024-74.002-40.178 0-22.155 33.197-40.179 74.002-40.179 40.805 0 74.002 18.024 74.002 40.179 0 22.154-33.197 40.178-74.002 40.178",
    "m412.6-116.84c-34.783 0-63.08 15.364-63.08 34.248 0 18.884 28.297 34.249 63.08 34.249 34.781 0 63.079-15.364 63.079-34.249 0-18.884-28.298-34.248-63.079-34.248m0 70.917c-37.241 0-67.538-16.449-67.538-36.669 0-20.219 30.297-36.669 67.538-36.669 37.24 0 67.538 16.45 67.538 36.669 0 20.219-30.297 36.669-67.538 36.669",
    "m412.6-113.33c-31.218 0-56.616 13.79-56.616 30.739 0 16.949 25.397 30.738 56.616 30.738 31.218 0 56.616-13.789 56.616-30.738 0-16.949-25.397-30.739-56.616-30.739m0 63.898c-33.677 0-61.074-14.875-61.074-33.16 0-18.284 27.398-33.16 61.074-33.16s61.074 14.875 61.074 33.16c0 18.284-27.398 33.16-61.074 33.16",
    "m412.6-109.82c-27.654 0-50.151 12.215-50.151 27.23 0 15.014 22.497 27.229 50.151 27.229 27.653 0 50.151-12.215 50.151-27.229 0-15.014-22.498-27.23-50.151-27.23m0 56.879c-30.112 0-54.61-13.301-54.61-29.65 0-16.349 24.498-29.65 54.61-29.65s54.61 13.301 54.61 29.65c0 16.349-24.498 29.65-54.61 29.65",
    "m412.6-106.31c-24.09 0-43.688 10.641-43.688 23.72 0 13.079 19.598 23.72 43.688 23.72 24.089 0 43.687-10.641 43.687-23.72 0-13.079-19.598-23.72-43.687-23.72m0 49.86c-26.548 0-48.147-11.726-48.147-26.141 0-14.413 21.599-26.14 48.147-26.14 26.548 0 48.146 11.727 48.146 26.14 0 14.414-21.598 26.141-48.146 26.141",
    "m412.6-102.8c-20.525 0-37.224 9.0666-37.224 20.21 0 11.144 16.699 20.211 37.224 20.211s37.223-9.0665 37.223-20.211c0-11.143-16.698-20.21-37.223-20.21m0 42.84c-22.984 0-41.683-10.152-41.683-22.631 0-12.478 18.698-22.631 41.683-22.631s41.682 10.152 41.682 22.631c0 12.479-18.698 22.631-41.682 22.631",
    "m412.6-99.288c-16.961 0-30.761 7.4918-30.761 16.701 0 9.2089 13.8 16.701 30.761 16.701s30.759-7.4918 30.759-16.701c0-9.2089-13.798-16.701-30.759-16.701m0 35.822c-19.42 0-35.219-8.5775-35.219-19.121 0-10.543 15.799-19.121 35.219-19.121 19.419 0 35.218 8.5782 35.218 19.121 0 10.544-15.799 19.121-35.218 19.121",
    "m412.6-95.778c-13.397 0-24.296 5.9177-24.296 13.191 0 7.2731 10.899 13.191 24.296 13.191s24.296-5.9177 24.296-13.191c0-7.2738-10.899-13.191-24.296-13.191m0 28.803c-15.855 0-28.755-7.0034-28.755-15.612 0-8.6088 12.899-15.612 28.755-15.612 15.855 0 28.754 7.0034 28.754 15.612 0 8.6081-12.899 15.612-28.754 15.612",
    "m412.6-92.269c-9.8328 0-17.833 4.3429-17.833 9.6816 0 5.3387 7.9999 9.6816 17.833 9.6816s17.831-4.3429 17.831-9.6816c0-5.3387-7.9985-9.6816-17.831-9.6816m0 21.784c-12.291 0-22.291-5.4293-22.291-12.102 0-6.673 10-12.102 22.291-12.102 12.29 0 22.29 5.4293 22.29 12.102 0 6.6731-9.9997 12.102-22.29 12.102",
    "m412.6-88.759c-6.2684 0-11.368 2.7688-11.368 6.1724 0 3.4029 5.0996 6.1717 11.368 6.1717 6.2685 0 11.368-2.7688 11.368-6.1717 0-3.4036-5.0996-6.1724-11.368-6.1724m0 14.765c-8.7274 0-15.827-3.8545-15.827-8.5925 0-4.7386 7.0994-8.5932 15.827-8.5932 8.7267 0 15.827 3.8545 15.827 8.5932 0 4.738-7.1001 8.5925-15.827 8.5925",
    "m412.6-85.25c-2.7048 0-4.9048 1.1947-4.9048 2.6626 0 1.4685 2.2001 2.6626 4.9048 2.6626 2.7034 0 4.9042-1.194 4.9042-2.6626 0-1.4678-2.2007-2.6626-4.9042-2.6626m0 7.7458c-5.1637 0-9.3635-2.2804-9.3635-5.0833s4.1999-5.0833 9.3635-5.0833c5.1623 0 9.3622 2.2804 9.3622 5.0833s-4.1999 5.0833-9.3622 5.0833",
  ].reverse()


  let max_ebit = parseInt( Texts.get("max_ebit") )
  let nb_rings_highlighted = Math.min(Math.ceil(ebit / max_ebit * 12), paths.length)

  let rings = paths.map((p, i) => {
    let dimmed = i >= nb_rings_highlighted // (i / nb_rings) > percent
    return <path key={i} d={p} className={dimmed ? "dimmed" : ""} />
  })

  return (
    <g className={'EbitCircle' + (highlight ? " ebit-blink" : "")} transform="matrix(.89869 0 0 .89869 35.733 -4.3549)">
      <defs>
        <radialGradient id="bubble-gradient" cx="0.3" cy="0.3" r="0.5">
          <stop id="stop1428" stopColor="#09eaff" offset="0"/>
          <stop id="stop1430" stopColor="#10a3ff" offset=".86629"/>
          <stop id="stop1432" stopColor="#10a3ff" offset="1"/>
        </radialGradient>
      </defs>
      <g className='EbitCircle-rings'>{rings}</g>



      <g className='EbitCircle-stroke'>
        <path d="m332.14-81.756c0-24.584 36.026-44.513 80.466-44.513 44.44 0 80.466 19.929 80.466 44.513" />
        <path d="m493.07-83.118c0 24.421-36.026 44.218-80.466 44.218-44.44 0-80.466-19.797-80.466-44.218" />
      </g>



      {nb_rings_highlighted === 1 &&
      <path className="EbitCircle-bubble"
      d="m422.17-83.003c0 2.907-4.1307 5.6458-9.3657 5.6458-5.2349 0-9.5916-2.4594-9.5916-5.6458 0-5.2293 4.2436-9.4682 9.4786-9.4682 5.2349 0 9.4786 4.239 9.4786 9.4682"
      />}

      {nb_rings_highlighted === 2 &&
      <path className="EbitCircle-bubble"
      d="m 428.45124,-83.4026 c 0,4.874711 -6.92677,9.467454 -15.70546,9.467454 v 0 c -8.77847,0 -16.08412,-4.124233 -16.08412,-9.467454 v 0 c 0,-8.768971 7.11604,-15.877355 15.89479,-15.877355 v 0 c 8.77847,0 15.89479,7.108384 15.89479,15.877355"
      />}
      {nb_rings_highlighted === 3 &&
      <path className="EbitCircle-bubble"
      d="m 434.86739,-83.69638 c 0,6.842454 -9.72286,13.28912 -22.04518,13.28912 v 0 c -12.32202,0 -22.5767,-5.789036 -22.5767,-13.28912 v 0 c 0,-12.308684 9.98853,-22.28647 22.31094,-22.28647 v 0 c 12.32202,0 22.31094,9.977786 22.31094,22.28647"
      />}
      {nb_rings_highlighted === 4 &&
      <path className="EbitCircle-bubble"
      d="m 441.17278,-83.483325 c 0,8.776229 -12.47068,17.044814 -28.27546,17.044814 v 0 c -15.8044,0 -28.9572,-7.425099 -28.9572,-17.044814 v 0 c 0,-15.787292 12.81143,-28.584945 28.61633,-28.584945 v 0 c 15.8044,0 28.61633,12.797653 28.61633,28.584945"
      />}
      {nb_rings_highlighted === 5 &&
      <path className="EbitCircle-bubble"
      d="m 447.6441,-84.06402 c 0,10.710004 -15.2185,20.800508 -34.50574,20.800508 v 0 c -19.28678,0 -35.3377,-9.061163 -35.3377,-20.800508 v 0 c 0,-19.26591 15.63433,-34.88343 34.92172,-34.88343 v 0 c 19.28678,0 34.92172,15.61752 34.92172,34.88343"
      />}
      {nb_rings_highlighted === 6 &&
      <path className="EbitCircle-bubble"
      d="m 453.9941,-83.927516 c 0,12.701508 -18.04835,24.668322 -40.92201,24.668322 v 0 c -22.87312,0 -41.90868,-10.746069 -41.90868,-24.668322 v 0 c 0,-22.848374 18.54151,-41.369944 41.41535,-41.369944 v 0 c 22.87312,0 41.41534,18.52157 41.41534,41.369944"
      />}

      {nb_rings_highlighted === 7 &&
      <path className="EbitCircle-bubble"
      d="m 461.29054,-84.310541 c 0,14.533504 -21.22806,28.226346 -48.13153,28.226346 v 0 c -26.90285,0 -49.29204,-12.296024 -49.29204,-28.226346 v 0 c 0,-26.143909 21.8081,-47.336929 48.71179,-47.336929 v 0 c 26.90285,0 48.71178,21.19302 48.71178,47.336929"
      />}
      {nb_rings_highlighted === 8 &&
      <path className="EbitCircle-bubble"
      d="m 467.43082,-84.164399 c 0,16.3655 -23.90393,31.78437 -54.19867,31.78437 v 0 c -30.29404,0 -55.50546,-13.845978 -55.50546,-31.78437 v 0 c 0,-29.439441 24.55708,-53.303911 54.85207,-53.303911 v 0 c 30.29404,0 54.85206,23.86447 54.85206,53.303911"
      />}
      {nb_rings_highlighted === 9 &&
      <path className="EbitCircle-bubble"
      d="m 473.78082,-84.506285 c 0,18.260067 -26.67119,35.463917 -60.47303,35.463917 v 0 c -33.80106,0 -61.9311,-15.448871 -61.9311,-35.463917 v 0 c 0,-32.847535 27.39995,-59.474695 61.20207,-59.474695 v 0 c 33.80106,0 61.20206,26.62716 61.20206,59.474695"
      />}

      {nb_rings_highlighted === 10 &&
      <path className="EbitCircle-bubble"
      d="m 481.18916,-84.74437 c 0,20.312515 -29.89967,39.450093 -67.79313,39.450093 v 0 c -37.89258,0 -69.42768,-17.185338 -69.42768,-39.450093 v 0 c 0,-36.53964 30.71664,-66.15971 68.61041,-66.15971 v 0 c 37.89258,0 68.6104,29.62007 68.6104,66.15971"
      />}
      {nb_rings_highlighted === 11 &&
      <path className="EbitCircle-bubble"
      d="m 487.00999,-84.669696 c 0,22.035807 -32.43632,42.796996 -73.54462,42.796996 v 0 c -41.10735,0 -75.31785,-18.643323 -75.31785,-42.796996 v 0 c 0,-39.639634 33.3226,-71.772634 74.43124,-71.772634 v 0 c 41.10735,0 74.43123,32.133 74.43123,71.772634"
      />}
      {nb_rings_highlighted === 12 &&
      <path className="EbitCircle-bubble"
      d="m 493.62457,-84.440521 c 0,23.994093 -35.31888,46.600296 -80.08041,46.600296 v 0 c -44.76049,0 -82.01122,-20.300125 -82.01122,-46.600296 v 0 c 0,-43.162349 36.28392,-78.150959 81.04582,-78.150959 v 0 c 44.76049,0 81.04581,34.98861 81.04581,78.150959"
      />}




  </g>
  );
};

export default EbitCircle;
