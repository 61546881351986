import React from 'react'

import './Star.css'

const Star = ({fill, color="#08efff", size=40, }) => {

  return (
    <div className="Star">

      <svg width={size} height={size} version="1.1" viewBox="0 0 11.141 10.622" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-8.9981 -7.6022)">
          <path transform="rotate(180 -89.419 -59.271)"
          d="m-193.41-126.41-1.6396-3.3222-3.6663-0.53274 2.653-2.586-0.62628-3.6515 3.2792 1.724 3.2792-1.724-0.62628 3.6515 2.653 2.586-3.6663 0.53274z"
          fill={fill ? color : "transparent"}
          stroke={color}
          strokeLinejoin="round"
          strokeWidth=".6"
          style={{paintOrder: "markers fill stroke"}} />
        </g>
      </svg>
    </div>
  );
};

export default Star;
