import React, { useState } from 'react'
import FadedScroll from '../../shared/FadedScroll/FadedScroll';
import ActionCard from './ActionCard/ActionCard';

import './ActionsGrid.css'

const ActionsGrid = ({actions}) => {
  const [flipped, setFlipped] = useState([]);



  return (
    <FadedScroll>
      <div className="ActionsGrid">
        {actions.map(a => <ActionCard action={a} key={a.id} />)}
        {/* <ActionCard action={actions[0]} /> */}
      </div>
    </FadedScroll>
  );
};

export default ActionsGrid;
