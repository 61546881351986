import * as React from "react";
import Enablers from "../../../js/Enablers/Enablers";
import Usecases from "../../../js/Usecases/Usecases";
import Circle from "./Circle/Circle";
import EbitCircle from "./EbitCircle/EbitCircle";
import Rails from "./Rails/Rails";
import Square from "./Square/Square";
import TalentsSquare from "./Square/TalentsSquare";
import Tower from "./Tower";

import "./SvgDashboard.css";
import { toJS } from "mobx";

const SvgDashboard = () => {



  let headlessUcs = Usecases.activated.filter(uc => uc.headless)


  let nonHeadlessUcs = Usecases.activated.filter(uc => !uc.headless)

  let stratEnablers = Enablers.getBoughtEnablersByCateg("strategy")
  let techEnablers = Enablers.getBoughtEnablersByCateg("tech")
  let peopleEnablers = Enablers.getBoughtEnablersByCateg("people")

  return (
  <div className="SvgDashboard">
    <svg viewBox="0 0 580 155">
      <g transform="translate(-91.522 30)" >
        <Square transform="translate(229.14 -52.917)" className="strategy"  enablers={stratEnablers} />
        <Square transform="translate(0 80)" className="tech"  enablers={techEnablers}  />
        <Square transform="translate(229.14 79.375)" className="people" enablers={peopleEnablers}  />

        <Rails headlessUcsVisible={headlessUcs.length > 0 && nonHeadlessUcs.length > 0} />

        {/* <TalentsSquare transform="translate(0 80)" className="teal"  /> */}

        {headlessUcs.length > 0 && nonHeadlessUcs.length > 0 &&
        <Circle transform="matrix(.63851 0 0 .63851 66.264 -81.413)"  usecases={headlessUcs} total={headlessUcs.length} />}

        {headlessUcs.length > 0 && nonHeadlessUcs.length === 0 &&
        <Circle usecases={headlessUcs} total={headlessUcs.length} />}

        { nonHeadlessUcs.length > 0 && <Circle usecases={nonHeadlessUcs} total={7}  />}
        { nonHeadlessUcs.length === 0 && headlessUcs.length === 0 && <Circle usecases={[]} total={6}  />}

        <EbitCircle />

        <Tower />
      </g>
    </svg>





  </div>
  )
}

export default SvgDashboard;
