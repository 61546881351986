import { makeAutoObservable } from "mobx"

class Character {
  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }
}


class Characters {
  all = []

  init(json) {
    this.all = json
    .filter(elem => elem.id).map(elem => new Character(elem))
  }

  get ids() { return this.all.map(c => c.id)}


  get(id) { return this.all.find(c => c.id === id) }

}


export default new Characters()