import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import Enablers from '../../js/Enablers/Enablers';
import GameSequence from '../../js/GameSequence';
import Moments from '../../js/Moments';
import Talents from '../../js/Talents';

import Texts from '../../js/Texts';
import BudgetProgressbar from '../shared/BudgetProgressbar/BudgetProgressbar';
import MediasGallery from '../shared/MediasGallery/MediasGallery';
import MomentRightColumn from './MomentRightColumn/MomentRightColumn';

import NeonButton from '../shared/NeonButton/NeonButton';
import ActionsGrid from './ActionsGrid/ActionsGrid';

import ActionsList from './ActionsList/ActionsList';
import './ScreenMoment.css';
import ActionsMatrix from './ActionsMatrix/ActionsMatrix';
import MatrixActionDescription from './MatrixActionDescription/MatrixActionDescription';


const ScreenMoment = () => {

  const moment = Moments.selected

  const elem = GameSequence.current.raw

  let button_text = elem.screen_content

  const [showGallery, setShowGallery] = useState(false);
  const [visibleAction, setVisibleAction] = useState(null);
  function handleClickNext() {
    Enablers.buy()
    Talents.hire()

    moment.submit()
    Moments.unlockNext()
    GameSequence.next()
  }


  function toggleGallery() {
    setShowGallery(s => !s)
  }


  return (
    <div className={"ScreenMoment screen " + moment.type}>

      {moment.type !== "matrix" && <div className="col1">
        {moment.type === "grid" &&
        <BudgetProgressbar word={moment.budget_word} current={moment.selected_actions_cost} total={moment.budget} />}
      </div>}


      <div className="col2">
        <div className="ScreenMoment-title">
           <h1>{moment.title}</h1>
        </div>

        <div className="moment-sumup">
          {moment.moment_medias && <NeonButton className='float-left' small onClick={toggleGallery}>{Texts.get("moment_show_medias")}</NeonButton>}
          {moment.sum_up}
        </div>



        {moment.type === "list" && <ActionsList actions={moment.actions} />}
        {moment.type === "grid" && <ActionsGrid actions={moment.actions}  />}
        {moment.type === "matrix" && <ActionsMatrix actions={moment.actions} visibleAction={visibleAction} onActionclick={a => setVisibleAction(a)} />}

      </div>
      <div className="col3">
        {moment.show_right_col && <MomentRightColumn />}

        {!moment.show_right_col && <div></div>}

        {moment.type === "matrix" &&
        <MatrixActionDescription action={visibleAction} />}

        <div className='ScreenMoment-next-button'>
          {moment.type === "matrix" &&
          <div className='ScreenMoment-nb-selected' dangerouslySetInnerHTML={{__html:
            Texts.get("moment-matrix-next-button-disabled", null, null, { current: moment.selected_actions_cost, total: moment.min_budget  })
          }}>
          </div>}
          <NeonButton disabled={!moment.can_be_validated} onClick={handleClickNext}>{button_text}</NeonButton>
        </div>

      </div>

      {showGallery && <MediasGallery onCloseClick={toggleGallery} urls={moment.moment_medias} />}


    </div>
  );
};

export default observer(ScreenMoment)
