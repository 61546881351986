import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Moments from '../../js/Moments';
import Texts from '../../js/Texts';
import FadedScroll from '../shared/FadedScroll/FadedScroll';
import Title from '../shared/Title/Title';
import MomentButton from './MomentButton/MomentButton';
import './ScreenMomentSelection.css';

const ScreenMomentSelection = () => {
  const [selectedMoment, setSelectedMoment] = useState(null);



  return (
    <div className="screen ScreenMomentSelection">
      <div className="col1">
        <Title>{Texts.get("screen_moment_selection_title")}</Title>
      </div>

      <div className="col2">
        <FadedScroll>
          {Moments.all.map(moment =>
          <MomentButton selected={moment === selectedMoment} moment={moment} key={moment.id} onClick={setSelectedMoment} />)}
        </FadedScroll>

      </div>
      <div className="col3">
        {!Moments.allMomentsPlayed && <div/>}



      </div>





    </div>
  );
};

export default observer(ScreenMomentSelection)
