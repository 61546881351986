import React, { useEffect } from 'react';
import clock from "../../../images/clock.png";
import { TYPES } from '../../../js/Actions/Action';
import AppState, { POPUPS } from '../../../js/AppState';
import { UC_PHASES_STATES } from '../../../js/Usecases/Usecase';
import Cylinder from '../SvgDashboard/Cylinder/Cylinder';
import './DashboardPopup.css';

import { observer } from 'mobx-react-lite';
import PopupsManager from 'react-sdk/PopupsManager';
import ScaleManager from 'react-sdk/ScaleManager';
import arrows from "../../../images/arrows.png";
import arrows_forbidden from "../../../images/arrows_forbidden.png";
import Texts from '../../../js/Texts';
import Brick from '../SvgDashboard/Brick/Brick';
import Talent from '../SvgDashboard/Square/Talent/Talent';
import TraitCanvas from './TraitCanvas/TraitCanvas';
import { toJS } from 'mobx';

const EnablerDetails = ({enabler}) => {

  return (
    <div className="DashboardPopup-enabler-detail">

      <div className="detail-block enabler-selected-at">
        <div className="detail-icon"><img src={clock} /></div>
        <div>
          <div className="detail-l1" style={{color: enabler.categ.color}}>{Texts.get("dashboard-popup-selected")}</div>
          <div className="detail-l2">{Texts.get("dashboard-popup-on-mot")}{enabler.moment.index + 1}</div>
        </div>
      </div>
    </div>
  )
}

const UsecaseDetails = ({usecase}) => {
  let viewBox = "152 15 50 80"

  // ici on doit reconstruire les objets de phase et de phaseStates car ça ne représente pas les états

  // let phases_0 = [usecase.phases[0]]
  // let phases_1 = [usecase.phases[0], usecase.phases[1]]
  // let phases_2 = [usecase.phases[0], usecase.phases[1], usecase.phases[2]]
  let phases_0 = [true]
  let phases_1 = [true, true]
  let phases_2 = [true, true, true]



  return (
    <div className='DashboardPopup-usecase-detail' >
      {/* {Texts.get("dashboard-popup-current-situation")} */}


      <div className='DashboardPopup-usecase-svgs'>
        <div>
          <svg viewBox={viewBox}>
            <Cylinder
              highlighted={usecase.phases.length === 1}
              phases={phases_0}
              phasesStates={usecase.phasesStates}
              iconUrl={usecase.iconUrl} />
          </svg>
          <div>{Texts.get("dashboard-popup-usecase-phase0")}</div>
        </div>

        <div className="arrows">
          <img src={arrows} />
        </div>

        <div>
          <svg viewBox={viewBox}>
            {usecase.phases.length === 2 && <Cylinder phases={phases_1} highlighted />}
            <Cylinder phases={phases_1} phasesStates={usecase.phasesStates} wireframe={usecase.phases.length < 2} iconUrl={usecase.iconUrl} />
          </svg>
          {usecase.phasesStates[1] === undefined && <div></div>}
          {usecase.phasesStates[1] === UC_PHASES_STATES.RED && <div>{Texts.get("dashboard-popup-usecase-phase1-failed")}</div>}
          {(usecase.phasesStates[1] === UC_PHASES_STATES.GREEN || usecase.phasesStates[1] === UC_PHASES_STATES.DEFAULT) && <div>{Texts.get("dashboard-popup-usecase-phase1-succeeded")}</div>}
        </div>

        <div className="arrows">
          {usecase.phasesStates[1] === UC_PHASES_STATES.RED &&
          <img src={arrows_forbidden} />}

          {usecase.phasesStates[1] !== UC_PHASES_STATES.RED &&
          <img src={arrows} />}
        </div>

        <div>
          <svg viewBox={viewBox}>
            {usecase.phases.length === 3 && <Cylinder phases={phases_2} highlighted />}
            <Cylinder phases={phases_2} iconUrl={usecase.iconUrl}  wireframe={usecase.phases.length < 3} phasesStates={usecase.phasesStates} />
          </svg>
          {usecase.phasesStates[2] === undefined && <div></div>}
          {usecase.phasesStates[2] === UC_PHASES_STATES.RED && <div>{Texts.get("dashboard-popup-usecase-phase2-failed")}</div>}
          {(usecase.phasesStates[2] === UC_PHASES_STATES.GREEN || usecase.phasesStates[2] === UC_PHASES_STATES.DEFAULT) && <div>{Texts.get("dashboard-popup-usecase-phase2-succeeded")}</div>}
        </div>

      </div>
    </div>
  )
}

const DashboardPopup = ({data}) => {
  let {elem, coords} = data

  let isEnabler = elem.type === TYPES.ENABLER
  let isUsecase = elem.type === TYPES.USECASE
  let isTalent = elem.type === TYPES.TALENT

  const handleClose = () => AppState.closeDashboardPopup()

  useEffect(() => {
    const handleEscape = e => { if(e.code === 'Escape')  handleClose() }
    window.addEventListener('keydown', handleEscape)
    return () => { window.removeEventListener('keydown', handleEscape) }
  }, []);

  useEffect(() => {
    PopupsManager.close(POPUPS.FTUE)
  }, []);

  let color = null
  if(isEnabler) color = elem.categ.color
  if(isUsecase) color = "#009EBB"

  let top = coords.top / ScaleManager.scale
  if(ScaleManager.marginPos === "top") top -= ScaleManager.margin / ScaleManager.scale

  let left  = coords.left / ScaleManager.scale
  if(ScaleManager.marginPos === "left") left -= ScaleManager.margin / ScaleManager.scale

  let style = {
    borderColor: color
  }

  let category = ""
  if(isEnabler) category = elem.category
  if(isUsecase) category = elem.headless ? "headless" : ""

  let traitDirection = isEnabler && elem.category !== "tech" ? "left" : "right"

  return (
    <div className={"DashboardPopup-overlay " + elem.type + " " + category} onClick={handleClose}>
      <TraitCanvas coords={coords} direction={traitDirection} />
      <div className="DashboardPopup" style={style} onClick={e => e.stopPropagation()}>
        {isEnabler &&
        <div className="DashboardPopup-brick">
          <svg viewBox='132 58 100 27'>
            <Brick enabler={elem} />
          </svg>
        </div>}

        <div className="DashboardPopup-close" style={{backgroundColor: color}} onClick={handleClose} >&times;</div>

        {!isTalent &&
        <>
          <div className="DashboardPopup-title" style={{backgroundColor: color}} >{elem.title}</div>

          <div className="DashboardPopup-description" style={{color: color}}>
            {elem.description}
          </div>
        </>}

        {isTalent &&
        <div className='DashboardPopup-talent-content'>
          <svg viewBox="-5 0 40 50">
            <Talent isExternal={elem.talent_type === "external"} visible />
          </svg>
          <div>
            <div className="DashboardPopup-title" style={{backgroundColor: color}} >
              {Texts.get("dashboard-popup-talent-" + elem.talent_type + "-title")}
            </div>

            <div className="DashboardPopup-description" style={{color: color}}>
            {Texts.get("dashboard-popup-talent-" + elem.talent_type + "-description")}
            </div>
          </div>

        </div>}

        {isEnabler && <EnablerDetails enabler={elem} />}
        {isUsecase && <UsecaseDetails usecase={elem} />}
      </div>
      {/* <div className="DashboardPopup-trait-horiz" /> */}
    </div>
  );
};

export default observer(DashboardPopup)
