import { observer } from 'mobx-react-lite';
import React from 'react'
import { useEffect } from 'react';
import AppState, { SCREENS } from '../../js/AppState';
import GameSequence from '../../js/GameSequence';
import Moments from '../../js/Moments';
import Texts from '../../js/Texts';

import NeonButton from '../shared/NeonButton/NeonButton';
import Star from '../shared/Star/Star';
import Title from '../shared/Title/Title';

import './ScreenStars.css'

// TODO renommer en "KEY_LEARNINGS" ?

const ScreenStars = () => {
  const m = Moments.selected

  function handleDownload() {
    window.open(m.moment_file_url, "_blank");
  }

  useEffect(() => {
    AppState.sendScores()

  }, []);


  return (
    <div className="ScreenStars screen">

      <div className="col1">
        <Title>{Texts.get("screen_key_learnings_title")}</Title>


      </div>
      <div className="col2">
        <h1>{m.title}</h1>

        <div className="ScreenStars-score">
          <div className="ScreenStars-score-title">{Texts.get("keylearnings_your_score")}</div>
          <span>
            <Star fill={2 < m.stars} size={150} />
          </span>
          <span>
            <Star fill={0 < m.stars} size={150} />
            <Star fill={1 < m.stars} size={150} />
          </span>
        </div>

        <div className="ScreenStars-explanation">

        <div className="ScreenStars-dld-desc" dangerouslySetInnerHTML={{__html: GameSequence.lastMeaningfullContent}} />


          {m.moment_file_url &&
          <NeonButton className='ScreenStars-dld-btn' onClick={handleDownload}>
            {Moments.selected.download_button_text}
          </NeonButton>}
        </div>

      </div>
      <div className="col3">

        <div />
        <NeonButton onClick={() => GameSequence.next()}>{Texts.get("next_button")}</NeonButton>
      </div>




    </div>
  );
};

export default observer(ScreenStars)
