import * as React from "react";
import Usecases from "../../../../js/Usecases/Usecases";


/* de haut en bas, de gauche à droite */

import "./Rails.css"

export default ({headlessUcsVisible}) => (
  <g className="Rails" >

    {headlessUcsVisible && <path id="path21113" d="m 178.72598,-23.812499 v 37.041667 m 9.16544,0 v -37.041667" stroke="#00b8bc"/>}

    <path id="path20275" d="m357.45-52.917-132.9 76.729m9.1654 5.2917 132.9-76.729" stroke="#00b8bc"/>
    <path id="path20717" d="m219.97 84.667 59.575 34.396m9.1654-5.2917-59.575-34.396" stroke="#00b8bc"/>


    <path d="m362.03 76.729-64.158 37.042m9.1654 5.2917 64.158-37.042" stroke="#40bfff" />

    {/* <path d="m288.71 119.06-64.158 37.042m9.1654 5.2917 64.158-37.042" stroke="#00b8bc" /> */}
    <path d="m288.71 119.06-64.158 37.042m9.1654 5.2917 64.158-37.042" stroke="#ee8aff" />

    <path id="path2129" d="m 311.6248,132.29167 50.4099,29.10416 m 9.16543,-5.29166 L 320.79023,127" stroke="#ffe552" />

    {/* <path d="m439.94 121.71h-109.99v-5.2917h109.99" stroke="#ee8aff" /> */}
  </g>
  )